/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiHealth, EuiLink, EuiPanel, EuiSpacer, EuiTableRowCell, EuiText } from '@elastic/eui'

import type { CuiTableColumn } from '@modules/cui/Table'
import { CuiTable } from '@modules/cui/Table'
import { CuiHelpTipIcon } from '@modules/cui/HelpTipIcon'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { DeploymentInstance } from '@modules/billing-api/customTypes'
import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import EmptyDataPrompt from '@/components/User/BillingUsage/BillingUsageOverviewV2/EmptyDataPrompt'
import DocLink from '@/components/DocLink'

import AggregationTableRowV2 from '../components/AggregationTableRowV2'
import { ColorKeyForOtherDeployments, VisibleDeployments } from '../constants'

import {
  getDeploymentInstancesWithAggregatedCosts,
  getRowColor,
  getTotalDeploymentCostsPerDimension,
} from './utils'
import { IconType } from './types'
import DetailsPanel from './DetailsPanel'

import type { ReducerState } from '../components/Filters/filtersReducer'
import type {
  DeploymentInstanceWithAggregatedCosts,
  TotalDeploymentCostsPerDimension,
} from './types'
import type { FunctionComponent } from 'react'

interface Props {
  instances: DeploymentInstance[]
  aggregated: boolean
  isLoading: boolean
  onDeploymentSelected: (deploymentId: string, deploymentName?: string) => void
  state: ReducerState
  organizationId: string
}

const DeploymentsList: FunctionComponent<Props> = ({
  instances,
  isLoading,
  onDeploymentSelected,
  aggregated,
  state,
  organizationId,
}) => {
  const items = getDeploymentInstancesWithAggregatedCosts(instances).sort(
    (a, b) => b.total_ecu - a.total_ecu,
  )
  const totalDeploymentCostsPerDimension = getTotalDeploymentCostsPerDimension(items)
  const visibleItems = items.slice(0, VisibleDeployments)
  const collapsedItems = items.slice(VisibleDeployments)
  const collapsedTotals = getTotalDeploymentCostsPerDimension(collapsedItems)
  const hasAnyDeployments = Boolean(visibleItems.length)

  const columns: Array<CuiTableColumn<DeploymentInstanceWithAggregatedCosts>> = [
    {
      label: (
        <FormattedMessage id='billing-usage-deployment-list.name-label' defaultMessage='Name' />
      ),
      width: '30%',
      render: ({ name, id }) => (
        <EuiHealth
          color={
            aggregated ? colorForInstances.get(ColorKeyForOtherDeployments) : getRowColor(id, name)
          }
        >
          <EuiLink disabled={!id} onClick={() => onDeploymentSelected(id, name)}>
            <span data-test-id='name'>{name || id}</span>
          </EuiLink>
        </EuiHealth>
      ),
      footer: {
        render: () => (
          <Fragment>
            <FormattedMessage
              id='billing-usage-deployment-list.total'
              defaultMessage='Total usage'
            />
            <CuiHelpTipIcon iconType='iInCircle'>
              <FormattedMessage
                id='billing-deployment-usage.tip-icon'
                defaultMessage='Usage only. Does not include credits, prepaids, or any other discounts.'
              />
            </CuiHelpTipIcon>
          </Fragment>
        ),
      },
    },
    {
      align: 'right',
      label: (
        <FormattedMessage
          id='billing-usage-deployment-list.capacity-label'
          defaultMessage='Capacity (in ECU)'
        />
      ),
      render: ({ capacity }) => (
        <span data-test-id='capacity'>
          <CuiElasticConsumptionUnits value={capacity} unit='none' dp={4} withSymbol={false} />
        </span>
      ),
      footer: {
        render: () => (
          <span data-test-id='total-capacity'>
            <CuiElasticConsumptionUnits
              value={totalDeploymentCostsPerDimension.capacity}
              unit='none'
              dp={4}
              withSymbol={true}
              symbolPosition='before'
            />
          </span>
        ),
      },
    },
    {
      align: 'right',
      label: (
        <FormattedMessage
          id='billing-usage-deployment-list.data-transfer-label'
          defaultMessage='Data transfer (in ECU)'
        />
      ),
      render: ({ dataTransfer }) => (
        <span data-test-id='dataTransfer'>
          <CuiElasticConsumptionUnits value={dataTransfer} unit='none' dp={4} withSymbol={false} />
        </span>
      ),
      footer: {
        render: () => (
          <span data-test-id='total-dataTransfer'>
            <CuiElasticConsumptionUnits
              value={totalDeploymentCostsPerDimension.dataTransfer}
              unit='none'
              dp={4}
              withSymbol={true}
              symbolPosition='before'
            />
          </span>
        ),
      },
    },
    {
      align: 'right',
      label: (
        <FormattedMessage
          id='billing-usage-deployment-list.snapshots-label'
          defaultMessage='Snapshots (in ECU)'
        />
      ),
      render: ({ storage }) => (
        <span data-test-id='storage'>
          <CuiElasticConsumptionUnits value={storage} unit='none' dp={4} withSymbol={false} />
        </span>
      ),
      footer: {
        render: () => (
          <span data-test-id='total-storage'>
            <CuiElasticConsumptionUnits
              value={totalDeploymentCostsPerDimension.storage}
              unit='none'
              dp={4}
              withSymbol={true}
              symbolPosition='before'
            />
          </span>
        ),
      },
    },
    {
      align: 'right',
      label: (
        <FormattedMessage
          id='billing-usage-deployment-list.synthetics-label'
          defaultMessage='Synthetics (in ECU)'
        />
      ),
      render: ({ synthetics }) => (
        <span data-test-id='synthetics'>
          <CuiElasticConsumptionUnits value={synthetics} unit='none' dp={4} withSymbol={false} />
        </span>
      ),
      footer: {
        render: () => (
          <span data-test-id='total-synthetics'>
            <CuiElasticConsumptionUnits
              value={totalDeploymentCostsPerDimension.synthetics}
              unit='none'
              dp={4}
              withSymbol={true}
              symbolPosition='before'
            />
          </span>
        ),
      },
    },
    {
      align: 'right',
      label: (
        <FormattedMessage
          id='billing-usage-deployment-list.total-label'
          defaultMessage='Total (in ECU)'
        />
      ),
      render: ({ total_ecu }) => (
        <span data-test-id='total'>
          <CuiElasticConsumptionUnits value={total_ecu} unit='none' dp={4} withSymbol={false} />
        </span>
      ),
      footer: {
        render: () => (
          <span data-test-id='total-total'>
            <CuiElasticConsumptionUnits
              value={totalDeploymentCostsPerDimension.total_ecu}
              unit='none'
              dp={4}
              withSymbol={true}
              symbolPosition='before'
            />
          </span>
        ),
      },
    },
    {
      actions: true, // additional actions column
      width: '40px',
    },
  ]

  return (
    <Fragment>
      <EuiText size='s'>
        <h3>
          <FormattedMessage
            id='billing-deployment-usage-list.title'
            defaultMessage='Hosted deployments'
          />
        </h3>
      </EuiText>

      <EuiSpacer size='xs' />

      <EuiText size='s'>
        <FormattedMessage
          id='billing-deployment-usage-list.description'
          defaultMessage='All cost units are measured in {ecu}.'
          values={{
            ecu: (
              <DocLink link='billingECU' favorSaasContext={true}>
                <FormattedMessage
                  id='ecu-tooltip.page-ecu-unit'
                  defaultMessage='Elastic Consumption Units (ECU)'
                />
              </DocLink>
            ),
          }}
        />
      </EuiText>

      <EuiSpacer size='m' />

      <EuiPanel
        hasShadow={false}
        paddingSize='l'
        hasBorder={true}
        className='billing-usage-responsive-panel'
        data-test-id='deployments-list-usage-table'
      >
        <CuiTable
          rows={visibleItems}
          columns={columns}
          initialLoading={isLoading}
          hasFooterRow={hasAnyDeployments}
          responsive={false}
          getRowId={(item) => item.id}
          renderDetailRow={(item) => (
            <DetailsPanel
              instanceId={item.id}
              organizationId={organizationId}
              startDate={state.startDate}
              endDate={state.endDate}
            />
          )}
          hasDetailRow={(item) => !!item.id}
          renderDetailButton={true}
          emptyMessage={<EmptyDataPrompt iconType={IconType.TABLE} />}
          renderCustomRowsLast={
            hasAnyDeployments
              ? () => (
                  <AggregationTableRowV2<
                    TotalDeploymentCostsPerDimension,
                    DeploymentInstanceWithAggregatedCosts
                  >
                    onItemSelected={onDeploymentSelected}
                    totals={collapsedTotals}
                    items={collapsedItems}
                    type='deployment'
                    renderRow={({ capacity, dataTransfer, storage, synthetics, total_ecu }) => (
                      <Fragment>
                        <EuiTableRowCell align='right'>
                          <CuiElasticConsumptionUnits
                            value={capacity}
                            unit='none'
                            dp={4}
                            withSymbol={false}
                          />
                        </EuiTableRowCell>
                        <EuiTableRowCell align='right'>
                          <span data-test-id='deployments-list-dataTransfer'>
                            <CuiElasticConsumptionUnits
                              value={dataTransfer}
                              unit='none'
                              dp={4}
                              withSymbol={false}
                            />
                          </span>
                        </EuiTableRowCell>
                        <EuiTableRowCell align='right'>
                          <span data-test-id='deployments-list-storage'>
                            <CuiElasticConsumptionUnits
                              value={storage}
                              unit='none'
                              dp={4}
                              withSymbol={false}
                            />
                          </span>
                        </EuiTableRowCell>
                        <EuiTableRowCell align='right'>
                          <span data-test-id='deployments-list-synthetics'>
                            <CuiElasticConsumptionUnits
                              value={synthetics}
                              unit='none'
                              dp={4}
                              withSymbol={false}
                            />
                          </span>
                        </EuiTableRowCell>
                        <EuiTableRowCell align='right'>
                          <span data-test-id='deployments-list-total'>
                            <CuiElasticConsumptionUnits
                              value={total_ecu}
                              unit='none'
                              dp={4}
                              withSymbol={false}
                            />
                          </span>
                        </EuiTableRowCell>
                        <EuiTableRowCell className='euiTableCellContent' />
                      </Fragment>
                    )}
                  />
                )
              : undefined
          }
        />
      </EuiPanel>
    </Fragment>
  )
}

export default DeploymentsList
