/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage } from 'react-intl'
import React, { Fragment } from 'react'

import { EuiBadge, EuiHealth, EuiPanel, EuiTableRow, EuiTableRowCell } from '@elastic/eui'

import type { CuiTableColumn } from '@modules/cui/Table'
import { CuiTable } from '@modules/cui/Table'
import { CuiHelpTipIcon } from '@modules/cui/HelpTipIcon'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import EmptyDataPrompt from '../BillingUsageOverviewV2/EmptyDataPrompt'
import { IconType } from '../BillingUsageOverviewV2/types'
import QuantityTableInfo from '../components/QuantityTableInfo'

import { getServerlessFreeComponents } from './utils'

import type { ProductData } from '@/components/User/BillingUsage/BillingUsageByInstance/types'
import type { FunctionComponent } from 'react'

interface Props {
  products: ProductData[]
  total_cost: number
  isLoading: boolean
}

const ProjectsList: FunctionComponent<Props> = ({ products, isLoading, total_cost }) => {
  const [isFlagUsable, flags] = useFlagsWhenLoaded()

  const columns: Array<CuiTableColumn<ProductData>> = [
    {
      label: (
        <FormattedMessage
          id='billing-usage-product-list.product-type-label'
          defaultMessage='Product type'
        />
      ),
      render: ({ color, type }) =>
        type ? (
          <EuiHealth color={color}>
            <span data-test-id='type'>{type}</span>
          </EuiHealth>
        ) : (
          ''
        ),
      footer: {
        render: () => (
          <Fragment>
            <FormattedMessage id='billing-usage-product-list.total' defaultMessage='Total usage' />
            <CuiHelpTipIcon iconType='iInCircle'>
              <FormattedMessage
                id='billing-deployment-usage.tip-icon'
                defaultMessage='Usage only. Does not include credits, prepaids, or any other discounts.'
              />
            </CuiHelpTipIcon>
          </Fragment>
        ),
      },
    },
    {
      label: (
        <FormattedMessage id='billing-usage-product-list.name-label' defaultMessage='Component' />
      ),
      render: ({ name }) => <span data-test-id='name'>{name}</span>,
      width: '45%',
      footer: {
        render: () => null,
      },
    },
    {
      label: (
        <FormattedMessage
          id='billing-usage-product-list.quantity-label'
          defaultMessage='Quantity'
        />
      ),
      render: ({ unit, display_quantity, quantity }) => (
        <QuantityTableInfo unit={unit} display_quantity={display_quantity} quantity={quantity} />
      ),
      footer: {
        render: () => null,
      },
    },
    {
      label: (
        <FormattedMessage
          id='billing-usage-product-list.item-price-label'
          defaultMessage='Current usage rates (in ECU)'
        />
      ),
      render: ({ item_price }) => item_price,
      footer: {
        render: () => null,
      },
    },
    {
      label: (
        <FormattedMessage
          id='billing-usage-product-list.total-label'
          defaultMessage='Total (in ECU)'
        />
      ),
      align: 'right',
      render: ({ total }) => (
        <CuiElasticConsumptionUnits value={total} unit='none' dp={4} withSymbol={false} />
      ),
      footer: {
        render: () => (
          <CuiElasticConsumptionUnits
            value={total_cost}
            unit='none'
            dp={4}
            withSymbol={true}
            symbolPosition='before'
          />
        ),
      },
    },
  ]

  const renderCustomRowsLast = () => {
    if (!isFlagUsable) {
      return null
    }

    return (
      <Fragment>
        {getServerlessFreeComponents(products, flags.serverlessFreeComponents).map(
          ({ product_type, name, free_message }) => (
            <EuiTableRow key={name}>
              <EuiTableRowCell>
                <EuiHealth color='#FEC514'>{product_type}</EuiHealth>
              </EuiTableRowCell>
              <EuiTableRowCell>
                <p style={{ marginRight: 12, display: 'inline-block' }}>{name}</p>
                <EuiBadge>{free_message}</EuiBadge>
              </EuiTableRowCell>
              <EuiTableRowCell colSpan={3} />
            </EuiTableRow>
          ),
        )}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <EuiPanel
        hasShadow={false}
        paddingSize='l'
        hasBorder={true}
        className='billing-usage-responsive-panel'
        data-test-id='product-list-table'
      >
        <CuiTable
          rows={products}
          columns={columns}
          initialLoading={isLoading}
          hasFooterRow={true}
          responsive={false}
          emptyMessage={<EmptyDataPrompt iconType={IconType.TABLE} />}
          renderCustomRowsLast={renderCustomRowsLast}
        />
      </EuiPanel>
    </Fragment>
  )
}

export default ProjectsList
