/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { useIntl } from 'react-intl'

import { EuiFlexGroup, EuiSkeletonRectangle } from '@elastic/eui'

import { useGetProvidersQuery } from '@modules/cloud-lib/billing/hooks'
import type { PlatformId } from '@modules/ui-types'
import { isPlatformId } from '@modules/discovery-questions-lib/utils'
import { useOnboardingToken } from '@modules/discovery-questions-lib/hooks'
import { selectTemplateByOnboardingToken } from '@modules/deployment-creation-lib/utils'

// eslint-disable-next-line import/no-restricted-paths
import CreateStackDeploymentEditorDependencies from '@/components/StackDeploymentEditor/CreateStackDeploymentEditorDependencies'
// eslint-disable-next-line import/no-restricted-paths
import { selectTemplateWithSameCategory } from '@/lib/stackDeployments/defaultDeploymentTemplate'

import { DiscoveryQuestionStepLayout } from '../discovery-questions-components/DiscoveryQuestionStepLayout'
import messages from '../discovery-questions-components/messages'

import CreateDeploymentEditor from './CreateDeploymentEditor'

// eslint-disable-next-line import/no-restricted-paths
import type { CreateEditorComponentConsumerProps } from '@/components/StackDeploymentEditor/types'
import type { FunctionComponent } from 'react'

export interface CreateDeploymentProps {
  onDeploymentCreation: () => Promise<void>
}

const CreateDeployment: FunctionComponent<CreateDeploymentProps> = ({
  onDeploymentCreation,
}: CreateDeploymentProps) => {
  const { formatMessage } = useIntl()

  const { data: providers } = useGetProvidersQuery()

  const onboardingToken = useOnboardingToken()

  if (providers === undefined || providers.length === 0) {
    return loadingSkeleton()
  }

  const providerIds = providers.map(({ name }) => name) as PlatformId[]

  const defaultProvider: PlatformId | undefined = isPlatformId(providerIds[0])
    ? providerIds[0]
    : undefined

  if (!defaultProvider) {
    return loadingSkeleton()
  }

  return (
    <DiscoveryQuestionStepLayout
      title={formatMessage(messages.title)}
      description={formatMessage(messages.subtitle)}
    >
      <CreateStackDeploymentEditorDependencies
        selectTemplate={selectTemplateByOnboardingToken(
          onboardingToken,
          selectTemplateWithSameCategory,
        )}
      >
        {(props: CreateEditorComponentConsumerProps) => renderCreateDeployment(props)}
      </CreateStackDeploymentEditorDependencies>
    </DiscoveryQuestionStepLayout>
  )

  function renderCreateDeployment(props: CreateEditorComponentConsumerProps) {
    if (!providers) {
      return null
    }

    return (
      <CreateDeploymentEditor
        {...props}
        providerIds={providerIds}
        providers={providers}
        onDeploymentCreation={onDeploymentCreation}
      />
    )
  }

  function loadingSkeleton() {
    return (
      <DiscoveryQuestionStepLayout
        title={<EuiSkeletonRectangle width={300} height={27} />}
        description={<EuiSkeletonRectangle width={300} height={16} />}
        footer={<EuiSkeletonRectangle width={88} height={40} />}
      >
        <EuiFlexGroup>
          <EuiSkeletonRectangle width={196} height={38} />
          <EuiSkeletonRectangle width={88} height={38} />
        </EuiFlexGroup>
      </DiscoveryQuestionStepLayout>
    )
  }
}

export default CreateDeployment
