/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { injectIntl } from 'react-intl'

import { EuiComboBox, EuiFormRow } from '@elastic/eui'

import { Solution } from '../../../types'

import type { FunctionComponent } from 'react'
import type { Props } from './types'
import type { WrappedComponentProps } from 'react-intl'

const SolutionSelect: FunctionComponent<Props & WrappedComponentProps> = ({
  onChange,
  isLoading,
  isDisabled,
  intl: { formatMessage },
  values,
}) => {
  const options = [
    {
      label: formatMessage({
        id: 'billing-usage.filters.solution.security',
        defaultMessage: 'Security',
      }),
      value: Solution.Security,
    },
    {
      label: formatMessage({
        id: 'billing-usage.filters.solution.observability',
        defaultMessage: 'Observability',
      }),
      value: Solution.Observability,
    },
    {
      label: formatMessage({
        id: 'billing-usage.filters.solution.search',
        defaultMessage: 'Search',
      }),
      value: Solution.Search,
    },
  ]

  return (
    <EuiFormRow
      fullWidth={true}
      label={formatMessage({
        id: 'billing-usage.filters.solution',
        defaultMessage: 'Serverless project types',
      })}
    >
      <EuiComboBox
        aria-label={formatMessage({
          id: 'billing-usage.filters.solution',
          defaultMessage: 'Serverless project types',
        })}
        options={options}
        selectedOptions={options.filter(({ value }) => values.includes(value))}
        onChange={(solutions) =>
          onChange({ solutions: solutions.map(({ value }) => value) as Solution[] })
        }
        isClearable={false}
        isDisabled={isDisabled || isLoading}
        fullWidth={true}
      />
    </EuiFormRow>
  )
}

export default injectIntl(SolutionSelect)
