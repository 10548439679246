/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { groupBy } from 'lodash'

import { EuiHorizontalRule, EuiSpacer, EuiText, EuiToolTip } from '@elastic/eui'

import type { ZoneSummary as ZoneSummaryType, ZoneSummaryInstance } from '@modules/ui-types'

import { getSliderInstanceColor } from '@/lib/sliders/colors'

import './zoneSummary.scss'

type Props = {
  zone: ZoneSummaryType
  zoneIndex: number
}

interface ZoneSummaryWithCount extends ZoneSummaryInstance {
  nodeCount: number
}

class ZoneSummary extends Component<Props> {
  render() {
    const { zone, zoneIndex } = this.props
    const zoneLabels = [`a`, `b`, `c`]
    const zoneWithDisplayData: ZoneSummaryWithCount[] = Object.values(groupBy(zone, 'id')).map(
      (zoneInstances) => {
        // This must be defined
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const instance = zoneInstances[0]!
        return {
          ...instance,
          nodeCount: zoneInstances.length,
        }
      },
    )

    return (
      <div data-test-id={`zone-summary-${zoneIndex + 1}`}>
        <EuiText style={{ textTransform: 'capitalize' }} size='s' color='subdued'>
          <FormattedMessage
            id='zone-summary.zone-labe'
            defaultMessage='Zone {zoneLabel}'
            values={{ zoneLabel: zoneLabels[zoneIndex] || `` }}
          />
        </EuiText>

        <EuiHorizontalRule margin='s' />

        <div className='zoneSummary'>
          {zoneWithDisplayData.map((instance, instanceIndex) => (
            <EuiToolTip
              key={instanceIndex}
              content={
                <Fragment>
                  {instance.name} — {instance.size}
                  {instance.storage ? [` and `, instance.storage] : ``}
                </Fragment>
              }
            >
              <div
                className={`instance-summary-circle`}
                style={{ backgroundColor: getSliderInstanceColor(instance.type) }}
                data-test-id={`instance-summary-viz-circle-${instance.id}`}
                data-type={instance.type}
                tabIndex={0}
              >
                {instance.nodeCount > 1 ? instance.nodeCount.toString() : ``}
              </div>
            </EuiToolTip>
          ))}
        </div>
        <EuiSpacer size='s' />
      </div>
    )
  }
}

export default ZoneSummary
