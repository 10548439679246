/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { useHistory } from 'react-router-dom'

import {
  EuiPageTemplate,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiPageSection,
  useEuiTheme,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  useEuiBackgroundColor,
} from '@elastic/eui'

import Header from '../Header'
import { Product } from '../types'
import StackHosted from '../StackHosted'
import Serverless from '../Serverless'

import { getDefaultProductType } from './utils'

const PricingPage = () => {
  const history = useHistory()
  const { euiTheme } = useEuiTheme()
  const defaultProductType = getDefaultProductType(history.location.search.slice(1))
  const [selectedProduct, setSelectedProduct] = useState<Product>(defaultProductType)

  const onProductSelect = (product: Product) => {
    if (product !== selectedProduct) {
      setSelectedProduct(product)
      // clear previous set of filters
      history.push(`${history.location.pathname}?productType=${product}`)
    }
  }

  const ProductTabs = [
    {
      label: (
        <FormattedMessage
          id='pricing-list.product.stack-hosted'
          defaultMessage='Elastic Cloud Stack Hosted'
        />
      ),
      description: (
        <FormattedMessage
          id='pricing-list.product.stack-hosted.description'
          defaultMessage='Detailed rates for hosted deployments'
        />
      ),
      isActive: selectedProduct === Product.STACK_HOSTED,
      key: Product.STACK_HOSTED,
    },
    {
      label: (
        <FormattedMessage
          id='pricing-list.product.serverless'
          defaultMessage='Elastic Cloud Serverless'
        />
      ),
      description: (
        <FormattedMessage
          id='pricing-list.product.serverless.description'
          defaultMessage='Detailed rates for serverless projects'
        />
      ),
      isActive: selectedProduct === Product.SERVERLESS,
      key: Product.SERVERLESS,
    },
  ]

  return (
    <EuiPageTemplate panelled={true} css={css({ backgroundColor: useEuiBackgroundColor('plain') })}>
      <EuiPageTemplate.Header bottomBorder={false} responsive={false}>
        <Header />
      </EuiPageTemplate.Header>
      <EuiPageSection color='subdued' restrictWidth={true} paddingSize='xl'>
        <EuiFlexGroup direction='column' alignItems='center'>
          <EuiFlexItem>
            <EuiTitle size='m' css={css({ color: euiTheme.colors.primary })}>
              <h2>
                <FormattedMessage
                  id='pricing-list.title'
                  defaultMessage='Elastic Cloud Pricing Table'
                />
              </h2>
            </EuiTitle>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>
                <FormattedMessage
                  id='pricing-list.description'
                  defaultMessage='Select your type, then check the detailed rates.'
                />
              </p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xl' />

        <EuiFlexGroup alignItems='center'>
          {ProductTabs.map(({ label, description, key, isActive }) => (
            <EuiFlexItem grow={5} key={key} onClick={() => onProductSelect(key)}>
              <EuiPanel
                hasShadow={isActive}
                hasBorder={!isActive}
                css={css({
                  cursor: 'pointer',
                  paddingTop: 20,
                  paddingBottom: 20,
                  ...(isActive && { borderLeft: `3px solid ${euiTheme.colors.primary}` }),
                })}
              >
                <EuiText css={css({ fontWeight: 700 })}>
                  <p>{label}</p>
                </EuiText>

                <EuiSpacer size='s' />

                <EuiText size='xs'>
                  <p>{description}</p>
                </EuiText>
              </EuiPanel>
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      </EuiPageSection>

      <EuiSpacer size='s' />

      <EuiPageSection restrictWidth={true}>
        {
          {
            [Product.STACK_HOSTED]: <StackHosted />,
            [Product.SERVERLESS]: <Serverless />,
          }[selectedProduct]
        }
      </EuiPageSection>
    </EuiPageTemplate>
  )
}

export default PricingPage
