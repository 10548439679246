/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButtonIcon,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiFlexGroup,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiToolTip,
  EuiPanel,
  EuiEmptyPrompt,
} from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'
import type { OnFilterChangeParams } from '@modules/cui/FilterContext'
import { getFilterQueryString, setFilterQueryString } from '@modules/cui/FilterContext'
import { CuiAlert } from '@modules/cui/Alert'
import { addToast } from '@modules/cui/Toasts'
import type { CuiTableColumn } from '@modules/cui/Table'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'
import Header from '@modules/cui/Header'

import RoleBadges from '@/components/Organization/RoleBadges'
import { allUsersApiKeysCrumbs } from '@/lib/crumbBuilder'

import OrganizationMemberEmailLabel from '../Organization/OrganizationMembers/OrganizationMemberEmailLabel'
import DocLink from '../DocLink'

import ApiKeysFilterContext from './ApiKeysFilterContext'
import RevokeKeyModal from './RevokeKeyModal'
import GenerateKeyModal from './GenerateKeyModal'
import GenerateApiKeyButton from './GenerateApiKeyButton'
import messages from './messages'
import ApiKeysRoleAssignments from './ApiKeysRoleAssignments'
import { ApiKeyStatus } from './ApiKeyStatus'

import type { ApiKeyRow, AllProps as Props, State } from './types'
import type { FunctionComponent, ReactNode } from 'react'

import './apiKeys.scss'

class ApiKeys extends Component<Props, State> {
  state: State = {
    showRevokeKeyModal: false,
    showGenerateKeyModal: false,
    apiKeyToEditRoleAssignments: null,
    key: null,
    query: getFilterQueryString({ storageKey: `ApiKeys` }),
    queryResults: this.props.apiKeys,
  }

  componentDidMount() {
    const {
      fetchApiKeys,
      searchAllOrganizationDeployments,
      fetchOrganizationMembers,
      organizationId,
    } = this.props

    fetchApiKeys()

    if (organizationId) {
      fetchOrganizationMembers({ organizationId })
      searchAllOrganizationDeployments({ organizationId })
    }
  }

  componentWillUnmount() {
    this.props.resetRevokeApiKeyRequest()
  }

  render() {
    const {
      regionId,
      fetchKeysRequest,
      generateApiKey,
      generateKeyRequest,
      apiKeys,
      revokeApiKeyRequest,
      fetchApiKeys,
      defaultExpiration,
      showCreateApiKey,
      showRoleAssignments,
      showNeverExpirationOption,
      organizationId,
    } = this.props

    const { showGenerateKeyModal, showRevokeKeyModal, key } = this.state

    return (
      <Fragment>
        {regionId && (
          <Header
            name={<FormattedMessage id='keysManagement.title' defaultMessage='API keys' />}
            breadcrumbs={allUsersApiKeysCrumbs({ regionId })}
          />
        )}

        <PrivacySensitiveContainer>
          {this.renderApiKeyRoleAssignments()}

          {!isEmpty(apiKeys) || this.isLoading() || !showCreateApiKey ? (
            this.renderKeysTable()
          ) : (
            <EmptyApiKeysTable onClick={this.showGenerateKeyModal} />
          )}

          {fetchKeysRequest.error && (
            <Fragment>
              <EuiSpacer size='m' />
              <CuiAlert type='danger'>{fetchKeysRequest.error}</CuiAlert>
            </Fragment>
          )}

          {revokeApiKeyRequest.error && (
            <Fragment>
              <EuiSpacer size='m' />
              <CuiAlert type='danger'>{revokeApiKeyRequest.error}</CuiAlert>
            </Fragment>
          )}

          {showGenerateKeyModal && (
            <GenerateKeyModal
              organizationId={organizationId}
              generateApiKey={generateApiKey}
              generateKeyRequest={generateKeyRequest}
              apiKeys={apiKeys}
              onCancel={this.closeGenerateKeyModal}
              onConfirm={this.confirmGenerate}
              fetchApiKeys={fetchApiKeys}
              defaultExpiration={defaultExpiration}
              showRoleAssignments={showRoleAssignments}
              showNeverExpirationOption={showNeverExpirationOption}
            />
          )}

          {showRevokeKeyModal && (
            <RevokeKeyModal
              revokeApiKeyRequest={revokeApiKeyRequest}
              keys={[key]}
              onCancel={this.closeRevokeKeyModal}
              onConfirm={this.confirmRevoke}
            />
          )}
        </PrivacySensitiveContainer>
      </Fragment>
    )
  }

  renderKeysTable = () => {
    const { showCreateApiKey } = this.props
    const { query } = this.state
    const { columns, initialSort } = this.getGridColumns()

    return (
      <EuiFlexGroup direction='column'>
        <EuiFlexItem>
          <EuiText grow={true} color='subdued'>
            <FormattedMessage
              id='api-keys.overview'
              defaultMessage='An API key allows you to perform most of the operations available in the UI console through API calls. You can create and manage deployments, configure remote clusters, set up traffic filters, manage extensions, and much more. {learnMore}'
              values={{
                learnMore: (
                  <DocLink link='apiKeysDocLink'>
                    <FormattedMessage
                      id='api-keys.overview.learn-more'
                      defaultMessage='Learn more'
                    />
                  </DocLink>
                ),
              }}
            />
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup justifyContent='flexEnd'>
            <EuiFlexItem>
              <ApiKeysFilterContext
                query={query}
                onChange={this.onChange}
                apiKeys={this.props.apiKeys}
              />
            </EuiFlexItem>

            {showCreateApiKey && (
              <EuiFlexItem grow={false}>
                <PermissionsGate
                  permissions={[
                    {
                      type: 'api-key',
                      action: 'create',
                    },
                  ]}
                >
                  {({ hasPermissions }) => (
                    <GenerateApiKeyButton
                      disabled={!hasPermissions}
                      onClick={this.showGenerateKeyModal}
                    />
                  )}
                </PermissionsGate>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiPanel>
            <CuiTable<ApiKeyRow>
              initialSort={initialSort}
              initialLoading={this.isLoading()}
              columns={columns}
              rows={isEmpty(this.state.queryResults) ? [] : this.state.queryResults}
            />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderApiKeyRoleAssignments() {
    if (!this.props.organizationId || this.state.apiKeyToEditRoleAssignments === null) {
      return null
    }

    return (
      <ApiKeysRoleAssignments
        organizationId={this.props.organizationId}
        apiKey={this.state.apiKeyToEditRoleAssignments}
        onClose={this.closeRoleAssignments}
      />
    )
  }

  renderApiKeyName(apiKey: ApiKeyRow): ReactNode {
    if (!this.props.organizationId || !this.props.showRoleAssignments) {
      return apiKey.description
    }

    return (
      <PermissionsGate
        permissions={[
          {
            organizationId: this.props.organizationId,
            type: 'role-assignment',
            action: 'update',
          },
        ]}
      >
        {({ hasPermissions }) =>
          hasPermissions ? (
            <EuiButtonEmpty
              flush='left'
              onClick={() => {
                this.setState({
                  apiKeyToEditRoleAssignments: apiKey,
                })
              }}
            >
              {apiKey.description}
            </EuiButtonEmpty>
          ) : (
            apiKey.description
          )
        }
      </PermissionsGate>
    )
  }

  getGridColumns(): {
    initialSort: CuiTableColumn<ApiKeyRow>
    columns: Array<CuiTableColumn<ApiKeyRow>>
  } {
    const {
      intl: { formatMessage },
      deploymentsIds,
    } = this.props

    const expirationDateColumn = {
      label: formatMessage(messages.keyExpiresOnColumn),
      render: (apiKey) =>
        apiKey.expiration_date ? (
          <EuiToolTip
            position='right'
            content={moment(apiKey.expiration_date).format('MMMM Do YYYY, h:mm a')}
          >
            <div>{moment(apiKey.expiration_date).format('MMMM Do YYYY')}</div>
          </EuiToolTip>
        ) : (
          <FormattedMessage id='api-keys-table.expiration.never' defaultMessage='Never' />
        ),
      sortKey: `expiration_date`,
      width: 'auto',
    }

    return {
      initialSort: expirationDateColumn,
      columns: [
        {
          label: formatMessage(messages.keyNameColumn),
          render: (apiKey) => this.renderApiKeyName(apiKey),
          sortKey: `description`,
          width: 'auto',
        },
        {
          label: formatMessage(messages.keyCreatedByColumn),
          render: ({ createdBy, isRemoved }) => (
            <OrganizationMemberEmailLabel email={createdBy} isRemoved={isRemoved} />
          ),
          sortKey: `createdBy`,
          width: 'auto',
        },
        {
          label: formatMessage(messages.keyStatus),
          render: (apiKey) => <ApiKeyStatus apiKey={apiKey} />,
          sortKey: (apiKey) => apiKey.status,
          truncateText: true,
          width: 'auto',
        },
        expirationDateColumn,
        ...(this.props.showRoleAssignments
          ? [
              {
                label: <FormattedMessage id='api-key.roles' defaultMessage='Roles' />,
                render: (apiKey) => (
                  <RoleBadges
                    roleAssignments={apiKey.role_assignments}
                    deploymentIds={deploymentsIds}
                  />
                ),
                width: 'auto',
              },
            ]
          : []),
        {
          label: formatMessage(messages.keyCreatedOnColumn),
          render: (apiKey) => (
            <EuiToolTip
              position='right'
              content={moment(apiKey.creation_date).format('MMMM Do YYYY, h:mm a')}
            >
              <div>{moment(apiKey.creation_date).format('MMMM Do YYYY')}</div>
            </EuiToolTip>
          ),
          sortKey: `creation_date`,
        },
        {
          mobile: {
            label: formatMessage(messages.keyActionsColumn),
          },
          label: formatMessage(messages.keyActionsColumn),
          actions: true,
          align: `center`,
          width: `auto`,
          render: (apiKey) => (
            <div data-test-id='revoke-api-key'>
              <EuiButtonIcon
                aria-label={formatMessage(messages.keyRevokeColumn)}
                onClick={() => this.showRevokeKeyModal(apiKey)}
                iconType='trash'
                color='danger'
              />
            </div>
          ),
        },
      ],
    }
  }

  showRevokeKeyModal = (apiKey: ApiKeyRow) => {
    this.setState({ showRevokeKeyModal: true, key: apiKey })
  }

  closeRevokeKeyModal = () => {
    this.setState({ showRevokeKeyModal: false, key: null })
  }

  confirmRevoke = () => {
    const {
      intl: { formatMessage },
      revokeApiKey,
    } = this.props

    const { key } = this.state

    if (!key) {
      return
    }

    const revokeApiKeySuccess = {
      id: 'revokeKeySuccess',
      family: `api-key.revoke-key-success`,
      title: formatMessage(messages.revokeSuccess, {
        keyName: key.description,
      }),
      color: 'success',
    }

    return revokeApiKey(key)
      .then(() => {
        this.closeRevokeKeyModal()
        addToast(revokeApiKeySuccess)
        return
      })
      .catch(() => {
        this.closeRevokeKeyModal()
        return
      })
  }

  showGenerateKeyModal = () => {
    this.setState({ showGenerateKeyModal: true })
  }

  closeGenerateKeyModal = () => {
    this.setState({ showGenerateKeyModal: false })
  }

  confirmGenerate = () => {
    this.closeGenerateKeyModal()
  }

  closeRoleAssignments = () => this.setState({ apiKeyToEditRoleAssignments: null })

  isLoading = () => {
    const { fetchKeysRequest, fetchOrganizationMembersRequest, organizationId } = this.props

    const { inProgress: isFetchingKeys } = fetchKeysRequest

    const isFetchingOrganizationMemberships = organizationId
      ? fetchOrganizationMembersRequest({ organizationId }).inProgress
      : false

    return isFetchingKeys || isFetchingOrganizationMemberships
  }

  onChange = ({ queryText, queryResults }: OnFilterChangeParams<ApiKeyRow>) => {
    this.setState({
      query: queryText,
      queryResults,
    })

    setFilterQueryString({ storageKey: `ApiKeys`, queryText })
  }
}

const EmptyApiKeysTable: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
  <Fragment>
    <EuiSpacer size='l' />

    <EuiEmptyPrompt
      style={{ maxWidth: `50em` }}
      title={
        <h3>
          <FormattedMessage
            id='empty-api-keys-table.create-api-keys'
            defaultMessage='Create an API key'
            data-test-id='empty-api-keys-table.create-api-keys'
          />
        </h3>
      }
      color='plain'
      body={
        <EuiText>
          <p>
            <FormattedMessage
              id='api-keys.overview-description'
              defaultMessage='An API key allows you to perform most of the operations available in the UI console through API calls. You can create and manage deployments, configure remote clusters, set up traffic filters, manage extensions, and much more.'
            />
          </p>
        </EuiText>
      }
      actions={
        <PermissionsGate
          permissions={[
            {
              type: 'api-key',
              action: 'create',
            },
          ]}
        >
          {({ hasPermissions }) => (
            <GenerateApiKeyButton disabled={!hasPermissions} onClick={onClick} />
          )}
        </PermissionsGate>
      }
      footer={
        <Fragment>
          <EuiTitle size='xxs'>
            <span>
              <FormattedMessage
                id='api-keys.overview.want-learn-more'
                defaultMessage='Want to learn more?'
              />
            </span>
          </EuiTitle>
          &nbsp;&nbsp;
          <DocLink link='apiKeysDocLink' showExternalLinkIcon={true}>
            <FormattedMessage
              id='api-keys.overview.documentation'
              defaultMessage='Read documentation'
            />
          </DocLink>
        </Fragment>
      }
    />
  </Fragment>
)

export default injectIntl(ApiKeys)
