/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl'

import {
  EuiButton,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiFormHelpText,
  EuiCode,
  EuiCallOut,
  EuiAccordion,
  EuiSteps,
} from '@elastic/eui'

import { getDirectTrustRelationshipTrustRestrictionSettings } from '@/lib/stackDeployments/trustRelationships'
import { securityUrl } from '@/lib/urlBuilder'
import CustomTextPopover from '@/components/CustomTextPopover'
import DocLink from '@/components/DocLink'
import { getConfigForKey } from '@/store'
import { getTargetEnvironmentType } from '@/components/DeploymentTrustRelationships/helpers'

import type { AllProps } from './types'

const messages = defineMessages({
  step1Title: {
    id: `directTrustRelationshipInstructions.step1.title`,
    defaultMessage: `Download CA certificate`,
  },
  step2Title: {
    id: `directTrustRelationshipInstructions.step2.title`,
    defaultMessage: `Save trust restriction settings`,
  },
  step3Title: {
    id: `directTrustRelationshipInstructions.step3.title`,
    defaultMessage: `Configure {environmentName} to trust this deployment`,
  },
})

type State = {
  caCertificatePopoverOpen: boolean
  restrictionSettingsPopoverOpen: boolean
}

class DirectTrustRelationshipInstructions extends React.Component<
  AllProps & WrappedComponentProps,
  State
> {
  state: State = { caCertificatePopoverOpen: false, restrictionSettingsPopoverOpen: false }

  componentDidMount(): void {
    const { deployment, fetchCertificateAuthority } = this.props

    fetchCertificateAuthority(deployment.id)
  }

  render(): JSX.Element | null {
    const {
      deployment,
      trustRelationshipFromState,
      trustRelationshipUnderEdit,
      intl: { formatMessage },
    } = this.props

    const isEss = getConfigForKey(`APP_PLATFORM`) === 'saas'

    return (
      <Fragment>
        <EuiCallOut color='success'>
          <EuiText>
            <FormattedMessage
              id='deploymentTrustManagement.direct-cert.success'
              defaultMessage='Trusted environment successfully added'
            />
          </EuiText>
        </EuiCallOut>
        <EuiSpacer size='l' />
        <EuiText>
          <p>
            <FormattedMessage
              id='deploymentTrustManagement.direct-cert.intro'
              defaultMessage='Have you already set up trust from the other environment?'
            />
          </p>
        </EuiText>
        <EuiSpacer size='l' />
        <EuiPanel>
          <EuiAccordion
            id={`instructions-yes`}
            buttonContent={
              <b>
                <FormattedMessage
                  id='deploymentTrustManagement.direct-cert.yes'
                  defaultMessage='Yes, I have already set up trust from the other environment.'
                />
              </b>
            }
          >
            <EuiText>
              <EuiSpacer size='s' />
              <p>
                <FormattedMessage
                  id='deploymentTrustManagement.direct-cert.yes-body'
                  defaultMessage='You’re all set. You can now add remote clusters in Kibana and perform cross-cluster operations between both environments.'
                />
              </p>
            </EuiText>
          </EuiAccordion>
        </EuiPanel>
        <EuiSpacer size='l' />
        <EuiPanel>
          <EuiAccordion
            id={`instructions-no`}
            buttonContent={
              <b>
                <FormattedMessage
                  id='deploymentTrustManagement.direct-cert.no'
                  defaultMessage='No, I have NOT set up trust from the other environment yet.'
                />
              </b>
            }
          >
            <EuiText>
              <p>
                <EuiSpacer size='s' />
                <FormattedMessage
                  id='deploymentTrustManagement.direct-cert.no-body'
                  defaultMessage='To enable cross-cluster operations, both environments must trust each other. That means that if you haven’t already done so, you also need to perform this configuration in the newly trusted environment. To do this:'
                />
              </p>
            </EuiText>
            <EuiSpacer />
            <EuiSteps
              steps={[
                {
                  title: formatMessage(messages.step1Title),
                  children: this.renderStep1(),
                },
                {
                  title: formatMessage(messages.step2Title),
                  children: this.renderStep2(),
                },
                {
                  title: formatMessage(messages.step3Title, {
                    environmentName: trustRelationshipFromState.name,
                  }),
                  children: this.renderStep3(trustRelationshipUnderEdit, isEss),
                },
              ]}
            />
          </EuiAccordion>
        </EuiPanel>
        <EuiSpacer />
        <EuiFlexGroup direction='column' alignItems='flexEnd'>
          <EuiFlexItem>
            <EuiButton fill={true} href={securityUrl(deployment.id)}>
              <FormattedMessage
                id='directTrustRelationshipInstructions.done'
                defaultMessage='Done'
              />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderStep1 = (): JSX.Element => (
    <Fragment>
      <EuiText>
        <FormattedMessage
          id='directTrustRelationshipInstructions.step1.body'
          defaultMessage='Download the CA certificate of this deployment. You need it to configure trust with this deployment from your {environmentCluster}.'
          values={{
            environmentCluster: this.envCluster(),
          }}
        />
      </EuiText>
      <EuiSpacer size='m' />
      {this.renderCertButton()}
    </Fragment>
  )

  renderStep2 = (): JSX.Element => (
    <Fragment>
      <EuiText>
        <FormattedMessage
          id='directTrustRelationshipInstructions.step2.body'
          defaultMessage='Copy or download the following trust restriction settings. You need to add these settings to your {environmentName} configuration, in addition to the CA certificate you downloaded in step 1, to make sure that trust is limited to all or specific cluster nodes of this deployment.'
          values={{
            environmentName: this.envName(),
          }}
        />
      </EuiText>
      <EuiSpacer size='m' />
      {this.renderSettingsBox()}
    </Fragment>
  )

  renderStep3 = (trustRelationshipUnderEdit, isEss): JSX.Element => (
    <Fragment>
      <EuiText>
        <FormattedMessage
          id='directTrustRelationshipInstructions.step3.body'
          defaultMessage='For the trust relationship to be fully functional, it must be bi-lateral. Use the {caCertificate} and {restrictionSettings} you just downloaded to configure your {environmentCluster} to trust this deployment.'
          values={{
            caCertificate: this.renderCaCertificateHelpText(),
            restrictionSettings: this.renderRestrictionSettingsHelpText(),
            environmentCluster: this.envCluster(),
          }}
        />
      </EuiText>
      {isEss && getTargetEnvironmentType(trustRelationshipUnderEdit) === 'ece' && (
        <Fragment>
          <EuiSpacer size='m' />
          <EuiText color='subdued' size='xs'>
            <FormattedMessage
              id='directTrustRelationshipInstructions.note.body'
              defaultMessage='When configuring trust from an ECE environment to an Elasticsearch Service environment,
      provide the Organization ID as environment ID. You can find the ID on the Organization page
      of the environment you want to trust, next to the name of the organization.'
            />
          </EuiText>
        </Fragment>
      )}
    </Fragment>
  )

  renderCertButton = (): JSX.Element | null => {
    const [cert] = this.props.certificateAuthority?.public_certificates || []

    const text = (
      <FormattedMessage
        id='directTrustRelationshipInstructions.downloadCertButton'
        defaultMessage='Download CA certificate'
      />
    )

    if (!cert) {
      return <EuiButton isLoading={true}>{text}</EuiButton>
    }

    const blob = new window.Blob([cert.pem || ``], {
      type: `application/x-x509-ca-cert;charset=utf-8`,
    })
    const fileName = `${cert.metadata.fingerprint}.crt`
    const contents = window.URL.createObjectURL(blob)

    return (
      <EuiButton download={fileName} href={contents} iconType='download'>
        {text}
      </EuiButton>
    )
  }

  renderSettingsBox = (): JSX.Element => {
    const { trustRelationshipFromState, certificateAuthority } = this.props

    const yaml = getDirectTrustRelationshipTrustRestrictionSettings(
      trustRelationshipFromState,
      certificateAuthority,
    )

    const blob = new window.Blob([yaml], {
      type: `application/yaml;charset=utf-8`,
    })
    const fileName = `trust.yml`
    const contents = window.URL.createObjectURL(blob)

    return (
      <Fragment>
        <EuiCodeBlock isCopyable={true} paddingSize='m' language='yaml' whiteSpace='pre'>
          {yaml}
        </EuiCodeBlock>
        <EuiSpacer size='m' />
        <EuiButton download={fileName} href={contents} iconType='download'>
          <FormattedMessage
            id='directTrustRelationshipInstructions.downloadYaml'
            defaultMessage='Download trust.yml'
          />
        </EuiButton>
      </Fragment>
    )
  }

  renderCaCertificateHelpText(): JSX.Element {
    const { caCertificatePopoverOpen } = this.state

    return (
      <CustomTextPopover
        buttonText={
          <FormattedMessage id='use-ca-certificate.text' defaultMessage='CA certificate' />
        }
        onClick={() => this.onClickCaCertificate()}
        isOpen={caCertificatePopoverOpen}
      >
        <EuiFormHelpText>
          <FormattedMessage
            id='trust-new-ca.description'
            defaultMessage='Update the security certificates of your {environmentCluster} with this CA. {learnMore}'
            values={{
              learnMore: (
                <DocLink link='trustNewCaDocLink'>
                  <FormattedMessage id='trust-new-ca.learn-more' defaultMessage='Learn more' />
                </DocLink>
              ),
              environmentCluster: this.envCluster(),
            }}
          />
        </EuiFormHelpText>
      </CustomTextPopover>
    )
  }

  renderRestrictionSettingsHelpText(): JSX.Element {
    const { restrictionSettingsPopoverOpen } = this.state

    return (
      <CustomTextPopover
        buttonText={
          <FormattedMessage
            id='restriction-settings.text'
            defaultMessage='trust restriction settings'
          />
        }
        onClick={() => this.onClickRestrictionSettings()}
        isOpen={restrictionSettingsPopoverOpen}
      >
        <EuiFormHelpText>
          <FormattedMessage
            id='restriction-settings.description'
            defaultMessage='Update the value of {setting} in {configFile} to reflect the downloaded trust restriction settings.'
            values={{
              setting: <EuiCode>xpack.security.transport.ssl.trust_restrictions.path</EuiCode>,
              configFile: <EuiCode>elasticsearch.yml</EuiCode>,
            }}
          />
        </EuiFormHelpText>
      </CustomTextPopover>
    )
  }

  onClickCaCertificate() {
    this.setState({ caCertificatePopoverOpen: !this.state.caCertificatePopoverOpen })
  }

  onClickRestrictionSettings() {
    this.setState({ restrictionSettingsPopoverOpen: !this.state.restrictionSettingsPopoverOpen })
  }

  envCluster(): string {
    const { trustRelationshipUnderEdit } = this.props

    switch (getTargetEnvironmentType(trustRelationshipUnderEdit)) {
      case 'ess':
        return 'Elasticsearch Service deployment'
      case 'ece':
        return 'ECE environment'
      default:
        return 'self-managed Elasticsearch cluster'
    }
  }

  envName(): string {
    const { trustRelationshipUnderEdit } = this.props

    switch (getTargetEnvironmentType(trustRelationshipUnderEdit)) {
      case 'ess':
        return 'Elasticsearch Service organization'
      case 'ece':
        return 'ECE environment'
      default:
        return 'self-managed environment'
    }
  }
}

export default injectIntl(DirectTrustRelationshipInstructions)
