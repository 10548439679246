/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPanel, EuiText } from '@elastic/eui'

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'

import type { DeviceType } from '../../mfa-management/types'

const DeviceOption: React.FunctionComponent<{
  type: DeviceType
  device: SaasAuthMfaDeviceResponse | undefined
  onSelect: (type: DeviceType) => void
}> = ({ type, device, onSelect }) => (
  <EuiPanel hasBorder={true}>
    <EuiFlexGroup alignItems='center'>
      <EuiFlexItem grow={true}>
        <h2>
          <EuiText size='s'>
            <strong>
              {type === 'GOOGLE' && (
                <FormattedMessage
                  id='deviceOption.authenticator.title'
                  defaultMessage='Authenticator'
                />
              )}
              {type === 'EMAIL' && (
                <FormattedMessage id='deviceOption.email.title' defaultMessage='Email' />
              )}
            </strong>
          </EuiText>
        </h2>
        <EuiText size='s'>
          {type === 'GOOGLE' && (
            <FormattedMessage
              id='deviceOption.authenticator.description'
              defaultMessage='Generate verification codes on your mobile device with your preferred security app'
            />
          )}
          {type === 'EMAIL' && (
            <FormattedMessage
              id='deviceOption.email.description'
              defaultMessage='Receive a verification code at your email address'
            />
          )}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {device?.status === 'ACTIVE' ? (
          <EuiIcon type='checkInCircleFilled' size='l' />
        ) : (
          <EuiButton onClick={() => onSelect(type)}>
            <FormattedMessage id='deviceOption.setupButton' defaultMessage='Set up' />
          </EuiButton>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiPanel>
)

export default DeviceOption
