/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiNotificationBadge, EuiToolTip } from '@elastic/eui'

import type { ResourceType } from '@modules/role-assignments-lib/types'

import { roleBadgeLabels, getTooltipMessage, getCountLabel } from './lib/messages'

import type { RoleBadgeInfo } from './lib'
import type { FunctionComponent } from 'react'

type RoleBadgeIcon = 'logoElasticsearch' | 'logoObservability' | 'logoSecurity' | 'logoElasticStack'

const roleBadgeIcon: Record<ResourceType, RoleBadgeIcon> = {
  elasticsearch: 'logoElasticsearch',
  observability: 'logoObservability',
  security: 'logoSecurity',
  deployment: 'logoElasticStack',
}

const RoleBadge: FunctionComponent<{
  roleBadgeInfo: RoleBadgeInfo
}> = ({ roleBadgeInfo }) => {
  const { roleLabel, count, resourceType } = roleBadgeInfo
  return (
    <EuiToolTip content={<FormattedMessage {...getTooltipMessage(roleBadgeInfo)} />} position='top'>
      <EuiFlexGroup gutterSize='xs' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiBadge
            color='hollow'
            iconType={roleBadgeIcon[resourceType || '']}
            iconSide='right'
            role='role-badge'
          >
            <FormattedMessage {...roleBadgeLabels[roleLabel]} />
          </EuiBadge>
        </EuiFlexItem>

        {(resourceType || count === 'all') && (
          <EuiFlexItem grow={false} style={{ marginLeft: `-12px`, marginTop: '-7px' }}>
            <EuiNotificationBadge color='subdued' role='notification-role-badge'>
              {getCountLabel(count)}
            </EuiNotificationBadge>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiToolTip>
  )
}

export default RoleBadge
