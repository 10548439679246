/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useMemo, useState, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHideFor,
  EuiShowFor,
  EuiFormRow,
  EuiButton,
  EuiButtonEmpty,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutFooter,
  EuiHorizontalRule,
} from '@elastic/eui'

import CsvDownloadButton from '@/components/User/BillingUsage/components/Filters/CsvDownloadButton'

import DateRangePicker from '../DateRangePicker'
import ViewBySelect from '../ViewBySelect'
import ChartTypeToggle from '../ChartTypeToggle'
import ProductTypeSelect from '../ProductTypeSelect'
import SolutionSelect from '../SolutionSelect'
import { Action } from '../filtersReducer'
import { ProductType } from '../../../types'
import { getValidationError } from '../utils'
import { maxAvailableDate, minAvailableDate } from '../constants'

import type { Actions, ReducerState } from '../filtersReducer'
import type { FunctionComponent, Dispatch } from 'react'

interface Props {
  isLoading: boolean
  dispatch: Dispatch<Actions>
  state: ReducerState
  organizationId: string
  shouldShowServerlessFilters: boolean
}

const MainFilters: FunctionComponent<Props> = ({
  isLoading,
  dispatch,
  state,
  organizationId,
  shouldShowServerlessFilters,
}) => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false)

  const renderDateRangePicker = useMemo(
    () => (
      <DateRangePicker
        isDisabled={isLoading}
        selectedStartDate={state.startDate}
        selectedEndDate={state.endDate}
        minDate={minAvailableDate}
        maxDate={maxAvailableDate}
        onChange={(dateRange) => {
          dispatch({ type: Action.SET_DATE_RANGE, payload: dateRange })
        }}
        errorMessage={getValidationError({
          startDate: state.startDate,
          endDate: state.endDate,
          viewBy: state.viewBy,
        })}
      />
    ),
    [dispatch, isLoading, state.endDate, state.startDate, state.viewBy],
  )

  const renderViewBySelect = useMemo(
    () => (
      <ViewBySelect
        value={state.viewBy}
        onChange={(viewBy) => dispatch({ type: Action.SET_VIEW_BY, payload: viewBy })}
        isLoading={isLoading}
      />
    ),
    [dispatch, isLoading, state.viewBy],
  )

  const renderChartTypeToggle = useMemo(
    () => (
      <ChartTypeToggle
        onChange={(chartType) => dispatch({ type: Action.SET_CHART_TYPE, payload: chartType })}
        selectedChartType={state.chartType}
        isLoading={isLoading}
      />
    ),
    [dispatch, isLoading, state.chartType],
  )

  const renderProductTypeSelect = useMemo(
    () => (
      <ProductTypeSelect
        values={state.productTypes}
        onChange={(productTypes) =>
          dispatch({
            type: Action.SET_PRODUCT_TYPES,
            payload: productTypes,
          })
        }
        isLoading={isLoading}
      />
    ),
    [dispatch, isLoading, state.productTypes],
  )

  const renderSolutionSelect = useMemo(
    () => (
      <SolutionSelect
        values={state.solutions}
        onChange={(solutions) =>
          dispatch({
            type: Action.SET_SOLUTIONS,
            payload: solutions,
          })
        }
        isLoading={isLoading}
        isDisabled={!state.productTypes.includes(ProductType.Projects)}
      />
    ),
    [dispatch, isLoading, state.productTypes, state.solutions],
  )

  const toggleFlyout = () => setIsFlyoutVisible(!isFlyoutVisible)

  const onResetFilters = () => dispatch({ type: Action.RESET_FILTERS })

  const renderFlyout = () => (
    <EuiFlyout ownFocus={true} onClose={toggleFlyout} data-test-id='billing-usage.flyout'>
      <EuiFlyoutHeader hasBorder={true}>
        <EuiTitle size='m'>
          <h2>
            <FormattedMessage id='billing-usage.filters' defaultMessage='Filters' />
          </h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFlexGroup wrap={true} direction='column'>
          <EuiFlexItem>{renderDateRangePicker}</EuiFlexItem>
          <EuiFlexItem>{renderViewBySelect}</EuiFlexItem>
          {shouldShowServerlessFilters && (
            <Fragment>
              <EuiHorizontalRule margin='s' />
              <EuiFlexItem>{renderProductTypeSelect}</EuiFlexItem>
              <EuiFlexItem>{renderSolutionSelect}</EuiFlexItem>
            </Fragment>
          )}
        </EuiFlexGroup>
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent='spaceBetween' responsive={false}>
          <EuiFlexItem grow={false}>
            {shouldShowServerlessFilters && (
              <EuiButtonEmpty
                iconType='cross'
                onClick={onResetFilters}
                flush='left'
                data-test-id='billing-usage.reset-filters-button'
              >
                <FormattedMessage
                  id='billing-usage.flyout.resetFilters'
                  defaultMessage='Reset filters'
                />
              </EuiButtonEmpty>
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={toggleFlyout}
              fill={true}
              data-test-id='billing-usage.close-filters-button'
            >
              <FormattedMessage id='billing-usage.flyout.close' defaultMessage='Close' />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </EuiFlyout>
  )

  return (
    <Fragment>
      {/* Normal screen */}
      <EuiHideFor sizes={['xs', 's', 'm']}>
        <EuiFlexGroup>
          <EuiFlexItem grow={3} style={{ minWidth: 300 }}>
            {renderDateRangePicker}
          </EuiFlexItem>
          <EuiFlexItem grow={3}>{renderViewBySelect}</EuiFlexItem>
          <EuiFlexItem grow={6}>{renderChartTypeToggle}</EuiFlexItem>
          <EuiFlexItem grow={0}>
            <CsvDownloadButton
              startDate={state.startDate}
              endDate={state.endDate}
              organizationId={organizationId}
              instanceId={state.instanceId}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiHideFor>
      {/* Mobile screen */}
      <EuiShowFor sizes={['xs', 's', 'm']}>
        <EuiFlexGroup responsive={false} gutterSize='xs'>
          <EuiFlexItem grow={3}> {renderChartTypeToggle}</EuiFlexItem>
          <EuiFlexItem grow={3}>
            <CsvDownloadButton
              startDate={state.startDate}
              endDate={state.endDate}
              organizationId={organizationId}
              instanceId={state.instanceId}
              emptyButtonComponent={true}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiFormRow fullWidth={true} hasEmptyLabelSpace={true}>
              <EuiButton
                onClick={toggleFlyout}
                fullWidth={true}
                data-test-id='billing-usage.show-filters-button'
              >
                <FormattedMessage id='billing-usage.show-filters' defaultMessage='Show filters' />
              </EuiButton>
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>
      {isFlyoutVisible && renderFlyout()}
    </Fragment>
  )
}

export default MainFilters
