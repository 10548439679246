/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import {
  EuiAccordion,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSkeletonText,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type {
  Region as AvailableRegion,
  GlobalDeploymentTemplateInfo,
  DeploymentTemplateInfoV2,
} from '@modules/cloud-api/v1/types'
import type {
  VersionNumber,
  RegionId,
  PlatformId,
  StackDeploymentCreateRequest,
} from '@modules/ui-types'
import { getPlatform } from '@modules/utils/platform'
import messages from '@modules/discovery-questions-components/messages'

// eslint-disable-next-line import/no-restricted-paths
import SelectCloudPlatform from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/SelectTemplate/SelectCloudPlatform'
// eslint-disable-next-line import/no-restricted-paths
import SelectHardwareProfile from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/SelectTemplate/SelectHardwareProfile'
// eslint-disable-next-line import/no-restricted-paths
import SelectRegion from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/SelectTemplate/SelectRegion'
// eslint-disable-next-line import/no-restricted-paths
import SelectVersion from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/SelectTemplate/SetupDeployment/SelectVersion'

import type { FunctionComponent } from 'react'

export interface Props {
  availablePlatforms: PlatformId[]
  availableRegions: AvailableRegion[] | null
  availableVersions: string[]
  deploymentTemplates?: DeploymentTemplateInfoV2[] | null
  disabledControls?: boolean
  editorState: StackDeploymentCreateRequest
  globalDeploymentTemplates: GlobalDeploymentTemplateInfo[]
  onChangePlatform: (platform: string) => void
  onChangeRegion: (regionId: string) => void
  onChangeTemplate: (template: DeploymentTemplateInfoV2) => void
  regionId?: RegionId
  setVersion: (version: VersionNumber) => void
  trialMaxedOut?: boolean
  version: string | null
  whitelistedVersions: string[]
}

const AdvancedSettings: FunctionComponent<Props> = (props) => {
  const {
    availablePlatforms,
    availableRegions,
    availableVersions,
    deploymentTemplates,
    editorState,
    globalDeploymentTemplates,
    onChangePlatform,
    onChangeRegion,
    onChangeTemplate,
    regionId,
    setVersion,
    trialMaxedOut,
    version,
    whitelistedVersions,
  } = props

  const hiddenAccordionId = useGeneratedHtmlId({ prefix: 'hiddenAccordion' })
  const cloudPlatformHtmlId = useGeneratedHtmlId()
  const regionHtmlId = useGeneratedHtmlId()

  const platform = getPlatform(regionId)

  const hiddenButton = css`
    .euiAccordion__triggerWrapper {
      display: none;
    }
  `

  if (!version || !regionId) {
    return <EuiSkeletonText />
  }

  const { deploymentTemplate } = editorState

  return (
    <EuiFlexGroup direction='column' gutterSize='m'>
      <EuiFlexGroup direction='row'>
        <EuiFlexItem>
          <EuiFormRow
            label={
              <EuiTitle size='xxxs'>
                <EuiText id={cloudPlatformHtmlId}>
                  <FormattedMessage {...messages.cloudProvider} />
                </EuiText>
              </EuiTitle>
            }
          >
            <SelectCloudPlatform
              prepend={false}
              restoreFromSnapshot={false}
              platform={platform}
              availablePlatforms={availablePlatforms}
              onChange={onChangePlatform}
              disabled={trialMaxedOut}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow
            label={
              <EuiTitle size='xxxs'>
                <EuiText id={regionHtmlId}>
                  <FormattedMessage {...messages.region} />
                </EuiText>
              </EuiTitle>
            }
          >
            <SelectRegion
              prepend={false}
              regionId={regionId}
              restoreFromSnapshot={false}
              availableRegions={availableRegions}
              onChange={onChangeRegion}
              disabled={trialMaxedOut}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiAccordion css={hiddenButton} id={hiddenAccordionId}>
        <EuiFlexGroup data-test-id='advanced-settings-content' gutterSize='m' direction='row'>
          <EuiFlexItem>
            <SelectHardwareProfile
              onChange={onChangeTemplate}
              version={version}
              currentTemplate={deploymentTemplate}
              stackTemplates={globalDeploymentTemplates}
              deploymentTemplates={deploymentTemplates}
            />
          </EuiFlexItem>

          <EuiFlexItem>
            <SelectVersion
              version={version}
              availableVersions={availableVersions}
              whitelistedVersions={whitelistedVersions}
              setVersion={setVersion}
              disabled={trialMaxedOut}
              regionId={regionId}
              editorState={editorState}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiAccordion>
    </EuiFlexGroup>
  )
}

export default AdvancedSettings
