/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'

import { EuiSkeletonText } from '@elastic/eui'

import type { Info, UserProfile } from '@modules/ui-types'
import { useGetCostsItemsV2 } from '@modules/billing-lib/hooks'
import { processQueryParams } from '@modules/billing-api/utils'
import { CuiAlert } from '@modules/cui/Alert'
import { isPrepaidConsumptionCustomer } from '@modules/billing-lib/utils'

import RatePanel from '..'
import { messages } from '../../messages'
import { getDaysLeftInBillingCycle } from '../../lib'

import type { FunctionComponent } from 'react'

interface Props {
  profile: UserProfile
}

const MonthToDateUsage: FunctionComponent<Props> = ({ profile }) => {
  const { formatMessage } = useIntl()
  const isPrepaidConsumptionUser = isPrepaidConsumptionCustomer(profile)

  const { data, isLoading, isError } = useGetCostsItemsV2({
    pathParameters: { organization_id: profile.organization_id },
    queryParameters: processQueryParams({
      from: moment.utc().startOf('month').format(),
      to: moment.utc().endOf('day').format(),
    }),
  })

  if (isLoading) {
    return <EuiSkeletonText lines={3} />
  }

  if (isError) {
    return (
      <CuiAlert type='error' data-test-id='error-message'>
        <FormattedMessage
          id='month-to-date-error'
          defaultMessage="Something isn't right. Please wait a moment and try refreshing the page. If the error persists, contact Support."
        />
      </CuiAlert>
    )
  }

  const getInfo = (): Info[] => {
    if (profile.is_trial) {
      return [
        {
          text: (
            <FormattedMessage
              id='cost-analysis.trial-started.monthly-cost-info'
              defaultMessage='FREE during trial'
            />
          ),
        },
        {
          text: (
            <FormattedMessage
              id='cost-analysis.trial-started.monthly-cost-after-trial-info'
              defaultMessage='Usage is based on your active deployment/project rates.'
            />
          ),
        },
      ]
    }

    return [
      {
        text: (
          <FormattedMessage
            id='cost-analysis.days-left-in-billing-cycle'
            defaultMessage='{days} {days, plural, one {day} other {days}} left in billing cycle'
            values={{ days: getDaysLeftInBillingCycle() }}
          />
        ),
      },
    ]
  }

  return (
    <RatePanel
      isPrepaidConsumptionUser={isPrepaidConsumptionUser}
      description={{
        text: formatMessage(messages.monthToDateUsage),
      }}
      info={getInfo()}
      rate={data?.total_ecu}
    />
  )
}

export default MonthToDateUsage
