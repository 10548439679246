/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import { getProjectMetrics } from '@modules/autoops-api'
import {
  CHART_OPTIONS_BY_PROJECT_TYPE,
  DEFAULT_CHART_OPTIONS,
  METRIC_NAMES,
  METRIC_SERIES_NAMES,
  STATS_BY_PROJECT_TYPE,
} from '@modules/autoops-lib/constants'
import type { CHART_OPTIONS } from '@modules/autoops-lib/constants'
import type { ProjectType } from '@modules/ui-types/projects'

export const useGetProjectMetricsQuery = ({
  projectId,
  projectType,
  organizationId,
}: {
  projectId: string
  projectType: ProjectType
  organizationId: string
}) =>
  useQuery(
    ['getAutoOpsMetrics', projectId, projectType, organizationId],
    () => {
      const stats = STATS_BY_PROJECT_TYPE[projectType]

      return getProjectMetrics({
        projectId,
        organizationId,
        stats,
      })
    },
    {
      select: (data) =>
        data.metricSeries.map((item) => {
          const options: CHART_OPTIONS =
            CHART_OPTIONS_BY_PROJECT_TYPE[item.metricName] ?? DEFAULT_CHART_OPTIONS

          return {
            name: METRIC_SERIES_NAMES[item.metricName] ?? item.metricName,
            series: item.metrics.map((metricItem) => ({
              name: METRIC_NAMES[metricItem.name] ?? metricItem.name,
              data: metricItem.data,
            })),
            options,
          }
        }),
    },
  )
