/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { BillingDetails } from '@modules/billing-api/customTypes'
import { BillingModel, PartnerSelling } from '@modules/ui-types/billing'

import { MARKETPLACE_CHANNELS } from './constants'

export function isPrepaidConsumptionCustomer({ billing_model }: BillingDetails): boolean {
  return billing_model === BillingModel.PrepaidConsumption
}

export function isPrepaidConsumptionDirectCustomer({
  billing_model,
  channel,
}: BillingDetails): boolean {
  return billing_model === BillingModel.PrepaidConsumption && channel === 'direct'
}

export function isPrepaidConsumptionDirectResellerCustomer({
  billing_model,
  channel,
  partner_selling,
}: BillingDetails): boolean {
  return (
    billing_model === BillingModel.PrepaidConsumption &&
    channel === 'direct' &&
    partner_selling === PartnerSelling.Reseller
  )
}

export function isMarketplaceCustomer({ channel }: BillingDetails): boolean {
  if (!channel) {
    return false
  }

  return MARKETPLACE_CHANNELS.includes(channel)
}

export function isAnnualAndOverages({ billing_model }: BillingDetails): boolean {
  return billing_model === 'annual_overages'
}

export function isDrawdown({ billing_model }: BillingDetails): boolean {
  return billing_model === 'drawdown'
}

export function isMonthly({ billing_model }: BillingDetails): boolean {
  return billing_model === 'monthly'
}

export function isInactive({ billing_model }: BillingDetails): boolean {
  return billing_model === 'none'
}

export const getMarketplaceDisplayName = (channel: BillingDetails['channel']): string => {
  if (!channel) {
    return ''
  }

  return {
    gcp: 'GCP',
    azure: 'Azure',
    aws: 'AWS',
  }[channel]
}

export function isTrial({ trial_state }: BillingDetails): boolean {
  if (!trial_state) {
    return false
  }

  return ['in_trial', 'in_trial_extended'].includes(trial_state)
}
