/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiCheckableCard,
  EuiForm,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui'

import { UseCaseOptionsType } from '@modules/discovery-questions-lib/utils'

import { DiscoveryQuestionStepLayout } from './DiscoveryQuestionStepLayout'
import { useCardStyleOnHover } from './styles'

import type { FunctionComponent } from 'react'

type Props = {
  useCase: UseCaseOptionsType | undefined
  onChangeUseCase: (value: UseCaseOptionsType) => void
  onUseCaseConfirmed: () => void
}

const UseCase: FunctionComponent<Props> = ({
  useCase,
  onChangeUseCase,
  onUseCaseConfirmed,
}: Props) => {
  const radioGroupId = useGeneratedHtmlId({ prefix: 'radioGroup' })

  const cardStyleOnHover = useCardStyleOnHover()

  const options = [
    {
      key: UseCaseOptionsType.search,
      label: <FormattedMessage id='discovery-questions-use-case.radio1' defaultMessage='Search' />,
      description: (
        <FormattedMessage
          id='discovery-questions-use-case.radio1-sub'
          defaultMessage='Develop your own search use cases with Elasticsearch APIs, storage, search, and analytics capabilities.'
        />
      ),
      htmlId: useGeneratedHtmlId(),
    },
    {
      key: UseCaseOptionsType.observability,
      label: (
        <FormattedMessage id='discovery-questions-use-case.radio2' defaultMessage='Observability' />
      ),
      description: (
        <FormattedMessage
          id='discovery-questions-use-case.radio2-sub'
          defaultMessage='Collect and analyze your logs, APM data, and traces.'
        />
      ),
      htmlId: useGeneratedHtmlId(),
    },
    {
      key: UseCaseOptionsType.security,
      label: (
        <FormattedMessage id='discovery-questions-use-case.radio3' defaultMessage='Security' />
      ),
      description: (
        <FormattedMessage
          id='discovery-questions-use-case.radio3-sub'
          defaultMessage='Detect and respond to threats by unifying your SIEM, endpoint, and cloud asset security data.'
        />
      ),
      htmlId: useGeneratedHtmlId(),
    },
  ]

  return (
    <EuiForm
      onSubmit={(e) => {
        e.preventDefault()
        onUseCaseConfirmed()
      }}
      component={'form'}
    >
      <DiscoveryQuestionStepLayout
        title={
          <FormattedMessage
            id='discovery-questions-use-case.title'
            defaultMessage='Which use case are you looking to try first?'
          />
        }
        description={
          <FormattedMessage
            id='discovery-questions-use-case.subtitle'
            defaultMessage="We'll help you get set up with a relevant Elastic solution."
          />
        }
        footer={
          <EuiButton
            data-test-id='signup.discovery-questions.submit'
            fill={true}
            type={'submit'}
            disabled={!useCase}
          >
            <FormattedMessage
              id='discovery-questions-use-case.continue'
              defaultMessage='Continue'
            />
          </EuiButton>
        }
      >
        {options.map(({ key, label, description, htmlId }) => (
          <div data-track-id={`signup.discovery-questions.${key}`} key={key}>
            <EuiCheckableCard
              data-test-id='signup.discovery-questions.use-case'
              id={htmlId}
              css={cardStyleOnHover}
              label={
                <Fragment>
                  <EuiTitle size='xs'>
                    <h2>{label}</h2>
                  </EuiTitle>
                  <EuiText size='s'>{description}</EuiText>
                </Fragment>
              }
              name={radioGroupId}
              value={key}
              checked={useCase === key}
              onClick={() => onChangeUseCase(key as UseCaseOptionsType)}
              onChange={() => onChangeUseCase(key as UseCaseOptionsType)}
            />
          </div>
        ))}
      </DiscoveryQuestionStepLayout>
    </EuiForm>
  )
}

export default UseCase
