/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiDescribedFormGroup } from '@elastic/eui'

import { CuiLink } from '@modules/cui/Link'

import { getRegionId } from '@/lib/stackDeployments/selectors/fundamentals'

import EnableCrossClusterReplicationCallout from '../../../../components/CrossClusterReplication/EnableCrossClusterReplicationCallout'
import { platformTrustManagementUrl } from '../../../../lib/urlBuilder'
import DeploymentTrustRelationshipTable from '../../../../components/DeploymentTrustRelationships/DeploymentTrustRelationshipTable'

import type { AllProps as Props, State } from './types'

class DeploymentTrustManagement extends React.Component<Props, State> {
  componentDidMount(): void {
    this.props.fetchCurrentAccount()
    this.props.fetchTrustRelationships()
  }

  componentWillUnmount(): void {
    this.props.resetFetchCurrentAccount()
    this.props.resetFetchTrustRelationships()
  }

  render(): JSX.Element {
    const { deployment } = this.props

    const regionId = getRegionId({ deployment })

    const title = (
      <h3>
        <FormattedMessage id='deploymentTrustManagement.title' defaultMessage='Trust management' />
      </h3>
    )

    const description = (
      <Fragment>
        <p>
          <FormattedMessage
            id='deploymentTrustManagement.description'
            defaultMessage='List all deployments and clusters that this deployment is allowed to connect remotely to and define the authentication method (certificate or API key) to use for each connection.'
          />
        </p>
        <p>
          <FormattedMessage
            id='deploymentTrustManagement.description-action'
            defaultMessage='For remote connections to other Elastic Cloud Enterprise (ECE) environments, you must first configure platform-level trust in {link}.'
            values={{
              link: (
                <CuiLink to={platformTrustManagementUrl(regionId)}>
                  <FormattedMessage
                    id='deploymentTrustManagement.description-action-link'
                    defaultMessage='Trust management'
                  />
                </CuiLink>
              ),
            }}
          />
        </p>
      </Fragment>
    )

    return (
      <Fragment>
        <EuiDescribedFormGroup
          ratio='third'
          fullWidth={true}
          title={title}
          description={description}
          data-test-id='trust-management'
        >
          <div>
            <EnableCrossClusterReplicationCallout deployment={deployment} />
            {this.renderDetails()}
          </div>
        </EuiDescribedFormGroup>
      </Fragment>
    )
  }

  renderDetails(): JSX.Element {
    const { deployment, trustRelationships, keystore } = this.props

    return (
      <DeploymentTrustRelationshipTable
        deployment={deployment}
        trustRelationships={trustRelationships}
        keystore={keystore}
      />
    )
  }
}

export default DeploymentTrustManagement
