/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiTableRow,
  EuiTableRowCell,
  EuiButtonIcon,
  EuiHealth,
  EuiNotificationBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
} from '@elastic/eui'

import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import {
  ColorKeyForOtherDeployments,
  ColorKeyForOtherProjects,
} from '@/components/User/BillingUsage/constants'

interface CommonItem {
  name: string
  id: string
}

export interface AggregationTableRowProps<Total, Item> {
  totals: Total
  items: Item[]
  onItemSelected: (selectedItemId: string, selectedItemName?: string) => void
  renderRow: (args: Item | Total) => React.ReactNode
  type: 'deployment' | 'project'
}

const AggregationTableRowV2 = <Total, Item>({
  totals,
  items,
  type,
  onItemSelected,
  renderRow,
}: AggregationTableRowProps<Total, Item & CommonItem>) => {
  const [showDetails, setShowDetails] = useState<boolean>(false)

  if (!items.length) {
    return null
  }

  return (
    <Fragment>
      <EuiTableRow isExpandable={true}>
        <EuiTableRowCell isExpander={true}>
          <EuiFlexGroup alignItems='center' gutterSize='s' responsive={false}>
            <EuiFlexItem grow={false}>
              <EuiHealth
                color={
                  type === 'deployment'
                    ? colorForInstances.get(ColorKeyForOtherDeployments)
                    : colorForInstances.get(ColorKeyForOtherProjects)
                }
              >
                {type === 'deployment' && (
                  <FormattedMessage
                    id='billing-aggregation-row.all-deployments'
                    defaultMessage='All other deployments'
                  />
                )}
                {type === 'project' && (
                  <FormattedMessage
                    id='billing-aggregation-row.all-projects'
                    defaultMessage='All other projects'
                  />
                )}
              </EuiHealth>
            </EuiFlexItem>
            <EuiFlexItem grow={false} data-test-id='aggregation-table-row.badge'>
              <EuiNotificationBadge color='subdued' style={{ minWidth: 30, whiteSpace: 'nowrap' }}>
                {items.length}
              </EuiNotificationBadge>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                data-test-id='aggregation-table-row.toggleDetailsBtn'
                onClick={() => setShowDetails(!showDetails)}
                aria-label={showDetails ? 'Collapse' : 'Expand'}
                iconType={showDetails ? 'arrowUp' : 'arrowDown'}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiTableRowCell>
        {renderRow(totals)}
      </EuiTableRow>

      {showDetails &&
        items.map((item) => (
          <EuiTableRow
            data-test-id='aggregation-table-row.expandedRow'
            isExpandedRow={true}
            key={item.id}
            className='euiTableCellContent--alignRight'
          >
            <EuiTableRowCell>
              <EuiLink
                data-test-id='aggregation-table-row.expandedRowLink'
                onClick={() => onItemSelected(item.id, item.name)}
                style={{ paddingLeft: 20 }}
              >
                {item.name || item.id}
              </EuiLink>
            </EuiTableRowCell>
            {renderRow(item)}
          </EuiTableRow>
        ))}
    </Fragment>
  )
}

export default AggregationTableRowV2
