/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import history from '@modules/utils/history'

export function getQuerystringItems(qs?: string): Record<string, string | null> {
  return Object.fromEntries(
    new URLSearchParams(qs ? decodeURIComponent(qs) : window.location.search),
  )
}

export function getQuerystringItem<TSchema extends Record<string, string | null>>(
  key: Extract<keyof TSchema, string>,
): string | null {
  return new URLSearchParams(window.location.search).get(key)
}

export function setQuerystringItem<TSchema extends Record<string, string | undefined>>(
  key: Extract<keyof TSchema, string>,
  value: string | number | boolean | null | undefined,
) {
  const qs = new URLSearchParams(window.location.search)

  if (value === null || value === undefined) {
    qs.delete(key)
  } else {
    qs.set(key, value.toString())
  }

  history.replace('?' + qs.toString())
}
