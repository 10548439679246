/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import type { Price } from '@modules/billing-api/v1/types'

export const filterPricesByTier = (prices: Price[], tier: Price['tier']) =>
  prices.filter((price) => price.tier === tier)

export const calculatePricesBasedOnBoostDays = (prices: Price[], boostDays: number) =>
  prices.map((price) => ({
    ...price,
    unit_amount: +(price.unit_amount * boostDays * 1.2).toFixed(4), // TODO the formula must be updated once defined
  }))

export const calculatePricesBasedOnSearchPower = (prices: Price[], searchPower: number) =>
  prices.map((price) => ({
    ...price,
    unit_amount: +(price.unit_amount * searchPower * 0.9).toFixed(4), // TODO the formula must be updated once defined
  }))
