/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

// ToDo: remove casting where possible https://github.com/elastic/cloud/pull/125301#discussion_r1679467580

import { isEmpty } from 'lodash'

import type { EuiComboBoxOptionOption } from '@elastic/eui'

import { makeSsoUrl } from '@modules/project-lib/kibanaLinks'
import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import {
  ROLE_IDS,
  elasticsearchRoleIdsByLabel,
  observabilityRoleIdsByLabel,
  resourceRoleIdsOrdering,
  securityRoleIdsByLabel,
} from '@modules/role-assignments-lib/types'
import type {
  RoleAssignmentsTableItem,
  NormalisedResourceRoleAssignment,
  ResourceType,
  ResourceRoleId,
  ResourceRoleAssignment,
  Resource,
  TableItemRoleDescriptor,
} from '@modules/role-assignments-lib/types'
import type { ProjectType } from '@modules/ui-types/projects'

export const normaliseDeploymentRoleAssignments = (
  roleAssignments: Array<ResourceRoleAssignment<'deployment'>> = [],
): Array<NormalisedResourceRoleAssignment<'deployment'>> =>
  roleAssignments.filter(hasValidRoleId('deployment')).map((roleAssignment) => {
    if (roleAssignment.all) {
      return {
        ...roleAssignment,
        role_id: roleAssignment.role_id as ResourceRoleId<'deployment'>,
      }
    }

    return {
      ...roleAssignment,
      role_id: roleAssignment.role_id as ResourceRoleId<'deployment'>,
      ids: roleAssignment.deployment_ids?.map((id) => id),
    }
  })

export const getProjectSpecificViewerRole = (
  projectType: ProjectType,
): ResourceRoleId<ProjectType> => {
  switch (projectType) {
    case 'elasticsearch':
      return elasticsearchRoleIdsByLabel.viewer
    case 'observability':
      return observabilityRoleIdsByLabel.viewer
    case 'security':
      return securityRoleIdsByLabel.viewer
    default:
      return elasticsearchRoleIdsByLabel.viewer
  }
}

export const normaliseProjectRoleAssignments =
  <T extends ProjectType>(projectType?: ProjectType) =>
  (
    roleAssignments: Array<ResourceRoleAssignment<T>> = [],
  ): Array<NormalisedResourceRoleAssignment<T>> =>
    roleAssignments.filter(notNoneRole()).map((roleAssignment) => {
      if (roleAssignment.all) {
        return {
          ...roleAssignment,
          role_id: roleAssignment.role_id as ResourceRoleId<T>,
        }
      }

      // Handle custom roles
      if (roleAssignment.application_roles?.length && projectType) {
        return {
          ...roleAssignment,
          role_id: getProjectSpecificViewerRole(projectType) as ResourceRoleId<T>,
          ids: roleAssignment.project_ids?.map((id) => id),
          application_roles: roleAssignment.application_roles,
        }
      }

      // Handle pre-defined roles
      return {
        ...roleAssignment,
        role_id: roleAssignment.role_id as ResourceRoleId<T>,
        ids: roleAssignment.project_ids?.map((id) => id),
      }
    })

export const roleAssignmentsToTableItems = <
  T extends ResourceType,
  R extends ResourceRoleAssignment<T>,
>(
  resourceType: T,
  resources: Array<Resource<T>> | undefined,
  roleAssignments: R[] = [],
  normaliseRoleAssignments: (roleAssignments: R[]) => Array<NormalisedResourceRoleAssignment<T>>,
): Array<RoleAssignmentsTableItem<T>> => {
  if (resources === undefined) {
    return []
  }

  const normalisedRoleAssignments = normaliseRoleAssignments(roleAssignments)
  const allRoleAssignments = getAllRoleAssignments(normalisedRoleAssignments)

  if (allRoleAssignments.length > 0) {
    return resources.map((resource) => ({
      resourceType,
      id: resource.id,
      name: resource.name,
      roles: allRoleAssignments.map((allRole) => ({
        roleId: allRole.role_id,
      })),
      isDisabled: true,
      ...('endpoints' in resource && {
        roleManagementUrl: makeSsoUrl(resource, {
          kibanaDeepLink: '/app/management/security/roles',
        }),
      }),
    }))
  }

  return specificRoleAssignmentsToTableItems(resourceType, resources, normalisedRoleAssignments)
}

export const specificRoleAssignmentsToTableItems = <T extends ResourceType>(
  resourceType: T,
  resources: Array<Resource<T>> | undefined,
  normalisedRoleAssignments: Array<NormalisedResourceRoleAssignment<T>> = [],
): Array<RoleAssignmentsTableItem<T>> => {
  if (resources === undefined) {
    return []
  }

  const specificRoleAssignments = filterRoleAssignments(
    normalisedRoleAssignments,
    'specificRoleAssignments',
  )

  return resources.map((resource) => {
    const roleAssignments = specificRoleAssignments.filter(
      (specificRoleAssignment) => specificRoleAssignment.ids?.includes(resource.id) ?? false,
    )

    const emptyRoleAssignment: RoleAssignmentsTableItem<T> = {
      resourceType,
      id: resource.id,
      name: `${resource.name} (${resource.id.slice(0, 6)})`,
      roles: [],
      isDisabled: false,
      ...('endpoints' in resource && {
        roleManagementUrl: makeSsoUrl(resource, {
          kibanaDeepLink: '/app/management/security/roles',
        }),
      }),
    }

    if (roleAssignments.length === 0) {
      return emptyRoleAssignment
    }

    const roles: TableItemRoleDescriptor[] = []

    roleAssignments.forEach((roleAssignment) => {
      if (
        // Handle custom role
        (resourceType === 'elasticsearch' ||
          resourceType === 'observability' ||
          resourceType === 'security') &&
        roleAssignment.application_roles?.length
      ) {
        roles.push(
          ...roleAssignment.application_roles.map((customRole) => ({
            roleId: customRole,
            isCustomRole: true,
          })),
        )
      } else {
        // Predefined role
        roles.push({ roleId: roleAssignment.role_id })
      }
    })

    return { ...emptyRoleAssignment, roles }
  })
}

export const deleteResourcesRoleAssignments = (
  roleAssignments: RoleAssignments,
): RoleAssignments => ({
  ...roleAssignments,
  deployment: getEmptyRoleAssignments().deployment,
  project: getEmptyRoleAssignments().project,
})

export const filterItemsByIdOrName = <T extends ResourceType>(
  items: Array<RoleAssignmentsTableItem<T>>,
  query: string,
): Array<RoleAssignmentsTableItem<T>> =>
  items.filter((item) => {
    const lowerCasedQuery = query.toLocaleLowerCase().trim()

    return (
      item.id.toLocaleLowerCase().includes(lowerCasedQuery) ||
      item.name.toLocaleLowerCase().includes(lowerCasedQuery)
    )
  })

export const replaceAllRoleAssignment = <T extends ResourceType>(
  organizationId: string,
  roles: Array<EuiComboBoxOptionOption<ResourceRoleId<T>>>,
  onChangeRoleAssignments: (roleAssignments: Array<ResourceRoleAssignment<T>>) => void,
): void =>
  onChangeRoleAssignments(
    roles.map(
      (role) =>
        ({
          organization_id: organizationId,
          role_id: role.value ?? '',
          all: true,
        } as ResourceRoleAssignment<T>),
    ),
  )

export const tableItemsToDeploymentRoleAssignment =
  (organizationId: string) =>
  (
    items: Array<RoleAssignmentsTableItem<'deployment'>>,
  ): Array<ResourceRoleAssignment<'deployment'>> => {
    const assignments: Array<ResourceRoleAssignment<'deployment'>> = []
    items.forEach((item) => {
      // Only one roles assignment is possible for deployments
      const roleId = item.roles.at(0)?.roleId

      if (roleId) {
        const found = assignments.find((assignment) => assignment.role_id === roleId)

        if (found) {
          found.deployment_ids?.push(item.id)
        } else {
          assignments.push({
            role_id: roleId,
            organization_id: organizationId,
            deployment_ids: [item.id],
          })
        }
      }
    })

    return assignments
  }

export const tableItemsToProjectRoleAssignment =
  (organizationId: string) =>
  (
    items: Array<RoleAssignmentsTableItem<ProjectType>>,
  ): Array<ResourceRoleAssignment<ProjectType>> => {
    const projectType = items.at(0)?.resourceType ?? 'elasticsearch'

    const predefinedAssignments: Array<ResourceRoleAssignment<ProjectType>> = []
    const customAssignments: Array<ResourceRoleAssignment<ProjectType>> = []

    items.forEach((item) => {
      item.roles.forEach((role) => {
        if (role.isCustomRole) {
          const found = customAssignments.find((rA) => rA.project_ids?.includes(item.id))

          if (found) {
            found.application_roles?.push(role.roleId)
          } else {
            customAssignments.push({
              organization_id: organizationId,
              role_id: getProjectSpecificViewerRole(projectType),
              all: false,
              project_ids: [item.id],
              application_roles: [role.roleId],
            })
          }
        } else {
          const found = predefinedAssignments.find((rA) => rA.role_id === role.roleId)

          if (found) {
            found.project_ids?.push(item.id)
          } else {
            predefinedAssignments.push({
              organization_id: organizationId,
              role_id: role.roleId,
              all: false,
              project_ids: [item.id],
            })
          }
        }
      })
    })

    return predefinedAssignments.concat(customAssignments)
  }

export const updateTableItemRoles = <T extends ResourceType, R extends ResourceRoleAssignment<T>>(
  items: Array<RoleAssignmentsTableItem<T>>,
  itemId: string,
  selectedRoleDescriptors: TableItemRoleDescriptor[],
  tableItemsToRoleAssignment: (items: Array<RoleAssignmentsTableItem<T>>) => R[],
  onChangeRoleAssignments: (roleAssignments: R[]) => void,
): void => {
  // Remove any invalid values
  const validRoleDescriptors = selectedRoleDescriptors.filter((role) => role.roleId.length > 0)

  const updateItems = items.map((item): RoleAssignmentsTableItem<T> => {
    if (item.id === itemId) {
      return {
        ...item,
        roles: validRoleDescriptors.map((role) => ({
          roleId: role.roleId,
          isCustomRole: role.isCustomRole,
        })),
      }
    }

    return {
      // Remove any invalid or 'no roles" selection (empty strings), it is a UI selection only
      ...item,
      roles: item.roles.filter((role) => role.isCustomRole || role.roleId.length > 0),
    }
  })

  const roleAssignments = tableItemsToRoleAssignment(updateItems)

  onChangeRoleAssignments(roleAssignments)
}

export const setDeploymentRoleAssignments = (
  roleAssignments: RoleAssignments,
  deploymentRoleAssignments: Array<ResourceRoleAssignment<'deployment'>>,
  onChangeRoleAssignments: (roleAssignments: RoleAssignments) => void,
): void =>
  onChangeRoleAssignments({
    ...roleAssignments,
    deployment: deploymentRoleAssignments,
  })

export const setProjectRoleAssignments = <
  T extends ProjectType,
  R extends ResourceRoleAssignment<T>,
>(
  roleAssignments: RoleAssignments,
  projectType: T,
  projectRoleAssignments: R[],
  onChangeRoleAssignments: (roleAssignments: RoleAssignments) => void,
): void =>
  onChangeRoleAssignments({
    ...roleAssignments,
    ...{
      project: {
        ...roleAssignments.project,
        [projectType]: projectRoleAssignments,
      },
    },
  })

export const getAllRoleAssignments = <T extends ResourceType>(
  roleAssignments: Array<NormalisedResourceRoleAssignment<T>>,
): Array<NormalisedResourceRoleAssignment<T>> =>
  filterRoleAssignments(roleAssignments, 'allRoleAssignments')

export const getEmptyRoleAssignments = (): RoleAssignments => ({
  organization: [],
  deployment: [],
  project: {
    elasticsearch: [],
    observability: [],
    security: [],
  },
})

export const isRoleAssignmentsEmpty = (roleAssignments: RoleAssignments | undefined): boolean =>
  isEmpty(roleAssignments) ||
  (isEmpty(roleAssignments.organization) && isResourcesRoleAssignmentsEmpty(roleAssignments))

export const isResourcesRoleAssignmentsEmpty = (
  roleAssignments: RoleAssignments | undefined,
): boolean =>
  isEmpty(roleAssignments?.deployment) &&
  isEmpty(roleAssignments?.project?.elasticsearch) &&
  isEmpty(roleAssignments?.project?.observability) &&
  isEmpty(roleAssignments?.project?.security)

export const filterRoleAssignments = <T extends ResourceType>(
  roleAssignments: Array<NormalisedResourceRoleAssignment<T>>,
  includeOnly:
    | 'allRoleAssignments'
    | 'specificRoleAssignments'
    | 'specificPredefinedRoleAssignments'
    | 'customRoleAssignments',
): Array<NormalisedResourceRoleAssignment<T>> => {
  switch (includeOnly) {
    case 'allRoleAssignments':
    case 'specificRoleAssignments':
      const isAll = includeOnly === 'allRoleAssignments'

      return roleAssignments
        .filter((roleAssignment) => Boolean(roleAssignment.all) === isAll)
        .sort(sortRoleAssignments)
    case 'specificPredefinedRoleAssignments':
      return roleAssignments
        .filter(
          (roleAssignment) =>
            !roleAssignment.all &&
            (roleAssignment.application_roles === undefined ||
              roleAssignment.application_roles.length === 0),
        )
        .sort(sortRoleAssignments)
    case 'customRoleAssignments':
      return roleAssignments
        .filter(
          (roleAssignment) =>
            !roleAssignment.all &&
            roleAssignment.application_roles &&
            roleAssignment.application_roles.length > 0,
        )
        .sort(sortRoleAssignments)
    default:
      return []
  }
}

const hasValidRoleId =
  <T extends ResourceType>(resourceType: T) =>
  (roleAssignment: ResourceRoleAssignment<T>): roleAssignment is ResourceRoleAssignment<T> => {
    const roleIds: readonly string[] = ROLE_IDS[resourceType]
    return roleIds.includes(roleAssignment.role_id)
  }

const notNoneRole =
  <T extends ResourceType>() =>
  (roleAssignment: ResourceRoleAssignment<T>): roleAssignment is ResourceRoleAssignment<T> =>
    roleAssignment.role_id.length > 0

const sortRoleAssignments = <T extends ResourceType>(
  roleAssignment1: NormalisedResourceRoleAssignment<T>,
  roleAssignment2: NormalisedResourceRoleAssignment<T>,
): number =>
  resourceRoleIdsOrdering[roleAssignment1.role_id] -
  resourceRoleIdsOrdering[roleAssignment2.role_id]
