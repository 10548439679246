/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { debounce, find } from 'lodash'

import {
  EuiFieldText,
  EuiFormLabel,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
  EuiButtonIcon,
  EuiPopover,
  EuiButton,
} from '@elastic/eui'

import type { DeploymentSearchResponse } from '@modules/cloud-api/v1/types'
import type { ClusterSnapshot } from '@modules/ui-types'

import { getFirstSliderClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { getFirstEsId } from '@/lib/stackDeployments/selectors/clusters'

import { kibanaSnapshotAndRestoreUrl } from '../../../../../../../lib/serviceProviderDeepLinks'

import type { IntlShape } from 'react-intl'

type Props = {
  intl: IntlShape
  selectableSnapshots: ClusterSnapshot[]
  selectedDeployment: DeploymentSearchResponse | null
  selectedSnapshot?: string
  onSelectSnapshot: (snapshot: Pick<ClusterSnapshot, 'snapshot'> | null, id: string) => void
}

type State = {
  status: undefined | 'success' | 'partial' | 'failed' | 'notfound'
  showHelp: boolean
}

const messages = defineMessages({
  placeholder: {
    id: `snapshot-restore-from-remote.enter-snapshot.placeholder-latest-success`,
    defaultMessage: `Last successful full snapshot`,
  },
})

class EnterSnapshot extends React.Component<Props, State> {
  state: State = {
    status: undefined,
    showHelp: false,
  }

  input: HTMLInputElement | undefined = undefined

  static getDerivedStateFromProps(nextProps: Props): Partial<State> | null {
    // what it resets to upon change of the source deployment
    if (nextProps.selectedSnapshot === `__latest_success__`) {
      return { status: undefined }
    }

    return null
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props
    const { selectedDeployment, selectedSnapshot } = this.props

    const kibana = selectedDeployment
      ? getFirstSliderClusterFromGet({
          deployment: selectedDeployment,
          sliderInstanceType: `kibana`,
        })
      : null

    const label = (
      <EuiFormLabel style={{ width: 180 }}>
        <EuiFlexGroup justifyContent='flexStart' alignItems='center' gutterSize='xs'>
          <EuiFlexItem grow={false}>
            <FormattedMessage defaultMessage='Snapshot ID' id='enter-snapshot-label' />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPopover
              data-test-id='enter-snapshot-help-popover'
              anchorPosition='upCenter'
              ownFocus={true}
              button={
                <EuiButtonIcon
                  aria-label='enter-snapshot-help-trigger'
                  onClick={() => this.setState({ showHelp: !this.state.showHelp })}
                  iconType='iInCircle'
                />
              }
              isOpen={this.state.showHelp}
              closePopover={() => this.setState({ showHelp: false })}
            >
              <EuiText style={{ width: 350 }}>
                <p>
                  <FormattedMessage
                    id='enter-snapshot-help-text'
                    defaultMessage='Snapshot information can be found in Kibana. If the field is left blank, it will default to the last successful snapshot.'
                  />
                </p>
              </EuiText>
              {kibana && (
                <Fragment>
                  <EuiSpacer size='m' />
                  <EuiButton
                    href={kibanaSnapshotAndRestoreUrl({ resource: kibana })}
                    target='_blank'
                    iconType='popout'
                    iconSide='right'
                  >
                    <EuiIcon type='logoKibana' size='m' />

                    <FormattedMessage
                      id='enter-snapshot-kibana-link'
                      defaultMessage='Snapshot and Restore'
                    />
                  </EuiButton>
                </Fragment>
              )}
            </EuiPopover>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormLabel>
    )

    const value =
      selectedSnapshot && selectedSnapshot !== `__latest_success__` ? selectedSnapshot : ``
    const warnings = this.getWarnings()
    const errors = this.getErrors()

    return (
      <Fragment>
        <EuiFieldText
          inputRef={(el) => this.input === el}
          fullWidth={true}
          prepend={label}
          isInvalid={this.getIsInvalid()}
          disabled={!selectedDeployment}
          value={value}
          onChange={(e) => this.onChangeSnapshotName(e.target.value)}
          placeholder={formatMessage(messages.placeholder)}
          data-test-id='enter-snapshot-textbox'
        />
        {warnings && (
          <Fragment>
            <EuiSpacer size='xs' />
            <EuiFlexGroup justifyContent='flexEnd' alignItems='center' gutterSize='xs'>
              <EuiFlexItem grow={false}>
                <EuiIcon type='alert' color='warning' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText color='warning' size='xs'>
                  {warnings}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </Fragment>
        )}
        {errors && (
          <Fragment>
            <EuiSpacer size='xs' />
            <EuiFlexGroup justifyContent='flexEnd' alignItems='center' gutterSize='xs'>
              <EuiFlexItem grow={false}>
                <EuiIcon type='alert' color='danger' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText color='danger' size='xs'>
                  {errors}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </Fragment>
        )}
      </Fragment>
    )
  }

  getIsInvalid = () => {
    const { status } = this.state

    return status === `failed` || status === 'notfound'
  }

  getErrors = () => {
    const { status } = this.state

    if (status === `notfound`) {
      return (
        <FormattedMessage
          id='enter-snapshot-notfound'
          defaultMessage='Snapshot not found or invalid for restoration.'
        />
      )
    }

    if (status === `failed`) {
      return (
        <FormattedMessage id='enter-snapshot-failed' defaultMessage='This is a failed snapshot.' />
      )
    }

    return
  }

  getWarnings = () => {
    const { status } = this.state

    if (status === `partial`) {
      return (
        <FormattedMessage
          id='enter-snapshot-partial'
          defaultMessage='This is a partial snapshot.'
        />
      )
    }

    return
  }

  onChangeSnapshotName = (snapshotName: string) => {
    const { selectedDeployment, onSelectSnapshot } = this.props

    if (!selectedDeployment) {
      return
    }

    const clusterId = getFirstEsId({ deployment: selectedDeployment })!

    this.setState({ status: undefined }, () => {
      this.checkSnapshotDebounced.cancel()

      // if the name is blank, set the default of latest success
      onSelectSnapshot(
        { snapshot: snapshotName.trim() ? snapshotName : `__latest_success__` },
        clusterId,
      )

      // validate entry after short period of inactivity
      this.checkSnapshotDebounced(snapshotName)
    })
  }

  checkSnapshot = (snapshotName: string) => {
    const { selectableSnapshots, selectedDeployment } = this.props

    if (!selectedDeployment) {
      return
    }

    const foundSnapshot = find<ClusterSnapshot>(
      selectableSnapshots,
      ({ snapshot }) => snapshot === snapshotName.trim(),
    )

    switch (foundSnapshot?.state) {
      case `SUCCESS`:
        this.setState({ status: `success` })
        break
      case `PARTIAL`:
        this.setState({ status: `partial` })
        break
      case `FAILED`:
        this.setState({ status: `failed` })
        break
      default:
        this.setState({ status: `notfound` })
        return
    }
  }

  checkSnapshotDebounced = debounce(this.checkSnapshot, 500) // eslint-disable-line react/sort-comp
}

export default injectIntl(EnterSnapshot)
