/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { ExperienceLevelOptionsType } from '@modules/discovery-questions-lib/utils'

import CheckableCardFieldset from './CheckableCardFieldset'
import { DiscoveryQuestionStepLayout } from './DiscoveryQuestionStepLayout'

import type { FunctionComponent } from 'react'

type Props = {
  experienceLevel: ExperienceLevelOptionsType | undefined
  onChangeExperienceLevel: (value: ExperienceLevelOptionsType) => void
  onSubmitExperienceLevel: () => void
}

const ExperienceLevel: FunctionComponent<Props> = ({
  experienceLevel,
  onChangeExperienceLevel,
  onSubmitExperienceLevel,
}: Props) => {
  const options = [
    {
      label: {
        id: `discovery-questions-experience.radio-1`,
        defaultMessage: `I am new to Elastic`,
      },
      value: ExperienceLevelOptionsType.new,
    },
    {
      label: {
        id: `discovery-questions-experience.radio-2`,
        defaultMessage: `I have some experience with Elastic`,
      },
      value: ExperienceLevelOptionsType.experienced,
    },
    {
      label: { id: `discovery-questions-experience.radio-3`, defaultMessage: `I am an expert` },
      value: ExperienceLevelOptionsType.an_expert,
    },
  ]

  return (
    <DiscoveryQuestionStepLayout
      title={
        <FormattedMessage
          id='discovery-questions-experience.title'
          defaultMessage='How familiar are you with Elastic?'
        />
      }
    >
      <CheckableCardFieldset
        data-test-id='signup.discovery-questions.experience-level'
        prefix='experience_level'
        selectedValue={experienceLevel}
        options={options}
        onChange={onChangeExperienceLevel}
        onSubmit={onSubmitExperienceLevel}
      />
    </DiscoveryQuestionStepLayout>
  )
}

export default ExperienceLevel
