/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import {
  resourceRoleLabelByRoleId,
  CustomRoleBadgeLabel,
} from '@modules/role-assignments-lib/types'
import type { RoleLabel, ResourceType } from '@modules/role-assignments-lib/types'
import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import {
  getAllRoleAssignments,
  normaliseDeploymentRoleAssignments,
  normaliseProjectRoleAssignments,
} from '@modules/role-assignments-lib'
import type { ProjectType } from '@modules/ui-types/projects'

export type RoleBadgeInfo = {
  resourceType?: ResourceType
  roleLabel: RoleLabel
  count?: number | 'all'
}

export function getRoleBadgeInfoList(
  roleAssignments: RoleAssignments,
  customRolesEnabled: boolean,
): RoleBadgeInfo[] {
  const getNormaliseProjectRoleAssignments = (projectType: ProjectType) =>
    normaliseProjectRoleAssignments(projectType)(roleAssignments.project?.[projectType]).map(
      (roleAssignment) => ({
        ...roleAssignment,
        resourceType: projectType as ProjectType,
      }),
    )
  const normalizedRoleAssignments = [
    ...normaliseDeploymentRoleAssignments(roleAssignments.deployment).map((roleAssignment) => ({
      ...roleAssignment,
      resourceType: 'deployment' as ResourceType,
    })),
    ...['elasticsearch', 'observability', 'security'].flatMap(getNormaliseProjectRoleAssignments),
  ]

  // Filter out multiple ALL assignements if custom roles are disabled
  // The conditional assignment below can be removed one custom roles are enabled and the feature flag is deleted
  // Replace with this assignment:
  // const allRoleAssignments = getAllRoleAssignments(normalizedRoleAssignments)
  const allRoleAssignments = customRolesEnabled
    ? getAllRoleAssignments(normalizedRoleAssignments)
    : getAllRoleAssignments(
        normalizedRoleAssignments.filter(
          (value, index, array) =>
            array.findIndex((a) => a.resourceType === value.resourceType) === index,
        ),
      )

  const NUMBER_OF_RESOURCE_TYPES = 4 // number of value in ResourceType type

  // Determine which ALL roles apply to all resources and handle those first
  const badges: RoleBadgeInfo[] = []
  const uniqueAllRoleLabels = [
    ...new Set(
      allRoleAssignments.map(
        (allRoleAssignment) => resourceRoleLabelByRoleId[allRoleAssignment.role_id],
      ),
    ),
  ]

  const globalAllRoleLabels = new Set<RoleLabel>()
  uniqueAllRoleLabels.forEach((roleLabel) => {
    const matchedAssignments = allRoleAssignments.filter(
      (assignment) => resourceRoleLabelByRoleId[assignment.role_id] === roleLabel,
    )

    if (matchedAssignments.length === NUMBER_OF_RESOURCE_TYPES) {
      // Add to global all assignments
      globalAllRoleLabels.add(roleLabel)

      // Create global all badge
      badges.push({
        roleLabel,
        count: 'all',
      })
    }
  })

  // Filter out multiple assignements if custom roles are disabled
  // These constants can be removed one custom roles are enabled and the feature flag is deleted
  const filteredProjects: string[] = []
  const filteredAll: ResourceType[] = []

  // Handle the rest of the assignments
  normalizedRoleAssignments.forEach((roleAssignment) => {
    const { role_id, ids, resourceType, application_roles } = roleAssignment
    const roleLabel = resourceRoleLabelByRoleId[role_id]

    // Skip any global all assignments that were handled above
    if (!(roleAssignment.all && globalAllRoleLabels.has(roleLabel))) {
      let foundBadge: RoleBadgeInfo | undefined
      let customRole = false

      if (customRolesEnabled && application_roles && application_roles.length > 0) {
        customRole = true
        foundBadge = badges.find(
          (badge) =>
            badge.roleLabel === CustomRoleBadgeLabel && badge.resourceType === resourceType,
        )
      }

      if (customRole && foundBadge && typeof foundBadge.count === 'number') {
        foundBadge.count += 1 // we're counting number of affected projects, not number of roles assigned
      } else {
        // This IF block below filters out multiple role assignements when custom roles is disabled
        // Once the custom roles flag is deleted and the feature is officially enabled, the IF
        // can be removed and replaced with just the badges.push call.

        if (
          customRolesEnabled ||
          (!ids && !filteredAll.includes(resourceType)) ||
          (ids && ids?.filter((id) => !filteredProjects.includes(id)).length > 0)
        ) {
          badges.push({
            resourceType,
            roleLabel: customRole ? CustomRoleBadgeLabel : roleLabel,
            count: ids?.length || ('all' as const),
          })

          // if custom roles are disabled, build list to eliminate multiple assignements
          // The next block can be removed once custom roles are enabled and the feature flag is deleted

          if (!customRolesEnabled) {
            if (ids) {
              filteredProjects.push(...(ids ?? []))
            } else {
              filteredAll.push(resourceType)
            }
          }
        }
      }
    }
  })

  return badges
}
