/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiDescribedFormGroup } from '@elastic/eui'

import EnableCrossClusterReplicationCallout from '../../../../components/CrossClusterReplication/EnableCrossClusterReplicationCallout'
import DeploymentTrustRelationshipTable from '../../../../components/DeploymentTrustRelationships/DeploymentTrustRelationshipTable'

import type { AllProps } from './types'

class DeploymentTrustManagement extends React.Component<AllProps> {
  componentDidMount(): void {
    this.props.fetchCurrentAccount()
  }

  componentWillUnmount(): void {
    this.props.resetFetchCurrentAccount()
  }

  render(): JSX.Element {
    const { deployment } = this.props

    const title = (
      <h3>
        <FormattedMessage
          id='deploymentTrustManagement.ess.title'
          defaultMessage='Trust management'
        />
      </h3>
    )

    const description = (
      <Fragment>
        <p>
          <FormattedMessage
            id='deploymentTrustManagement.ess.description'
            defaultMessage='List all deployments and clusters that this deployment is allowed to connect remotely to and define the authentication method (certificate or API key) to use for each connection.'
          />
        </p>
      </Fragment>
    )
    return (
      <Fragment>
        <EuiDescribedFormGroup
          ratio='third'
          fullWidth={true}
          title={title}
          description={description}
          data-test-id='trust-management'
        >
          <div>
            <EnableCrossClusterReplicationCallout deployment={deployment} />
            {this.renderDetails()}
          </div>
        </EuiDescribedFormGroup>
      </Fragment>
    )
  }

  renderDetails(): JSX.Element {
    const { deployment, keystore } = this.props

    return <DeploymentTrustRelationshipTable deployment={deployment} keystore={keystore} />
  }
}

export default DeploymentTrustManagement
