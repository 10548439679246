/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'

import type { EuiPanelProps } from '@elastic/eui'
import { EuiPanel, EuiSpacer, EuiTitle, EuiText } from '@elastic/eui'

import { CuiThemedIcon } from '@modules/cui/ThemedIcon'

import type { SerializedStyles } from '@emotion/react'
import type { ReactNode, FC } from 'react'

interface Props {
  children?: ReactNode
  title?: ReactNode
  subtitle?: ReactNode
  image?: string
  darkImage?: string
  panelProps?: EuiPanelProps
  infoMessage?: ReactNode
  isFlowV2?: boolean
  imageCssOverride?: SerializedStyles
  panelCssOverride?: SerializedStyles
}

const LandingPageInnerContainer: FC<Props> = ({
  children,
  title,
  subtitle,
  image,
  darkImage,
  panelProps,
  infoMessage,
  isFlowV2,
  imageCssOverride,
  panelCssOverride,
}) => {
  const allPanelProps: EuiPanelProps = {
    ...(isFlowV2
      ? { hasShadow: false, color: 'transparent', paddingSize: 'none' }
      : { hasShadow: true, color: undefined }),
    ...panelProps,
  }

  const panelStyle = css({
    width: '100%',
    margin: '0 auto',
    maxWidth: isFlowV2 ? '320px' : '430px',
  })

  const imageStyle = css({
    width: '200px',
    height: '180px',
    margin: '0 auto',
    display: 'block',
  })

  return (
    <Fragment>
      <EuiPanel {...allPanelProps} css={[panelStyle, panelCssOverride]}>
        {image && (
          <Fragment>
            <CuiThemedIcon
              css={[imageStyle, imageCssOverride]}
              size='original'
              lightType={image}
              darkType={darkImage ? darkImage : image}
            />
            <EuiSpacer size='l' />
          </Fragment>
        )}

        {title && (
          <Fragment>
            <EuiTitle size={isFlowV2 ? 'm' : 's'}>
              <h1 css={css({ textAlign: 'center' })}>{title}</h1>
            </EuiTitle>
            <EuiSpacer size={isFlowV2 ? 'l' : 's'} />
          </Fragment>
        )}

        {subtitle && (
          <Fragment>
            <EuiText textAlign='center' color='subdued'>
              {subtitle}
            </EuiText>
            <EuiSpacer />
          </Fragment>
        )}

        {children}
      </EuiPanel>
      {infoMessage}
    </Fragment>
  )
}

export default LandingPageInnerContainer
