/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { union } from 'lodash'

import type { Price } from '@modules/billing-api/v1/types'
import type { Dictionary } from '@modules/ts-essentials'

export const getElasticsearchPrices = (prices: Price[]): Price[] =>
  prices.filter(({ product_family }) => product_family === 'elasticsearch')

export const getObservabilityPrices = (prices: Price[]): Price[] =>
  prices.filter(({ product_family }) => product_family === 'observability')

export const getSecurityPrices = (prices: Price[]): Price[] =>
  prices.filter(({ product_family }) => product_family === 'security')

export const getServerlessPrices = (prices: Price[]): Price[] =>
  prices.filter(({ product_family }) => product_family === 'serverless')

export const getRegionalPrices = (prices: Price[], provider: string, region: string): Price[] =>
  prices.filter(({ csp, csp_region }) => csp === provider && csp_region === region)

export const getProvidersAndRegions = (prices: Price[]): Dictionary<string[]> =>
  prices.reduce((acc, item: Price) => {
    if (item.csp && item.csp_region) {
      if (acc[item.csp]) {
        acc[item.csp] = union(acc[item.csp], [item.csp_region])
      } else {
        acc[item.csp] = [item.csp_region]
      }
    }

    return acc
  }, {})
