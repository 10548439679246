/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiPopover, EuiText } from '@elastic/eui'

import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import CreateDeploymentButton from './CreateDeploymentButton'

import type { FC } from 'react'

interface Props {
  hasExpiredTrial: boolean
  hasNoDeployments: boolean
  inTrial: boolean
  unSubscribed: boolean
}

const popoverContentStyle = css({ maxWidth: '350px' })

const CreateDeploymentButtonPopover: FC<Props> = ({
  hasExpiredTrial,
  hasNoDeployments,
  inTrial,
  unSubscribed,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const togglePopover = useCallback(() => setIsPopoverOpen((currValue) => !currValue), [])
  const closePopover = useCallback(() => setIsPopoverOpen(false), [])

  if (inTrial) {
    return (
      <EuiPopover
        button={
          <CreateDeploymentButton
            canCreateDeployment={!hasExpiredTrial && hasNoDeployments}
            onClickFallback={togglePopover}
          />
        }
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        panelPaddingSize='m'
        anchorPosition='upCenter'
      >
        <EuiText css={popoverContentStyle}>
          <FormattedMessage
            id='portal-deployments.create-deployment-limited-to-one'
            defaultMessage='Trials are limited to one deployment at a time. {subscribe} to create additional deployments.'
            values={{
              subscribe: (
                <CreditCardModalButton onCloseModal={closePopover}>
                  <FormattedMessage id='portal.subscribe' defaultMessage='Subscribe' />
                </CreditCardModalButton>
              ),
            }}
          />
        </EuiText>
      </EuiPopover>
    )
  }

  return <CreateDeploymentButton canCreateDeployment={!unSubscribed} isDisabled={unSubscribed} />
}

export default CreateDeploymentButtonPopover
