/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ProjectType } from '@modules/ui-types/projects'
import type { ProjectStat } from '@modules/autoops-api/types'

export type CHART_OPTIONS = {
  showLegend: boolean
  graphType: 'line' | 'area'
  xTickFormat: 'none' | 'time'
}

export const STATS_BY_PROJECT_TYPE: Record<ProjectType, ProjectStat[]> = {
  elasticsearch: [
    'PROJECT_QUERY_RATE_AND_LATENCY',
    'PROJECT_VCU_USAGE',
    'PROJECT_STORAGE_RETAINED',
  ],
  observability: ['PROJECT_INGEST_RATE', 'PROJECT_STORAGE_RETAINED'],
  security: ['PROJECT_INGEST_RATE', 'PROJECT_STORAGE_RETAINED'],
}

export const METRIC_SERIES_NAMES: Record<ProjectStat, string> = {
  PROJECT_QUERY_RATE_AND_LATENCY: 'Search Rate / Search Latency',
  PROJECT_VCU_USAGE: 'VCUs usage',
  PROJECT_STORAGE_RETAINED: 'Storage retained',
  PROJECT_INGEST_RATE: 'Ingest Rate',
}

export const METRIC_NAMES: Record<string, string> = {
  query_rate_per_sec: 'Search Rate',
  query_latency_millis: 'Search Latency',
}

export const DEFAULT_CHART_OPTIONS: CHART_OPTIONS = {
  showLegend: true,
  graphType: 'line',
  xTickFormat: 'time',
}

export const CHART_OPTIONS_BY_PROJECT_TYPE: Record<ProjectStat, CHART_OPTIONS> = {
  PROJECT_QUERY_RATE_AND_LATENCY: { showLegend: true, graphType: 'line', xTickFormat: 'time' },
  PROJECT_VCU_USAGE: { showLegend: true, graphType: 'line', xTickFormat: 'none' },
  PROJECT_STORAGE_RETAINED: { showLegend: false, graphType: 'area', xTickFormat: 'time' },
  PROJECT_INGEST_RATE: { showLegend: false, graphType: 'line', xTickFormat: 'time' },
}
