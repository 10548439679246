/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { jsx, css } from '@emotion/react'

import type { EuiComboBoxOptionOption, EuiTabbedContentTab } from '@elastic/eui'
import {
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
  EuiTabbedContent,
  EuiText,
} from '@elastic/eui'

import type {
  ProjectRoleAssignment,
  DeploymentRoleAssignment,
  RoleAssignments,
} from '@modules/cloud-api/v1/types'
import { CuiAlert } from '@modules/cui/Alert'
import {
  filterItemsByIdOrName,
  getAllRoleAssignments,
  normaliseDeploymentRoleAssignments,
  normaliseProjectRoleAssignments,
  replaceAllRoleAssignment,
  roleAssignmentsToTableItems,
  setDeploymentRoleAssignments,
  setProjectRoleAssignments,
  tableItemsToDeploymentRoleAssignment,
  tableItemsToProjectRoleAssignment,
} from '@modules/role-assignments-lib'
import {
  deploymentRoleIdsByLabel,
  elasticsearchRoleIdsByLabel,
  observabilityRoleIdsByLabel,
  securityRoleIdsByLabel,
} from '@modules/role-assignments-lib/types'
import type {
  ResourceType,
  RoleAssignmentsTableItem,
  ResourceRoleAssignment,
  NormalisedResourceRoleAssignment,
  ResourceRoleId,
  Resource,
  RoleOptionDefinition,
} from '@modules/role-assignments-lib/types'
import {
  elasticsearchRoleLabels,
  observabilityRoleLabels,
  securityRoleLabels,
  securityRoleDescriptions,
  elasticsearchRoleDescriptions,
  deploymentRoleLabels,
  deploymentRoleDescriptions,
  ResourceTypeDisplay,
} from '@modules/role-assignments-lib/messages'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import ProjectRoleAssignmentsTable from '../ProjectRoleAssignmentsTable'

import type { CSSObject } from '@emotion/css'
import type { FunctionComponent, ReactElement } from 'react'

export type QueryResult<T extends ResourceType> = {
  data: Array<Resource<T>> | undefined
  isEnabled: boolean
  isLoading: boolean
  error: unknown
}

export type ProjectRoleAssignmentsEditorProps = {
  organizationId: string
  roleAssignments: RoleAssignments
  deploymentQuery: QueryResult<'deployment'>
  elasticsearchQuery: QueryResult<'elasticsearch'>
  observabilityQuery: QueryResult<'observability'>
  securityQuery: QueryResult<'security'>
  onChangeRoleAssignments: (roleAssignments: RoleAssignments) => void
}

const ProjectRoleAssignmentsEditor: FunctionComponent<ProjectRoleAssignmentsEditorProps> = ({
  organizationId,
  roleAssignments,
  deploymentQuery,
  elasticsearchQuery,
  observabilityQuery,
  securityQuery,
  onChangeRoleAssignments,
}: ProjectRoleAssignmentsEditorProps) => {
  const tabs: EuiTabbedContentTab[] = [
    {
      id: 'deployment',
      prepend: <EuiIcon type='logoElasticStack' />,
      name: <FormattedMessage id='deployments.pretty.type' defaultMessage='Deployment' />,
      content: deploymentQuery.isEnabled ? (
        <ProjectTabContent<'deployment', DeploymentRoleAssignment>
          key='deployment'
          organizationId={organizationId}
          resourceType='deployment'
          selectedAllRoleAssignments={getAllRoleAssignments(
            normaliseDeploymentRoleAssignments(roleAssignments.deployment),
          )}
          items={roleAssignmentsToTableItems(
            'deployment',
            deploymentQuery.data,
            roleAssignments.deployment,
            normaliseDeploymentRoleAssignments,
          )}
          isLoading={deploymentQuery.isLoading}
          error={deploymentQuery.error}
          tableItemsToRoleAssignment={tableItemsToDeploymentRoleAssignment(organizationId)}
          onChangeRoleAssignments={(deploymentRoleAssignments) =>
            setDeploymentRoleAssignments(
              roleAssignments,
              deploymentRoleAssignments,
              onChangeRoleAssignments,
            )
          }
        />
      ) : null,
    },
    {
      id: 'elasticsearch',
      prepend: <EuiIcon type='logoElasticsearch' />,
      name: (
        <FormattedMessage id='projects.pretty.type.elasticsearch' defaultMessage='Elasticsearch' />
      ),
      content: elasticsearchQuery.isEnabled ? (
        <ProjectTabContent<'elasticsearch', ProjectRoleAssignment>
          key='elasticsearch'
          organizationId={organizationId}
          resourceType='elasticsearch'
          selectedAllRoleAssignments={getAllRoleAssignments(
            normaliseProjectRoleAssignments('elasticsearch')(
              roleAssignments.project?.elasticsearch,
            ),
          )}
          items={roleAssignmentsToTableItems(
            'elasticsearch',
            elasticsearchQuery.data,
            roleAssignments.project?.elasticsearch,
            normaliseProjectRoleAssignments('elasticsearch'),
          )}
          isLoading={elasticsearchQuery.isLoading}
          error={observabilityQuery.error}
          tableItemsToRoleAssignment={tableItemsToProjectRoleAssignment(organizationId)}
          onChangeRoleAssignments={(elasticsearchRoleAssignments) =>
            setProjectRoleAssignments<'elasticsearch', ProjectRoleAssignment>(
              roleAssignments,
              'elasticsearch',
              elasticsearchRoleAssignments,
              onChangeRoleAssignments,
            )
          }
        />
      ) : null,
    },
    {
      id: 'observability',
      prepend: <EuiIcon type='logoObservability' />,
      name: (
        <FormattedMessage id='projects.pretty.type.observability' defaultMessage='Observability' />
      ),
      content: observabilityQuery.isEnabled ? (
        <ProjectTabContent<'observability', ProjectRoleAssignment>
          key='observability'
          organizationId={organizationId}
          resourceType='observability'
          selectedAllRoleAssignments={getAllRoleAssignments(
            normaliseProjectRoleAssignments('observability')(
              roleAssignments.project?.observability,
            ),
          )}
          items={roleAssignmentsToTableItems(
            'observability',
            observabilityQuery.data,
            roleAssignments.project?.observability,
            normaliseProjectRoleAssignments('observability'),
          )}
          isLoading={observabilityQuery.isLoading}
          error={observabilityQuery.error}
          tableItemsToRoleAssignment={tableItemsToProjectRoleAssignment(organizationId)}
          onChangeRoleAssignments={(observabilityRoleAssignments) =>
            setProjectRoleAssignments<'observability', ProjectRoleAssignment>(
              roleAssignments,
              'observability',
              observabilityRoleAssignments,
              onChangeRoleAssignments,
            )
          }
        />
      ) : null,
    },
    {
      id: 'security',
      prepend: <EuiIcon type='logoSecurity' />,
      name: <FormattedMessage id='projects.pretty.type.security' defaultMessage='Security' />,
      content: securityQuery.isEnabled ? (
        <ProjectTabContent<'security', ProjectRoleAssignment>
          key='security'
          organizationId={organizationId}
          resourceType='security'
          selectedAllRoleAssignments={getAllRoleAssignments(
            normaliseProjectRoleAssignments('security')(roleAssignments.project?.security),
          )}
          items={roleAssignmentsToTableItems(
            'security',
            securityQuery.data,
            roleAssignments.project?.security,
            normaliseProjectRoleAssignments('security'),
          )}
          isLoading={securityQuery.isLoading}
          error={securityQuery.error}
          tableItemsToRoleAssignment={tableItemsToProjectRoleAssignment(organizationId)}
          onChangeRoleAssignments={(securityRoleAssignments) =>
            setProjectRoleAssignments<'security', ProjectRoleAssignment>(
              roleAssignments,
              'security',
              securityRoleAssignments,
              onChangeRoleAssignments,
            )
          }
        />
      ) : null,
    },
  ]

  const filteredTabs = tabs.filter((tab) => tab.content !== null)

  if (filteredTabs.length === 0) {
    return null
  }

  if (filteredTabs.length === 1) {
    return filteredTabs.at(0)?.content as ReactElement
  }

  return (
    <Fragment>
      <TabsDividers
        showHostedDivider={deploymentQuery.isEnabled}
        showServerlessDivider={
          elasticsearchQuery.isEnabled || observabilityQuery.isEnabled || securityQuery.isEnabled
        }
      />

      <EuiTabbedContent
        size='m'
        expand={true}
        tabs={filteredTabs}
        initialSelectedTab={filteredTabs[0]}
      />
    </Fragment>
  )
}

type Props<T extends ResourceType, R extends ResourceRoleAssignment<T>> = {
  organizationId: string
  resourceType: T
  selectedAllRoleAssignments: Array<NormalisedResourceRoleAssignment<T>> | undefined
  items: Array<RoleAssignmentsTableItem<T>>
  isLoading: boolean
  error: unknown
  tableItemsToRoleAssignment: (items: Array<RoleAssignmentsTableItem<T>>) => R[]
  onChangeRoleAssignments: (roleAssignments: Array<ResourceRoleAssignment<T>>) => void
}

const ProjectTabContent = <T extends ResourceType, R extends ResourceRoleAssignment<T>>({
  organizationId,
  resourceType,
  selectedAllRoleAssignments,
  items,
  isLoading,
  error,
  tableItemsToRoleAssignment,
  onChangeRoleAssignments,
}: Props<T, R>) => {
  const [isFlagReady, flags] = useFlagsWhenLoaded()
  const customRolesEnabled = isFlagReady && flags.serverlessCustomRoles
  const [filterQuery, setFilterQuery] = useState('')
  const filteredItems = filterItemsByIdOrName(items, filterQuery)
  const { getOptions, noRolesPlaceholder } = useGetOptions()

  const options = getOptions(resourceType)

  const singleSelection = resourceType === 'deployment' || !customRolesEnabled
  const singleSelectionAsCount = singleSelection ? 1 : 2

  return (
    <Fragment>
      <EuiSpacer />

      <EuiFlexGroup direction='column' gutterSize='l'>
        {error && (
          <EuiFlexItem>
            <CuiAlert type='danger'>{error}</CuiAlert>
          </EuiFlexItem>
        )}

        <EuiFlexItem>
          <FormattedMessage
            id={'roles-assignments.project.helper'}
            defaultMessage={
              'Set {count, plural, one {a role} other {one or more roles}} for individual or all {resourceType}.'
            }
            values={{
              resourceType: <ResourceTypeDisplay resourceType={resourceType} plural={true} />,
              count: singleSelectionAsCount,
            }}
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <strong>
            <FormattedMessage
              id={'roles-assignments.project.all.description'}
              defaultMessage={'{count, plural, one {Role} other {Roles}} for all {resourceType}'}
              values={{
                resourceType: <ResourceTypeDisplay resourceType={resourceType} plural={true} />,
                count: singleSelectionAsCount,
              }}
            />
          </strong>
        </EuiFlexItem>

        <EuiFlexGroup>
          <EuiFlexItem grow={7}>
            <EuiComboBox<ResourceRoleId<T>>
              data-test-subj='allRoleSelector'
              data-test-id={`allRoleSelector-${resourceType}`}
              placeholder={noRolesPlaceholder(singleSelection)}
              singleSelection={singleSelection}
              options={options}
              selectedOptions={getSelectedOptions(
                selectedAllRoleAssignments,
                options,
                customRolesEnabled,
              )}
              onChange={(selectedOptions) =>
                replaceAllRoleAssignment(organizationId, selectedOptions, onChangeRoleAssignments)
              }
            />
          </EuiFlexItem>

          <EuiFlexItem grow={3} />
        </EuiFlexGroup>

        <EuiFlexItem>
          <ProjectRoleAssignmentsTable<T, R>
            resourceType={resourceType}
            items={items}
            filteredItems={filteredItems}
            isLoading={isLoading}
            customRolesEnabled={customRolesEnabled}
            onSearch={setFilterQuery}
            tableItemsToRoleAssignment={tableItemsToRoleAssignment}
            onChangeRoleAssignments={onChangeRoleAssignments}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  )
}

const getSelectedOptions = <T extends ResourceType>(
  assignments: Array<NormalisedResourceRoleAssignment<T>> | undefined,
  options: Array<EuiComboBoxOptionOption<ResourceRoleId<T>>>,
  customRolesEnabled: boolean,
): Array<EuiComboBoxOptionOption<ResourceRoleId<T>>> => {
  const selectedOptions: Array<EuiComboBoxOptionOption<ResourceRoleId<T>>> = []

  if (assignments?.length) {
    const assignmentsToProcess = customRolesEnabled ? assignments : assignments.slice(0, 1)

    assignmentsToProcess.forEach((assignment) => {
      const found = options.find((option) => option.value === assignment.role_id)

      if (found) {
        selectedOptions.push(found)
      }
    })
  }

  return selectedOptions
}

const useGetOptions = () => {
  const { formatMessage } = useIntl()

  const getOptions = <T extends ResourceType>(
    resourceType: T,
  ): Array<EuiComboBoxOptionOption<ResourceRoleId<T>>> => {
    let predefinedRoles: RoleOptionDefinition[]

    switch (resourceType) {
      case 'deployment':
        predefinedRoles = deploymentOptionDefintions
        break
      case 'elasticsearch':
        predefinedRoles = elasticsearchOptionDefinitions
        break
      case 'observability':
        predefinedRoles = observabilityOptionDefinitions
        break
      case 'security':
        predefinedRoles = securityOptionDefinitions
        break
      default:
        predefinedRoles = []
        break
    }

    return predefinedRoles.map(
      (roleDef) =>
        ({
          value: roleDef.roleId,
          label: formatMessage({ ...roleDef.labelDescriptor }),
          toolTipContent: formatMessage({ ...roleDef.descriptionDescriptor }),
          title: '',
        } as EuiComboBoxOptionOption<ResourceRoleId<T>>),
    )
  }

  const noRolesPlaceholder = (singleSelection: boolean) =>
    formatMessage(
      {
        id: 'roles-assignments.all.none.placeholder',
        defaultMessage: 'No {count, plural, one {role} other {roles}} assigned',
      },
      {
        count: singleSelection ? 1 : 2,
      },
    )

  return { getOptions, noRolesPlaceholder }
}

const deploymentOptionDefintions: RoleOptionDefinition[] = [
  {
    roleId: deploymentRoleIdsByLabel.admin,
    labelDescriptor: deploymentRoleLabels.admin,
    descriptionDescriptor: deploymentRoleDescriptions.admin,
  },
  {
    roleId: deploymentRoleIdsByLabel.editor,
    labelDescriptor: deploymentRoleLabels.editor,
    descriptionDescriptor: deploymentRoleDescriptions.editor,
  },
  {
    roleId: deploymentRoleIdsByLabel.viewer,
    labelDescriptor: deploymentRoleLabels.viewer,
    descriptionDescriptor: deploymentRoleDescriptions.viewer,
  },
]

const elasticsearchOptionDefinitions: RoleOptionDefinition[] = [
  {
    roleId: elasticsearchRoleIdsByLabel.admin,
    labelDescriptor: elasticsearchRoleLabels.admin,
    descriptionDescriptor: {
      id: 'roles-assignments.project.all.admin.description',
      defaultMessage:
        'Create and configure elasticsearch projects, including security settings. Superuser in all elasticsearch projects.',
    },
  },
  {
    roleId: elasticsearchRoleIdsByLabel.developer,
    labelDescriptor: elasticsearchRoleLabels.developer,
    descriptionDescriptor: elasticsearchRoleDescriptions.developer,
  },
  {
    roleId: elasticsearchRoleIdsByLabel.viewer,
    labelDescriptor: elasticsearchRoleLabels.viewer,
    descriptionDescriptor: {
      id: 'roles-assignments.project.all.viewer.description',
      defaultMessage: 'Read-only access to all elasticsearch projects, data, and features.',
    },
  },
]

const observabilityOptionDefinitions: RoleOptionDefinition[] = [
  {
    roleId: observabilityRoleIdsByLabel.admin,
    labelDescriptor: observabilityRoleLabels.admin,
    descriptionDescriptor: {
      id: 'roles-assignments.project.all.admin.description',
      defaultMessage:
        'Create and configure observability projects, including security settings. Superuser in all observability projects.',
    },
  },
  {
    roleId: observabilityRoleIdsByLabel.editor,
    labelDescriptor: observabilityRoleLabels.editor,
    descriptionDescriptor: {
      id: 'roles-assignments.project.all.editor.description',
      defaultMessage:
        'Configure all observability projects. Read-only access to data indices. Full access to all instance features.',
    },
  },
  {
    roleId: observabilityRoleIdsByLabel.viewer,
    labelDescriptor: observabilityRoleLabels.viewer,
    descriptionDescriptor: {
      id: 'roles-assignments.project.all.viewer.description',
      defaultMessage: 'Read-only access to all observability projects, data, and features.',
    },
  },
]

const securityOptionDefinitions: RoleOptionDefinition[] = [
  {
    roleId: securityRoleIdsByLabel.admin,
    labelDescriptor: securityRoleLabels.admin,
    descriptionDescriptor: {
      id: 'roles-assignments.project.all.admin.description',
      defaultMessage:
        'Create and configure security projects, including security settings. Superuser in all security projects.',
    },
  },
  {
    roleId: securityRoleIdsByLabel.editor,
    labelDescriptor: securityRoleLabels.editor,
    descriptionDescriptor: {
      id: 'roles-assignments.project.all.editor.description',
      defaultMessage:
        'Configure all security projects. Read-only access to data indices. Full access to all instance features.',
    },
  },
  {
    roleId: securityRoleIdsByLabel.viewer,
    labelDescriptor: securityRoleLabels.viewer,
    descriptionDescriptor: {
      id: 'roles-assignments.project.all.viewer.description',
      defaultMessage: 'Read-only access to all security projects, data, and features.',
    },
  },
  {
    roleId: securityRoleIdsByLabel.tier1Analyst,
    labelDescriptor: securityRoleLabels.tier1Analyst,
    descriptionDescriptor: securityRoleDescriptions.tier1Analyst,
  },
  {
    roleId: securityRoleIdsByLabel.tier2Analyst,
    labelDescriptor: securityRoleLabels.tier2Analyst,
    descriptionDescriptor: securityRoleDescriptions.tier2Analyst,
  },
  {
    roleId: securityRoleIdsByLabel.tier3Analyst,
    labelDescriptor: securityRoleLabels.tier3Analyst,
    descriptionDescriptor: securityRoleDescriptions.tier3Analyst,
  },
  {
    roleId: securityRoleIdsByLabel.threatIntelligenceAnalyst,
    labelDescriptor: securityRoleLabels.threatIntelligenceAnalyst,
    descriptionDescriptor: securityRoleDescriptions.threatIntelligenceAnalyst,
  },
  {
    roleId: securityRoleIdsByLabel.ruleAuthor,
    labelDescriptor: securityRoleLabels.ruleAuthor,
    descriptionDescriptor: securityRoleDescriptions.ruleAuthor,
  },
  {
    roleId: securityRoleIdsByLabel.socManager,
    labelDescriptor: securityRoleLabels.socManager,
    descriptionDescriptor: securityRoleDescriptions.socManager,
  },
  {
    roleId: securityRoleIdsByLabel.endpointOperationsAnalyst,
    labelDescriptor: securityRoleLabels.endpointOperationsAnalyst,
    descriptionDescriptor: securityRoleDescriptions.endpointOperationsAnalyst,
  },
  {
    roleId: securityRoleIdsByLabel.platformEngineer,
    labelDescriptor: securityRoleLabels.platformEngineer,
    descriptionDescriptor: securityRoleDescriptions.platformEngineer,
  },
  {
    roleId: securityRoleIdsByLabel.detectionsAdmin,
    labelDescriptor: securityRoleLabels.detectionsAdmin,
    descriptionDescriptor: securityRoleDescriptions.detectionsAdmin,
  },
  {
    roleId: securityRoleIdsByLabel.endpointPolicyManager,
    labelDescriptor: securityRoleLabels.endpointPolicyManager,
    descriptionDescriptor: securityRoleDescriptions.endpointPolicyManager,
  },
]

const line: CSSObject = {
  inlineSize: '100%',
  blockSize: '6px',
  borderRadius: '6px',
}

const blueLine = css({
  ...line,
  backgroundColor: '#A2C5E4',
})

const greenLine = css({
  ...line,
  backgroundColor: '#AAE3E0',
})

const TabsDividers: FunctionComponent<{
  showHostedDivider: boolean
  showServerlessDivider: boolean
}> = ({ showHostedDivider, showServerlessDivider }) => (
  <EuiFlexGroup responsive={false} gutterSize='xs'>
    {showHostedDivider && (
      <EuiFlexItem style={{ width: '136px' }} grow={1}>
        <EuiFlexGroup direction='column' gutterSize='s' alignItems='center'>
          <EuiText size='xs'>
            <FormattedMessage id='roles-assignments.hosted' defaultMessage='Hosted' />
          </EuiText>

          <div css={blueLine} />
        </EuiFlexGroup>
      </EuiFlexItem>
    )}

    {showServerlessDivider && (
      <EuiFlexItem grow={3}>
        <EuiFlexGroup direction='column' gutterSize='s' alignItems='center'>
          <EuiText size='xs'>
            <FormattedMessage id='roles-assignments.serverless' defaultMessage='Serverless' />
          </EuiText>

          <div css={greenLine} />
        </EuiFlexGroup>
      </EuiFlexItem>
    )}
  </EuiFlexGroup>
)

export default ProjectRoleAssignmentsEditor
