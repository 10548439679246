/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import type { z } from 'zod'

/**
 * A local storage persistence hook that validates against a supplied zod schema.
 */
export const useTypedLocalStorage = <TSchema extends z.ZodTypeAny, TData extends z.infer<TSchema>>(
  storageKey: string,
  schema: TSchema,
  initialValue: TData,
) => {
  // read existing value from local storage
  let jsonValue: any

  try {
    const rawValue = localStorage.getItem(storageKey)
    jsonValue = rawValue == null ? null : JSON.parse(rawValue)
  } catch {
    jsonValue = null
  }

  // validate against the schema, initializing if schema validation fails for any reason
  const parsedValue = schema.safeParse(jsonValue ?? {})
  const typedValue = (parsedValue.success ? parsedValue.data : initialValue) as TData

  // store in hook state and persist to local storage as a side-effect
  const [value, setValue] = React.useState(typedValue)

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value))
  }, [value, storageKey])

  return <[TData, React.Dispatch<React.SetStateAction<TData>>]>[value, setValue]
}
