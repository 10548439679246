/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  AreaSeries,
  Axis,
  Chart,
  DARK_THEME,
  LIGHT_THEME,
  LineSeries,
  niceTimeFormatByDay,
  Position,
  ScaleType,
  Settings,
  timeFormatter,
} from '@elastic/charts'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiSpacer,
  EuiSplitPanel,
  EuiTitle,
  withEuiTheme,
} from '@elastic/eui'
import type { WithEuiThemeProps } from '@elastic/eui'

import { useGetProjectMetricsQuery } from '@modules/autoops-lib/hooks'
import type { ProjectType } from '@modules/ui-types/projects'

type AutoOpsMetricsChartProps = WithEuiThemeProps & {
  projectId: string
  projectType: ProjectType
  organizationId: string
}

const baseTheme = {
  LIGHT: LIGHT_THEME,
  DARK: DARK_THEME,
}

const ChartSeriesByType = {
  line: LineSeries,
  area: AreaSeries,
}

const BottomFormatterByType = {
  time: timeFormatter(niceTimeFormatByDay(1)),
  none: undefined,
}

const AutoOpsMetricsChart = ({
  projectId,
  projectType,
  organizationId,
  theme,
}: AutoOpsMetricsChartProps) => {
  const query = useGetProjectMetricsQuery({ projectId, projectType, organizationId })

  if (query.isError) {
    return null
  }

  if (query.isLoading) {
    return (
      <Fragment>
        <EuiSkeletonText />
        <EuiSpacer size='xxl' />
      </Fragment>
    )
  }

  if (!query.isSuccess) {
    return null
  }

  return (
    <Fragment>
      <EuiTitle size='s'>
        <h2>
          <FormattedMessage
            id='project.heading.usage_and_performance'
            defaultMessage='Usage and performance'
          />
        </h2>
      </EuiTitle>

      <EuiSpacer size='xxl' />

      {query.data.map((metric) => (
        <Fragment key={metric.name}>
          <EuiFlexGroup gutterSize='l'>
            <EuiFlexItem>
              <EuiSplitPanel.Outer grow={true} hasBorder={true}>
                <EuiSplitPanel.Inner>
                  <Chart title={metric.name} size={{ height: '200px' }}>
                    <Settings
                      baseTheme={baseTheme[theme.colorMode]}
                      showLegend={metric.options.showLegend}
                      legendPosition={Position.Right}
                    />

                    <Axis
                      id='bottom'
                      position={Position.Bottom}
                      showOverlappingTicks={true}
                      tickFormat={BottomFormatterByType[metric.options.xTickFormat]}
                    />

                    <Axis
                      id='left'
                      position={Position.Left}
                      tickFormat={(d) => `${Number(d).toFixed(2)}`}
                    />

                    {metric.series.map((seriesItem) => {
                      const ChartSeries = ChartSeriesByType[metric.options.graphType]

                      return (
                        <ChartSeries
                          key={seriesItem.name}
                          id={seriesItem.name}
                          xScaleType={ScaleType.Time}
                          yScaleType={ScaleType.Linear}
                          xAccessor={0}
                          yAccessors={[1]}
                          data={seriesItem.data}
                        />
                      )
                    })}
                  </Chart>
                </EuiSplitPanel.Inner>
              </EuiSplitPanel.Outer>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size='xxl' />
        </Fragment>
      ))}
    </Fragment>
  )
}

export default withEuiTheme(AutoOpsMetricsChart)
