/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { add, mergeWith, uniq } from 'lodash'

import type {
  DeploymentDimension,
  DeploymentInstance,
  ProjectInstance,
  ChartItemType,
} from '@modules/billing-api/customTypes'
import type { Instance, ProductLineItem } from '@modules/billing-api/v1/types'
import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import { ProductType } from '../types'

import type { Solution } from '../types'
import type {
  DeploymentInstanceWithAggregatedCosts,
  TotalDeploymentCostsPerDimension,
  TotalProjectCollapsedCosts,
} from './types'

export const getDeploymentInstances = (instances: Instance[]): DeploymentInstance[] =>
  instances.filter(({ type }) => type === 'deployment') as DeploymentInstance[]

export const getProjectInstances = (instances: Instance[]): ProjectInstance[] =>
  instances.filter(({ type }) =>
    ['security', 'elasticsearch', 'observability'].includes(type),
  ) as ProjectInstance[]

export const getCostForDimensions = (
  dimensionTypes: DeploymentDimension[],
  product_line_items: ProductLineItem[],
): number =>
  product_line_items.reduce((currentCost, { total_ecu, type }) => {
    if (dimensionTypes.includes(type as DeploymentDimension)) {
      return currentCost + total_ecu
    }

    return currentCost
  }, 0)

export const getDeploymentInstancesWithAggregatedCosts = (
  instances: DeploymentInstance[] = [],
): DeploymentInstanceWithAggregatedCosts[] =>
  instances.map(({ id, name, product_line_items, total_ecu }) => ({
    id,
    name,
    capacity: getCostForDimensions(['capacity'], product_line_items),
    dataTransfer: getCostForDimensions(
      ['data_in', 'data_internode', 'data_out'],
      product_line_items,
    ),
    storage: getCostForDimensions(['storage_bytes', 'storage_api'], product_line_items),
    synthetics: getCostForDimensions(
      ['synthetics_lightweight', 'synthetics_browser'],
      product_line_items,
    ),
    total_ecu,
  })) || []

export const getTotalDeploymentCostsPerDimension = (
  instancesWithAggregatedCosts: DeploymentInstanceWithAggregatedCosts[],
): TotalDeploymentCostsPerDimension =>
  instancesWithAggregatedCosts.reduce((aggs, aggregation) => mergeWith(aggs, aggregation, add), {
    capacity: 0,
    dataTransfer: 0,
    storage: 0,
    synthetics: 0,
    total_ecu: 0,
  })

export const getTotalProjectCosts = (instances: ProjectInstance[]): number =>
  instances.reduce((total, { total_ecu }) => total + total_ecu, 0)

export const getTotalProjectCollapsedCosts = (
  instances: ProjectInstance[],
): TotalProjectCollapsedCosts =>
  instances.reduce(
    (totals, { total_ecu, type }) => ({
      total_ecu: total_ecu + totals.total_ecu,
      type: uniq([...totals.type, type]),
    }),
    {
      total_ecu: 0,
      type: [],
    },
  )

export const filterProjectsInstancesBySolutions = (
  instances: ProjectInstance[],
  solutions: Solution[],
): ProjectInstance[] => instances.filter(({ type }) => solutions.includes(type as Solution))

export const getAvailableChartValueTypes = (productTypes: ProductType[], solutions: Solution[]) => {
  const availableValues: ChartItemType[] = []

  if (productTypes.includes(ProductType.Deployments)) {
    availableValues.push('deployment')
  }

  if (productTypes.includes(ProductType.Projects)) {
    availableValues.push(...solutions)
  }

  return availableValues
}

export const getChartDataProductTypes = ({
  projects,
  deployments,
  selectedProductTypes,
}): ProductType[] => {
  const types: ProductType[] = []

  if (selectedProductTypes.includes('deployments') && deployments.length > 0) {
    types.push(ProductType.Deployments)
  }

  if (selectedProductTypes.includes('projects') && projects.length > 0) {
    types.push(ProductType.Projects)
  }

  return types
}

export const getRowColor = (id: string, name: string): string => {
  if (name) {
    return colorForInstances.get(`${id}_${name}`)
  }

  return colorForInstances.get(`${id}_${id}`)
}
