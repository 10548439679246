/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useEffect, useReducer } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'

import { useGetInstanceChartDataV2, useGetInstanceCostItemsV2 } from '@modules/billing-lib/hooks'
import { CuiAlert } from '@modules/cui/Alert'
import { processQueryParams } from '@modules/billing-api/utils'
import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import MainFilters from '@/components/User/BillingUsage/components/Filters/MainFilters'
import messages from '@/components/User/BillingOverviewV2/messages'
import ProductItemList from '@/components/User/BillingUsage/BillingUsageByInstance/ProductItemListTable'
import {
  buildServerlessDimensionItems,
  mapToDeploymentCostsItemsV2,
} from '@/components/User/BillingUsage/BillingUsageByInstance/utils'
import { ViewByToBucketingStrategy } from '@/components/User/BillingUsage/constants'
import InstanceUsageTable from '@/components/User/BillingUsage/components/InstanceUsageTable'
import ErrorCallout from '@/components/User/BillingUsage/components/ErrorCallout'
import { ProductType } from '@/components/User/BillingUsage/types'
import DocLink from '@/components/DocLink'

import { Action, filtersReducer } from '../components/Filters/filtersReducer'
import {
  getValidationError,
  parseFiltersQuery,
  stringifyFilters,
} from '../components/Filters/utils'
import CostsChartByInstance from '../components/CostsChartByInstance'

import type { AllProps } from '@/components/User/BillingUsage/BillingUsageByInstance/types'
import type { FunctionComponent } from 'react'

const BillingUsageByInstance: FunctionComponent<AllProps> = ({
  organizationId,
  instanceId,
  instanceName,
}) => {
  const history = useHistory()
  const [state, dispatch] = useReducer(filtersReducer, {
    ...parseFiltersQuery(history.location.search.slice(1)),
  })
  const areDatesValid = !getValidationError({
    startDate: state.startDate,
    endDate: state.endDate,
    viewBy: state.viewBy,
  })

  // Each time the filters change we want to update queryParams
  useEffect(() => {
    colorForInstances.reset()
    const queryString = stringifyFilters(state)
    history.replace(`${history.location.pathname}?${queryString}`)
  }, [history, state])

  const onBackToUsageClick = () =>
    dispatch({ type: Action.SET_INSTANCE_ID, payload: { instanceId: undefined } })

  const instanceCostItems = useGetInstanceCostItemsV2(
    {
      pathParameters: {
        organization_id: organizationId,
        instance_id: instanceId,
      },
      queryParameters: processQueryParams({
        from: state.startDate.startOf('day').utc().format(),
        to: state.endDate.endOf('day').utc().format(),
      }),
    },
    {
      enabled: areDatesValid,
    },
  )

  const instance_type = instanceCostItems.data?.products[0]?.type

  const renderListTable = () => {
    if (instanceCostItems.isError) {
      return (
        <CuiAlert type='error' data-test-id='error-message'>
          <FormattedMessage {...messages.genericErrorMessage} />
        </CuiAlert>
      )
    }

    if (instance_type === 'deployment') {
      return (
        <EuiPanel
          hasShadow={false}
          paddingSize='l'
          hasBorder={true}
          className='billing-usage-responsive-panel'
          data-test-id='deployment-usage-table'
        >
          <InstanceUsageTable itemsCosts={mapToDeploymentCostsItemsV2(instanceCostItems.data)} />
        </EuiPanel>
      )
    }

    return (
      <Fragment>
        <EuiFlexGroup data-test-id='billing-usage.product-list-table'>
          <ProductItemList
            products={buildServerlessDimensionItems(instanceCostItems.data)}
            isLoading={instanceCostItems.isLoading}
            total_cost={instanceCostItems.data ? instanceCostItems.data.total_ecu : 0}
          />
        </EuiFlexGroup>
      </Fragment>
    )
  }

  const instanceChartQuery = useGetInstanceChartDataV2(
    {
      pathParameters: {
        organization_id: organizationId,
        instance_id: instanceId,
      },
      queryParameters: processQueryParams({
        from: state.startDate.startOf('day').utc().format(),
        to: state.endDate.endOf('day').utc().format(),
        bucketing_strategy: ViewByToBucketingStrategy[state.viewBy],
      }),
    },
    {
      enabled: areDatesValid,
    },
  )

  const renderChart = () => {
    if (instanceChartQuery.isError) {
      return <ErrorCallout retry={instanceChartQuery.refetch} data-test-id='costs-chart-error' />
    }

    const type = instance_type === 'deployment' ? ProductType.Deployments : ProductType.Projects
    return (
      <EuiPanel
        hasShadow={false}
        paddingSize='m'
        hasBorder={true}
        className='costs-chart-panel'
        data-test-id='costs-chart-panel'
      >
        <CostsChartByInstance
          data={instanceChartQuery.data?.data || []}
          chartType={state.chartType}
          viewBy={state.viewBy}
          isLoading={instanceChartQuery.isLoading}
          productType={type}
        />
      </EuiPanel>
    )
  }

  return (
    <Fragment>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiText size='s'>
            <FormattedMessage id='billing-usage.usage-report' defaultMessage='Usage report for' />
          </EuiText>
          <EuiText size='m' data-test-id='billing-usage.page-title'>
            <h3>{instanceName || instanceId}</h3>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiLink onClick={onBackToUsageClick} data-test-id='back-to-usage-link'>
            <EuiIcon type='returnKey' />{' '}
            <FormattedMessage
              id='billing-usage.back-to-usage-link'
              defaultMessage='Back to usage'
            />
          </EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='xxl' />

      <MainFilters
        isLoading={instanceCostItems.isLoading}
        dispatch={dispatch}
        state={state}
        organizationId={organizationId}
        shouldShowServerlessFilters={false}
      />

      <EuiSpacer size='m' />

      {renderChart()}

      <EuiSpacer size='l' />

      <EuiText size='s'>
        <FormattedMessage
          id='billing-deployment-usage-list.description'
          defaultMessage='All cost units are measured in {ecu}.'
          values={{
            ecu: (
              <DocLink link='billingECU' favorSaasContext={true}>
                <FormattedMessage
                  id='ecu-tooltip.page-ecu-unit'
                  defaultMessage='Elastic Consumption Units (ECU)'
                />
              </DocLink>
            ),
          }}
        />
      </EuiText>

      <EuiSpacer size='xs' />
      {renderListTable()}
    </Fragment>
  )
}

export default BillingUsageByInstance
