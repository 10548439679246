/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

import type {
  DeploymentRoleLabel,
  ElasticsearchRoleLabel,
  ObservabilityRoleLabel,
  OrganizationRoleLabel,
  SecurityRoleLabel,
  ResourceType,
  CustomRoleBadgeLabelType,
} from './types'
import type { MessageDescriptor } from 'react-intl'

export const organizationRoleLabels = defineMessages<OrganizationRoleLabel>({
  'organization-admin': {
    id: 'roles-assignments.organization-owner',
    defaultMessage: 'Organization owner',
  },
  'billing-admin': {
    id: 'roles-assignments.billing-admin',
    defaultMessage: 'Billing admin',
  },
})

export const organizationRoleDescriptions = defineMessages<OrganizationRoleLabel>({
  'organization-admin': {
    id: 'roles-assignments.organization-owner-description',
    defaultMessage:
      'Highest role within the organization. Can manage members, access all features and all deployments.',
  },
  'billing-admin': {
    id: 'roles-assignments.billing-admin-description',
    defaultMessage: 'Access to all invoices and payment methods. Can make subscription changes.',
  },
})

export const deploymentRoleLabels = defineMessages<DeploymentRoleLabel>({
  admin: {
    id: 'roles-assignments.admin',
    defaultMessage: 'Admin',
  },
  editor: {
    id: 'roles-assignments.editor',
    defaultMessage: 'Editor',
  },
  viewer: {
    id: 'roles-assignments.viewer',
    defaultMessage: 'Viewer',
  },
})

export const elasticsearchRoleLabels = defineMessages<ElasticsearchRoleLabel>({
  admin: {
    id: 'roles-assignments.admin',
    defaultMessage: 'Admin',
  },
  developer: {
    id: 'roles-assignments.developer',
    defaultMessage: 'Developer',
  },
  viewer: {
    id: 'roles-assignments.viewer',
    defaultMessage: 'Viewer',
  },
})

export const observabilityRoleLabels = defineMessages<ObservabilityRoleLabel>({
  admin: {
    id: 'roles-assignments.admin',
    defaultMessage: 'Admin',
  },
  editor: {
    id: 'roles-assignments.editor',
    defaultMessage: 'Editor',
  },
  viewer: {
    id: 'roles-assignments.viewer',
    defaultMessage: 'Viewer',
  },
})

export const securityRoleLabels = defineMessages<SecurityRoleLabel>({
  admin: {
    id: 'roles-assignments.admin',
    defaultMessage: 'Admin',
  },
  editor: {
    id: 'roles-assignments.editor',
    defaultMessage: 'Editor',
  },
  viewer: {
    id: 'roles-assignments.viewer',
    defaultMessage: 'Viewer',
  },
  tier1Analyst: {
    id: 'roles-assignments.tier-1-analyst',
    defaultMessage: 'Tier 1 analyst',
  },
  tier2Analyst: {
    id: 'roles-assignments.tier-2-analyst',
    defaultMessage: 'Tier 2 analyst',
  },
  tier3Analyst: {
    id: 'roles-assignments.tier-3-analyst',
    defaultMessage: 'Tier 3 analyst',
  },
  threatIntelligenceAnalyst: {
    id: 'role-badges.threat-intelligence-analyst',
    defaultMessage: 'Threat intelligence analyst',
  },
  ruleAuthor: {
    id: 'role-badges.rule-author',
    defaultMessage: 'Rule author',
  },
  socManager: {
    id: 'role-badges.soc-manager',
    defaultMessage: 'SOC manager',
  },
  endpointOperationsAnalyst: {
    id: 'role-badges.endpoint-operations-analyst',
    defaultMessage: 'Endpoint operations analyst',
  },
  platformEngineer: {
    id: 'role-badges.platform-engineer',
    defaultMessage: 'Platform engineer',
  },
  detectionsAdmin: {
    id: 'role-badges.detections-admin',
    defaultMessage: 'Detections admin',
  },
  endpointPolicyManager: {
    id: 'role-badges.endpoint-policy-manager',
    defaultMessage: 'Endpoint policy manager',
  },
})

export const customRoleBadgeLabels = defineMessages<CustomRoleBadgeLabelType>({
  customRole: {
    id: 'role-badges.custom',
    defaultMessage: 'Custom role',
  },
})

export const deploymentRoleDescriptions = defineMessages<DeploymentRoleLabel>({
  admin: {
    id: 'roles-assignments.deployment.admin.description',
    defaultMessage:
      'Can create, edit, view, delete data and manage the configuration of a hosted deployment.',
  },
  editor: {
    id: 'roles-assignments.deployment.editor.description',
    defaultMessage: 'Can create, edit, view and delete data.',
  },
  viewer: {
    id: 'roles-assignments.deployment.viewer.description',
    defaultMessage: 'Can view and operate on existing data.',
  },
})

export const elasticsearchRoleDescriptions = defineMessages<ElasticsearchRoleLabel>({
  admin: {
    id: 'roles-assignments.project.elasticsearch.admin.description',
    defaultMessage:
      'Configure the project, including security settings. Superuser in this project.',
  },
  developer: {
    id: 'roles-assignments.project.elasticsearch.developer.description',
    defaultMessage:
      'Manage project API keys, indices, data streams, connectors and visualizations.',
  },
  viewer: {
    id: 'roles-assignments.project.elasticsearch.viewer.description',
    defaultMessage: 'Read-only access to this project, its data, and its features.',
  },
})

export const observabilityRoleDescriptions = defineMessages<ObservabilityRoleLabel>({
  admin: {
    id: 'roles-assignments.project.observability.admin.description',
    defaultMessage:
      'Configure the project, including security settings. Superuser in this project.',
  },
  editor: {
    id: 'roles-assignments.project.observability.editor.description',
    defaultMessage:
      'Manage all settings of this project. Read-only access to data indices in this project. Full access to all project features.',
  },
  viewer: {
    id: 'roles-assignments.project.observability.viewer.description',
    defaultMessage: 'Read-only access to this project, its data, and its features.',
  },
})

export const securityRoleDescriptions = defineMessages<SecurityRoleLabel>({
  admin: {
    id: 'roles-assignments.project.security.admin.description',
    defaultMessage:
      'Configure the project, including security settings. Superuser in this project.',
  },
  editor: {
    id: 'roles-assignments.project.security.editor.description',
    defaultMessage:
      'Manage all settings of this project. Read-only access to data indices in this project. Full access to all project features.',
  },
  viewer: {
    id: 'roles-assignments.project.security.viewer.description',
    defaultMessage: 'Read-only access to this project, its data, and its features.',
  },
  tier1Analyst: {
    id: 'roles-assignments.project.security.tier-1-analyst.description',
    defaultMessage:
      'Ideal for initial alert triage. General read access. Can create saved objects.',
  },
  tier2Analyst: {
    id: 'roles-assignments.project.security.tier-2-analyst.description',
    defaultMessage:
      'Ideal for alert triage and beginning the investigation process. Can create Cases.',
  },
  tier3Analyst: {
    id: 'roles-assignments.project.security.tier-3-analyst.description',
    defaultMessage:
      'Access to more capabilities for deeper investigations. Can fully utilize rules, lists, cases, osquery, and response actions.',
  },
  threatIntelligenceAnalyst: {
    id: 'roles-assignments.project.security.threat-intelligence-analyst.description',
    defaultMessage: 'Access to alerts, investigation tools, and intelligence pages.',
  },
  ruleAuthor: {
    id: 'roles-assignments.project.security.rule-author.description',
    defaultMessage:
      'Full management access to platform detection content and automation capabilities.',
  },
  socManager: {
    id: 'roles-assignments.project.security.soc-manager.description',
    defaultMessage:
      'Access to alerts, cases, investigation tools, endpoint policy management, and response actions.',
  },
  endpointOperationsAnalyst: {
    id: 'roles-assignments.project.security.endpoint-operations-analyst.description',
    defaultMessage:
      'Access to endpoint response actions. Can manage endpoint policies, Fleet, and integrations.',
  },
  platformEngineer: {
    id: 'roles-assignments.project.security.platform-engineer.description',
    defaultMessage: 'Access to Fleet, integrations, endpoints, and detection content.',
  },
  detectionsAdmin: {
    id: 'roles-assignments.project.security.detections-admin.description',
    defaultMessage:
      'Full management access to platform detection content and automation capabilities.',
  },
  endpointPolicyManager: {
    id: 'roles-assignments.project.security.endpoint-policy-manager.description',
    defaultMessage:
      'Access to endpoint policy management and related artifacts. Can manage Fleet and integrations.',
  },
})

export function ResourceTypeDisplay({
  resourceType,
  plural,
  capitalize,
}: {
  resourceType: ResourceType
  plural?: boolean
  capitalize?: boolean
}) {
  const getDeploymentDisplay = () => {
    const messages = defineMessages({
      normal: {
        id: 'deployments.long.type',
        defaultMessage: 'hosted {count, plural, one {deployment} other {deployments}}',
        values: { count: plural ? 2 : 1 },
      },
      capitalized: {
        id: 'deployments.long.type.capitalized',
        defaultMessage: 'Hosted {count, plural, one {deployment} other {deployments}}',
        values: { count: plural ? 2 : 1 },
      },
    })

    return capitalize ? { ...messages.capitalized } : { ...messages.normal }
  }

  const resourceTypes: Record<ResourceType, MessageDescriptor> = defineMessages({
    elasticsearch: {
      id: 'projects.long.type.elasticsearch',
      defaultMessage: 'Elasticsearch serverless {count, plural, one {project} other {projects}}',
      values: { count: plural ? 2 : 1 },
    },
    observability: {
      id: 'projects.long.type.observability',
      defaultMessage: 'Observability serverless {count, plural, one {project} other {projects}}',
      values: { count: plural ? 2 : 1 },
    },
    security: {
      id: 'projects.long.type.security',
      defaultMessage: 'Security serverless {count, plural, one {project} other {projects}}',
      values: { count: plural ? 2 : 1 },
    },
    deployment: getDeploymentDisplay(),
  })

  return <FormattedMessage {...resourceTypes[resourceType]} />
}
