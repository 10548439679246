/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router'
import { useState } from 'react'
import { capitalize } from 'lodash'

import { EuiCallOut, EuiFlexGroup } from '@elastic/eui'

import type { Region } from '@modules/cluster-user-api/v1/types'
import ChooseCSPRegion from '@modules/discovery-questions-components/ChooseCSPRegion'
import ExperienceLevel from '@modules/discovery-questions-components/ExperienceLevel'
import FullName from '@modules/discovery-questions-components/FullName'
import TrialIntent from '@modules/discovery-questions-components/TrialIntent'
import UseCase from '@modules/discovery-questions-components/UseCase'
import type { DiscoveryQuestionsStateType } from '@modules/discovery-questions-lib/hooks'
import {
  useOnboardingMetadataFromLocalStorage,
  useOnboardingToken,
  usePutDiscoveryQuestionsMutation,
} from '@modules/discovery-questions-lib/hooks'
import {
  initializeSteps,
  RESOURCE_TYPES,
  ResourceType,
  SEARCH_PROJECT_SUBTYPE_STEPS,
  Step,
} from '@modules/discovery-questions-lib/steps'
import { useCreateProject } from '@modules/project-creation-lib/hooks'
import { projectTypeOnboardingStartingUrl } from '@modules/project-creation-lib/urls'
import type { ProjectApiError, ProjectType } from '@modules/ui-types/projects'
import CreateDeployment from '@modules/deployment-creation-components/CreateDeployment'
import { postCloudAnalytics } from '@modules/analytics-api/caller'
import { useProfile } from '@modules/profile-lib/hooks'
import genericMessages from '@modules/project-lib/genericMessages'
import SelectSearchProjectSubtype from '@modules/discovery-questions-components/SelectSearchProjectSubtype'
import type { ElasticsearchOptimizedFor } from '@modules/project-user-api/v1/types'
import { useSessionStorageBackedState } from '@modules/utils/hooks/useLocalStorage'
import type { UseCaseOptionsType } from '@modules/discovery-questions-lib/utils'
import {
  EXPERIENCE_LEVELS_VALUES,
  TRIAL_INTENTS_VALUES,
  USE_CASES_VALUES,
} from '@modules/discovery-questions-lib/utils'

import { getUrl } from './utils'
import TrialFlowContainer from './TrialFlowContainer'

import type { FunctionComponent, ReactNode } from 'react'

type UrlParams = { questionId: Step; resourceType: ResourceType }

const DiscoveryQuestionsPage: FunctionComponent = () => {
  const [_, setOnboardingMetadata] = useOnboardingMetadataFromLocalStorage()
  const onboardingToken = useOnboardingToken()
  const profile = useProfile()

  let { questionId, resourceType } = useParams<UrlParams>()

  resourceType = RESOURCE_TYPES.find((resource) => resource === resourceType) ?? RESOURCE_TYPES[0]

  const [{ steps, getNextStep, initialState, getPrevStep }, setSteps] = useState(() =>
    initializeSteps({ onboardingToken, resourceType }),
  )
  // validate questionId from the URL against predefined ids, otherwise fallback to the first step
  questionId = steps.find((step) => step === questionId) ?? steps[0]

  const history = useHistory()

  const { mutateAsync } = usePutDiscoveryQuestionsMutation()

  const [errors, setErrors] = useState<ReactNode[]>([])

  const putDiscoveryQuestions = async (onSuccess?: () => void) => {
    await mutateAsync(
      { body: discoveryQuestionsStateTypeToPayload(answers) },
      {
        onError: () => {
          setErrors([
            <FormattedMessage
              id='discovery-questions-page.error-message'
              defaultMessage='Sorry, we were unable to save your answers. Ensure all questions in the previous steps were answered.'
            />,
          ])

          setResourceCreationLaunched(false)
        },
        onSuccess: () => {
          postCloudAnalytics({
            userId: profile?.user_id,
            questions: discoveryQuestionsStateTypeToPayload(answers),
          })
          onSuccess?.()
        },
      },
    )
  }

  const discoveryQuestionsStateTypeToPayload = (state: Partial<DiscoveryQuestionsStateType>) => {
    const { experience_level, trial_intent, use_case, full_name, company } = state

    return {
      experience_level: experience_level
        ? EXPERIENCE_LEVELS_VALUES[experience_level]
        : EXPERIENCE_LEVELS_VALUES.new,
      trial_intent: trial_intent ? TRIAL_INTENTS_VALUES[trial_intent] : TRIAL_INTENTS_VALUES.learn,
      use_case: use_case ? USE_CASES_VALUES[use_case] : USE_CASES_VALUES.search,
      full_name: full_name ? full_name : '',
      company: company ? company : '',
    }
  }

  const { createProject } = useCreateProject()

  const { answers, setAnswer } = useAnswersState({
    initialState: initialState.discoveryQuestions || {},
  })

  const [resourceCreationLaunched, setResourceCreationLaunched] = useState(false)

  const [projectSubtype, setProjectSubtype] = useSessionStorageBackedState<
    ElasticsearchOptimizedFor | undefined
  >('project-subtype', initialState.additionalParameters?.optimized_for)

  const {
    experience_level: experienceLevel,
    trial_intent: trialIntent,
    use_case: useCase,
    full_name: fullName,
    company,
  } = answers

  const { formatMessage } = useIntl()

  function getProjectTypeFromUseCase(use_case: UseCaseOptionsType | undefined): ProjectType {
    if (use_case !== 'search') {
      return use_case as ProjectType
    }

    return 'elasticsearch' as ProjectType
  }

  const projectType = getProjectTypeFromUseCase(useCase)

  const projectName = formatMessage(genericMessages.defaultProjectName, {
    label: capitalize(projectType),
  })

  function renderStep() {
    switch (questionId) {
      case Step.ExperienceLevel:
        return (
          <ExperienceLevel
            experienceLevel={experienceLevel}
            onChangeExperienceLevel={(experience_level) => {
              setAnswer({ experience_level })
            }}
            onSubmitExperienceLevel={() => {
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                history.push(getUrl(resourceType, nextStep, { onboardingToken }))
              }
            }}
          />
        )
      case Step.TrialIntent:
        return (
          <TrialIntent
            trialIntent={trialIntent}
            onChangeTrialIntent={(trial_intent) => {
              setAnswer({ trial_intent })
            }}
            onSubmitTrialIntent={() => {
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                history.push(getUrl(resourceType, nextStep, { onboardingToken }))
              }
            }}
          />
        )
      case Step.UseCase:
        return (
          <UseCase
            useCase={useCase}
            onChangeUseCase={(use_case) => setAnswer({ use_case })}
            onUseCaseConfirmed={() => {
              if (isElasticsearchServerless(resourceType, useCase)) {
                setSteps(
                  initializeSteps({
                    onboardingToken,
                    steps: SEARCH_PROJECT_SUBTYPE_STEPS,
                    resourceType,
                  }),
                )

                history.push(getUrl(resourceType, Step.SearchProjectSubtype, { onboardingToken }))

                return
              }

              const defaultSteps = initializeSteps({ onboardingToken, resourceType })
              setSteps(defaultSteps)
              const nextStep = defaultSteps.getNextStep(questionId)

              if (nextStep) {
                history.push(getUrl(resourceType, nextStep, { onboardingToken }))
              }
            }}
          />
        )
      case Step.CspRegion:
        return getCreateResource()

      case Step.SearchProjectSubtype:
        return (
          <SelectSearchProjectSubtype
            projectSubtype={projectSubtype}
            onChangeSearchProjectSubtype={(value: ElasticsearchOptimizedFor) =>
              setProjectSubtype(value)
            }
            onSearchProjectSubtypeCaseConfirmed={() => {
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                history.push(getUrl(resourceType, nextStep, { onboardingToken }))
              }
            }}
          />
        )
      default:
      case Step.FullName:
        return (
          <FullName
            fullName={fullName || ''}
            company={company || ''}
            onChangeFullName={(full_name) => setAnswer({ full_name })}
            onChangeCompany={(company) => setAnswer({ company })}
            setStep={() => {
              const nextStep = getNextStep(questionId)

              if (nextStep) {
                history.push(getUrl(resourceType, nextStep, { onboardingToken }))
              }
            }}
          />
        )
    }
  }

  function getCreateResource() {
    if (resourceType === ResourceType.Stateful) {
      return <CreateDeployment onDeploymentCreation={() => putDiscoveryQuestions()} />
    }

    return (
      <ChooseCSPRegion
        isDisabled={resourceCreationLaunched}
        onRegionConfirmed={(region) => {
          setResourceCreationLaunched(true)
          putDiscoveryQuestions(() => launchProjectCreation(region))
        }}
      />
    )
  }

  function launchProjectCreation(region: Region | undefined) {
    if (
      region === undefined ||
      (isElasticsearchServerless(resourceType, useCase) && projectSubtype === undefined)
    ) {
      return
    }

    createProject({
      projectName,
      region: region.id as string,
      label: projectType,
      optimizedFor: projectSubtype,
      onSuccess: ({ type: projectType, id: projectId }) => {
        setOnboardingMetadata({
          type: resourceType,
          token: onboardingToken,
          resource_id: projectId,
        })
        history.push(projectTypeOnboardingStartingUrl(projectType, projectId, onboardingToken))
      },
      onError: (projectApiError: ProjectApiError) => {
        setErrors(
          typeof projectApiError === 'string'
            ? [projectApiError]
            : projectApiError.errors.map(({ message }) => message),
        )
        setResourceCreationLaunched(false)
      },
    })
  }

  return (
    <TrialFlowContainer
      data-test-id='discovery-questions.form'
      step={steps.indexOf(questionId)}
      totalSteps={steps.length}
      onGoBack={() => {
        if (resourceType === ResourceType.Serverless && questionId === Step.SearchProjectSubtype) {
          history.push(getUrl(resourceType, Step.UseCase, { onboardingToken }))

          setSteps(initializeSteps({ onboardingToken, resourceType })) // reset steps

          return
        }

        const prevStep = getPrevStep(questionId)

        if (prevStep) {
          history.push(getUrl(resourceType, prevStep, { onboardingToken }))
        }
      }}
    >
      <EuiFlexGroup direction='column'>
        {errors.map((message) => (
          <EuiCallOut
            title={
              <FormattedMessage
                id='discovery-questions-page.error-title'
                defaultMessage='Sorry, we were unable to create your project'
              />
            }
            color='danger'
            iconType='error'
            onDismiss={() => setErrors([])}
          >
            {message}
          </EuiCallOut>
        ))}
        <EuiFlexGroup direction='column' gutterSize='xl'>
          {renderStep()}
        </EuiFlexGroup>
      </EuiFlexGroup>
    </TrialFlowContainer>
  )
}

export default DiscoveryQuestionsPage

/**
 * Hook to manage the state of the answers to the discovery questions
 * Stores the answers in the session storage so that they are not lost when accidentally reloading the page
 * @param initialState - state coming from the onboarding token
 */
const useAnswersState = ({
  initialState,
}: {
  initialState: Partial<DiscoveryQuestionsStateType>
}) => {
  const [answers, setAnswers] = useSessionStorageBackedState(
    'discovery-questions-answers',
    initialState,
  )

  return {
    answers,
    setAnswer: (answer: Partial<DiscoveryQuestionsStateType>) => {
      setAnswers({ ...answers, ...answer })
    },
  }
}

function isElasticsearchServerless(resourceType: ResourceType, useCase: string | undefined) {
  return resourceType === ResourceType.Serverless && useCase === 'search'
}
