/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'
import { type FunctionComponent, Fragment } from 'react'

import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'

import type { RoleAssignments } from '@modules/cloud-api/v1/types'
import {
  filterRoleAssignments,
  getAllRoleAssignments,
  isRoleAssignmentsEmpty,
  normaliseDeploymentRoleAssignments,
  normaliseProjectRoleAssignments,
} from '@modules/role-assignments-lib'
import AdminProjectRoleAssignmentsSummary from '@modules/role-assignments-admin-components/ProjectRoleAssignmentsSummary'
import UserProjectRoleAssignmentsSummary from '@modules/role-assignments-user-components/ProjectRoleAssignmentsSummary'
import type { ProjectType } from '@modules/ui-types/projects'
import { useConfig } from '@modules/cui/ConfigContext'
import type {
  NormalisedResourceRoleAssignment,
  ResourceType,
} from '@modules/role-assignments-lib/types'
import type { MemberOrApiKey } from '@modules/role-assignments-components/ProjectRoleAssignmentsSummary'
import { organizationRoleDescriptions } from '@modules/role-assignments-lib/messages'

import { roleBadgeLabels } from '../../RoleBadges/lib/messages'

import PermissionsRolePanel from './PermissionsRolePanel'
import AllProjectRoleAssignmentsTable from './AllProjectRoleAssignmentsTable'

import type { AllProjectRoleAssignmentsTableItem } from './AllProjectRoleAssignmentsTable'

export type Props = {
  roleAssignments: RoleAssignments
  organizationId: string
  memberOrApiKey: MemberOrApiKey
}

const RolesSummary: FunctionComponent<Props> = ({
  organizationId,
  roleAssignments,
  memberOrApiKey,
}) => {
  const isAdminconsole = useConfig('APP_NAME') === 'adminconsole'

  if (isRoleAssignmentsEmpty(roleAssignments)) {
    const emptyText =
      memberOrApiKey === 'api-key' ? (
        <Fragment>
          <strong>
            <FormattedMessage
              id='apikeys.apikey-role-assignments.prompt-text'
              defaultMessage="This API key doesn't have any role"
            />
          </strong>

          <EuiSpacer size='m' />

          <FormattedMessage
            id='apiKeys.apikey-role-assignments.prompt-text-secondary'
            defaultMessage="You can't add roles to an existing key. You can delete this one, and create a new key instead."
          />
        </Fragment>
      ) : (
        <FormattedMessage
          id='organization.view-permissions-flyout.no-role-assignment'
          defaultMessage='This member does not have a role yet.'
        />
      )

    return <EuiEmptyPrompt titleSize='xs' title={<h4>{emptyText}</h4>} />
  }

  const allRoleAssignmentsTableItems = getAllRoleAssignmentsTableItems(roleAssignments)
  const specificRoleAssignments = getSpecificRoleAssignments(roleAssignments)

  return (
    <EuiFlexGroup direction='column' gutterSize='m'>
      {roleAssignments.organization?.map(({ role_id: roleId }) => (
        <EuiFlexItem data-test-id='organization-panel' key={roleId}>
          <PermissionsRolePanel
            title={<FormattedMessage {...roleBadgeLabels[roleId]} />}
            description={<FormattedMessage {...organizationRoleDescriptions[roleId]} />}
          />
        </EuiFlexItem>
      ))}

      {(allRoleAssignmentsTableItems.length > 0 || specificRoleAssignments.length > 0) && (
        <EuiFlexItem>
          <PermissionsRolePanel
            title={
              <FormattedMessage
                id='roles-summary.instance-access'
                defaultMessage='Instance access'
              />
            }
          >
            <EuiFlexGroup direction='column'>
              {allRoleAssignmentsTableItems.length > 0 && (
                <Fragment>
                  <EuiFlexItem>
                    <FormattedMessage
                      id='roles-summary.instance-access.all.description'
                      defaultMessage='This member has access to all current and future instances of the following types:'
                    />
                  </EuiFlexItem>

                  <EuiFlexItem>
                    <AllProjectRoleAssignmentsTable items={allRoleAssignmentsTableItems} />
                  </EuiFlexItem>
                </Fragment>
              )}

              {specificRoleAssignments.length > 0 && (
                <EuiFlexItem>
                  {isAdminconsole ? (
                    <AdminProjectRoleAssignmentsSummary
                      memberOrApiKey={memberOrApiKey}
                      organizationId={organizationId}
                      roleAssignments={specificRoleAssignments}
                    />
                  ) : (
                    <UserProjectRoleAssignmentsSummary
                      memberOrApiKey={memberOrApiKey}
                      roleAssignments={specificRoleAssignments}
                    />
                  )}
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </PermissionsRolePanel>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

const getAllRoleAssignmentsTableItems = (
  roleAssignments: RoleAssignments,
): Array<AllProjectRoleAssignmentsTableItem<ResourceType>> => {
  const deploymentRoleItem: Array<AllProjectRoleAssignmentsTableItem<'deployment'>> = [
    getAllRoleAssignments(normaliseDeploymentRoleAssignments(roleAssignments.deployment)),
  ].flatMap((roleAssignment) => {
    if (roleAssignment.length === 0) {
      return []
    }

    // Build a complete list of roles per project type
    // In theory this array only ever has one element - we cannot assign more than one role for deployments
    const allRoles = roleAssignment.map((role) => role.role_id)
    return {
      resourceType: 'deployment',
      roleIds: allRoles,
    }
  })

  const projectTableItems: Array<AllProjectRoleAssignmentsTableItem<ProjectType>> = [
    'elasticsearch',
    'observability',
    'security',
  ].flatMap((projectType: ProjectType) => {
    const allAssignments = getAllRoleAssignments(
      normaliseProjectRoleAssignments(projectType)(roleAssignments.project?.[projectType]),
    )

    if (allAssignments.length === 0) {
      return []
    }

    // Build a complete list of roles per project type
    const allRoles = allAssignments.map((role) => role.role_id)
    return { resourceType: projectType, roleIds: allRoles }
  })

  return [...deploymentRoleItem, ...projectTableItems]
}

const getSpecificRoleAssignments = (
  roleAssignments: RoleAssignments,
): Array<NormalisedResourceRoleAssignment<ResourceType>> => {
  const deploymentRoleAssignments = filterRoleAssignments(
    normaliseDeploymentRoleAssignments(roleAssignments.deployment),
    'specificRoleAssignments',
  )

  const projectRoleAssigments = ['elasticsearch', 'observability', 'security'].flatMap(
    (projectType: ProjectType) =>
      filterRoleAssignments(
        normaliseProjectRoleAssignments(projectType)(roleAssignments.project?.[projectType]),
        'specificRoleAssignments',
      ),
  )

  return [...deploymentRoleAssignments, ...projectRoleAssigments]
}

export default RolesSummary
