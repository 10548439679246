/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import type { UserProfileFromApi } from '@modules/ui-types'
import { hasStrongMfaDevice } from '@modules/mfa-lib/devices'

export function isMfaEnforceable({
  mfaEnforced,
  profile,
}: {
  mfaEnforced: boolean
  profile: UserProfileFromApi | undefined
}): boolean {
  if (!mfaEnforced) {
    return false // feature flag
  }

  if (!profile) {
    return false // sanity to avoid flapping
  }

  if (profile.data.identity_provider) {
    return false // third party IdP means not our problem
  }

  return true
}

export function meetsMfaRequirements({
  mfaEnforced,
  profile,
  devices,
}: {
  mfaEnforced: boolean
  profile: UserProfileFromApi | undefined
  devices: SaasAuthMfaDeviceResponse[] | undefined
}): boolean | null {
  if (!profile || !devices) {
    return null
  }

  return !isMfaEnforceable({ mfaEnforced, profile }) || hasStrongMfaDevice(devices)
}
