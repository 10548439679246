/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { findIndex, isEmpty, sortBy } from 'lodash'

import type { Items } from '@modules/billing-api/v1/types'
import type { DeploymentDimension } from '@modules/billing-api/customTypes'
import { colorForInstances } from '@modules/billing-lib/colorGenerator'

import type { ProductData } from '@/components/User/BillingUsage/BillingUsageByInstance/types'
import type {
  ItemsCostsV2,
  ItemKind,
} from '@/components/User/BillingUsage/components/InstanceUsageTable/types'

export const buildServerlessDimensionItems = (costsItems: Items | undefined): ProductData[] => {
  if (!costsItems) {
    return []
  }

  const result: ProductData[] = []
  const dimensions: string[] = [] // for keeping dimensions returned from backend

  const sortedProducts = costsItems.products
    .map((product) => sortBy(product.product_line_items, ['type']))
    .flat()

  for (const item of sortedProducts) {
    let type = ''

    if (!dimensions.includes(item.type)) {
      type = item.type.charAt(0).toUpperCase() + item.type.slice(1)
      dimensions.push(item.type)
    }

    result.push({
      color: colorForInstances.get(item.type.toLowerCase()), // color keys are lowercase to match the color in charts
      quantity: item.quantity.formatted_value,
      display_quantity: item.display_quantity,
      type,
      name: item.name,
      item_price: item.rate.formatted_value,
      total: item.total_ecu,
      unit: item.unit,
      sku: item.sku,
    } as ProductData)
  }

  return result
}

const DATA_TRANSFER_TYPES = ['data_in', 'data_internode', 'data_out']
const STORAGE_TYPES = ['storage_bytes', 'storage_api']

export const mapToDeploymentCostsItemsV2 = (
  costsItems: Items | undefined,
  combineSameResources?: boolean,
): ItemsCostsV2 => {
  const itemsCosts: ItemsCostsV2 = {
    costs: {
      total: 0,
      dimensions: [],
    },
    data_transfer: [],
    storage: [],
    synthetics: [],
    resources: [],
  }

  if (costsItems) {
    itemsCosts.costs.total = costsItems.total_ecu

    for (const product of costsItems.products) {
      for (const item of product.product_line_items) {
        if (item.type === 'capacity') {
          const index = findIndex(itemsCosts.resources, (resource) => resource.kind === item.kind)

          if (index === -1 || !combineSameResources) {
            itemsCosts.resources.push({
              hours: {
                value: item.quantity.value,
                formatted_value: item.quantity.formatted_value,
              },
              instance_count: item.quantity.value,
              kind: item.kind as ItemKind,
              price: item.total_ecu,
              name: item.name,
              sku: item.sku,
              price_per_hour: {
                value: item.rate.value,
                formatted_value: item.rate.formatted_value,
              },
            })
          } else {
            const foundedEl = itemsCosts.resources[index]!
            const combinedHours = foundedEl.hours.value + item.quantity.value
            const combinedPrice = foundedEl.price + item.total_ecu

            foundedEl.price = combinedPrice
            foundedEl.name = `${foundedEl.name}|${item.name}`
            foundedEl.sku = `${foundedEl.sku}|${item.sku}`
            foundedEl.hours = {
              value: combinedHours,
              formatted_value: `${combinedHours} hours`,
            }
            foundedEl.price_per_hour = {
              value: +(combinedPrice / combinedHours).toFixed(4),
              formatted_value: `${(combinedPrice / combinedHours).toFixed(4)} per hour`,
            }
          }
        } else if (DATA_TRANSFER_TYPES.includes(item.type)) {
          itemsCosts.data_transfer.push({
            sku: item.sku,
            name: item.name,
            rate: item.rate,
            cost: item.total_ecu,
            type: item.type,
            quantity: item.quantity,
          })
        } else if (STORAGE_TYPES.includes(item.type)) {
          itemsCosts.storage.push({
            sku: item.sku,
            name: item.name,
            rate: item.rate,
            cost: item.total_ecu,
            type: item.type,
            quantity: item.quantity,
          })
        } else {
          itemsCosts.synthetics.push({
            sku: item.sku,
            name: item.name,
            rate: item.rate,
            cost: item.total_ecu,
            type: item.type,
            quantity: item.quantity,
          })
        }

        // update total ecu for each dimension
        const dimension = itemsCosts.costs.dimensions.find((d) => d.type === item.type)

        if (dimension) {
          dimension.cost += item.total_ecu
        } else {
          itemsCosts.costs.dimensions.push({
            type: item.type as DeploymentDimension,
            cost: item.total_ecu,
          })
        }
      }
    }
  }

  return itemsCosts
}

export interface ServerlessFreeComponent {
  product_type: string
  name: string
  free_message: string
}

interface LDFreeComponent {
  name: string
  product_type: string
  related_component?: string
  always_visible?: boolean
  message?: string
}

interface ServerlessFreeComponentsLD {
  message: string
  components: LDFreeComponent[]
}

export const getServerlessFreeComponents = (
  products: ProductData[],
  serverlessFreeComponentsLD?: ServerlessFreeComponentsLD,
): ServerlessFreeComponent[] => {
  const freeComponents: ServerlessFreeComponent[] = []

  if (isEmpty(serverlessFreeComponentsLD)) {
    return freeComponents
  }

  products.forEach(({ sku }) => {
    const freeComponentLD = serverlessFreeComponentsLD.components.find(
      ({ related_component }) => related_component === sku,
    )

    if (freeComponentLD) {
      freeComponents.push({
        product_type: freeComponentLD.product_type,
        name: freeComponentLD.name,
        free_message: freeComponentLD.message || serverlessFreeComponentsLD.message,
      })
    }
  })

  const alwaysVisibleFreeComponents = serverlessFreeComponentsLD.components.filter(
    ({ always_visible }) => always_visible,
  )

  alwaysVisibleFreeComponents.forEach((component) => {
    freeComponents.push({
      product_type: component.product_type,
      name: component.name,
      free_message: component.message || serverlessFreeComponentsLD.message,
    })
  })

  return freeComponents
}
