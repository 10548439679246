/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiBadge, EuiToolTip } from '@elastic/eui'

import type { OrganizationMembership } from '@modules/cloud-api/v1/types'
import type { AnyClusterPlanInfo } from '@modules/ui-types'

import { isPlanSystemInitiated } from '@/lib/stackDeployments/selectors/fundamentals'

import { getMemberEmail, hasNoMember } from '../Organization/OrganizationMembers/lib'
import OrganizationMemberEmailLabel from '../Organization/OrganizationMembers/OrganizationMemberEmailLabel'

import type { FunctionComponent } from 'react'

type Props = {
  members: OrganizationMembership[]
  planAttempt: AnyClusterPlanInfo
}

const StackConfigurationChangeUser: FunctionComponent<Props> = ({ members, planAttempt }) => {
  const userId = planAttempt.source?.user_id

  if (!isPlanSystemInitiated({ planAttempt })) {
    const email = getMemberEmail({ userId, members })
    const isRemoved = hasNoMember({ userId, members })

    return (
      <EuiToolTip content={email}>
        <div className='deploymentActivityTable-attribution configurationChangeAttribution-email'>
          <OrganizationMemberEmailLabel email={email} isRemoved={isRemoved} />
        </div>
      </EuiToolTip>
    )
  }

  return (
    <EuiBadge color='warning'>
      <FormattedMessage
        id='configuration-change-attribution.system-label'
        defaultMessage='System'
      />
    </EuiBadge>
  )
}

export default StackConfigurationChangeUser
