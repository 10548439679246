/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { flatMap, keyBy, omit } from 'lodash'

import type {
  Organization,
  OrganizationList,
  OrganizationInvitation,
  OrganizationInvitations,
  OrganizationMembership,
  OrganizationMemberships,
  RoleAssignments,
} from '@modules/cloud-api/v1/types'

import {
  CREATE_ORGANIZATION,
  CREATE_ORGANIZATION_MEMBERSHIP,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_INVITATIONS,
  DELETE_ORGANIZATION_MEMBERSHIPS,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATION_INVITATION,
  FETCH_ORGANIZATION_INVITATIONS,
  FETCH_ORGANIZATION_MEMBERSHIPS,
  UPDATE_ORGANIZATION,
  UPSERT_ORGANIZATION_INVITATION,
  UPDATE_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS,
} from '../../constants/actions'
import { replaceIn } from '../../lib/immutability-helpers'

import type { AsyncAction, ReduxState } from '@/types/redux'

export type OrganizationsById = {
  [organizationId: string]: Organization
}

type OrganizationInvitationsByOrganizationId = {
  [organizationId: string]: OrganizationInvitations
}

type OrganizationMembershipsByOrganizationId = {
  [organizationId: string]: OrganizationMemberships
}

export interface State {
  organizations: OrganizationsById
  organizationInvitations: OrganizationInvitationsByOrganizationId
  organizationMemberships: OrganizationMembershipsByOrganizationId
}

const initialState: State = {
  organizations: {},
  organizationInvitations: {},
  organizationMemberships: {},
}

interface CreateOrganizationMembershipAction
  extends AsyncAction<typeof CREATE_ORGANIZATION_MEMBERSHIP, OrganizationMembership> {
  meta: { organizationId: string; userId: string }
}

interface DeleteOrganizationAction extends AsyncAction<typeof DELETE_ORGANIZATION> {
  meta: { organizationId: string }
}

interface DeleteOrganizationInvitationsAction
  extends AsyncAction<typeof DELETE_ORGANIZATION_INVITATIONS> {
  meta: { organizationId: string; tokens: string }
}

interface DeleteOrganizationMembershipsAction
  extends AsyncAction<typeof DELETE_ORGANIZATION_MEMBERSHIPS> {
  meta: { organizationId: string; userIds: string }
}

interface EditOrganizationMembershipRoleAssignmentsAction
  extends AsyncAction<typeof UPDATE_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS> {
  meta: { organizationId: string; userId: string; roleAssignments: RoleAssignments }
}

interface FetchOrganizationInvitationAction
  extends AsyncAction<typeof FETCH_ORGANIZATION_INVITATION, OrganizationInvitation> {
  meta: { invitationToken: string }
}

interface FetchOrganizationInvitationsAction
  extends AsyncAction<typeof FETCH_ORGANIZATION_INVITATIONS, OrganizationInvitations> {
  meta: { organizationId: string }
}

interface FetchOrganizationMembershipsAction
  extends AsyncAction<typeof FETCH_ORGANIZATION_MEMBERSHIPS, OrganizationMemberships> {
  meta: { organizationId: string }
}

interface FetchOrganizationsAction
  extends AsyncAction<typeof FETCH_ORGANIZATIONS, OrganizationList> {}

interface FetchOrUpsertOrganizationAction
  extends AsyncAction<
    typeof FETCH_ORGANIZATION | typeof CREATE_ORGANIZATION | typeof UPDATE_ORGANIZATION,
    Organization
  > {}

interface UpsertOrganizationInvitationsAction
  extends AsyncAction<typeof UPSERT_ORGANIZATION_INVITATION, OrganizationInvitations> {
  meta: { organizationId: string; emails: string[] }
}

type Action =
  | CreateOrganizationMembershipAction
  | DeleteOrganizationAction
  | DeleteOrganizationInvitationsAction
  | DeleteOrganizationMembershipsAction
  | EditOrganizationMembershipRoleAssignmentsAction
  | FetchOrganizationInvitationAction
  | FetchOrganizationInvitationsAction
  | FetchOrganizationMembershipsAction
  | FetchOrganizationsAction
  | FetchOrUpsertOrganizationAction
  | UpsertOrganizationInvitationsAction

export default function organizationsReducer(state: State = initialState, action: Action): State {
  if (action.type === FETCH_ORGANIZATIONS) {
    if (action.payload && !action.error) {
      return replaceIn(state, ['organizations'], keyBy(action.payload.organizations, 'id'))
    }
  }

  if (
    action.type === FETCH_ORGANIZATION ||
    action.type === CREATE_ORGANIZATION ||
    action.type === UPDATE_ORGANIZATION
  ) {
    if (action.payload && !action.error) {
      return replaceIn(state, ['organizations', action.payload.id], action.payload)
    }
  }

  if (action.type === DELETE_ORGANIZATION) {
    if (action.payload && !action.error) {
      return replaceIn(
        state,
        ['organizations'],
        omit(state.organizations, action.meta.organizationId),
      )
    }
  }

  if (action.type === FETCH_ORGANIZATION_INVITATION) {
    if (action.payload && !action.error) {
      const {
        meta: { invitationToken },
        payload,
      } = action

      const organizationId = payload.organization.id

      const {
        organizationInvitations: { [organizationId]: { invitations: prevInvitations = [] } = {} },
      } = state

      const invitations = prevInvitations
        .filter(({ token }) => token !== invitationToken)
        .concat({ ...payload, token: invitationToken })

      return replaceIn(
        state,
        ['organizationInvitations', organizationId, 'invitations'],
        invitations,
      )
    }
  }

  if (action.type === FETCH_ORGANIZATION_INVITATIONS) {
    if (action.payload && !action.error) {
      return replaceIn(
        state,
        ['organizationInvitations', action.meta.organizationId],
        action.payload,
      )
    }
  }

  if (action.type === FETCH_ORGANIZATION_MEMBERSHIPS) {
    if (action.payload && !action.error) {
      return replaceIn(
        state,
        ['organizationMemberships', action.meta.organizationId],
        action.payload,
      )
    }
  }

  if (action.type === UPSERT_ORGANIZATION_INVITATION) {
    if (action.payload && !action.error) {
      const {
        meta: { organizationId },
        payload: { invitations: payloadInvitations },
      } = action

      const {
        organizationInvitations: { [organizationId]: { invitations: prevInvitations = [] } = {} },
      } = state

      const payloadEmails = payloadInvitations.map(({ email }) => email)

      const invitations = prevInvitations
        .filter(({ email }) => !payloadEmails.includes(email))
        .concat(payloadInvitations)

      return replaceIn(
        state,
        ['organizationInvitations', organizationId, 'invitations'],
        invitations,
      )
    }
  }

  if (action.type === CREATE_ORGANIZATION_MEMBERSHIP) {
    if (action.payload && !action.error) {
      const {
        meta: { organizationId },
        payload,
      } = action

      const {
        organizationMemberships: { [organizationId]: { members: prevMembers = [] } = {} },
      } = state

      const members = [...prevMembers, payload]

      return replaceIn(state, ['organizationMemberships', organizationId, 'members'], members)
    }
  }

  if (action.type === UPDATE_ORGANIZATION_MEMBERSHIP_ROLE_ASSIGNMENTS) {
    if (!action.error) {
      const {
        meta: { organizationId, userId, roleAssignments },
      } = action

      const {
        organizationMemberships: { [organizationId]: { members: prevMembers = [] } = {} },
      } = state

      const members = prevMembers.map((member) => {
        if (member.user_id === userId) {
          return {
            ...member,
            role_assignments: roleAssignments,
          }
        }

        return member
      })

      return replaceIn(state, ['organizationMemberships', organizationId, 'members'], members)
    }
  }

  if (action.type === DELETE_ORGANIZATION_INVITATIONS) {
    if (action.payload && !action.error) {
      const {
        meta: { organizationId, tokens },
      } = action

      const {
        organizationInvitations: { [organizationId]: { invitations: prevInvitations = [] } = {} },
      } = state

      const invitations = prevInvitations.filter(({ token }) => !tokens.includes(token))

      return replaceIn(
        state,
        ['organizationInvitations', organizationId, 'invitations'],
        invitations,
      )
    }
  }

  if (action.type === DELETE_ORGANIZATION_MEMBERSHIPS) {
    if (action.payload && !action.error) {
      const {
        meta: { organizationId, userIds },
      } = action

      const {
        organizationMemberships: { [organizationId]: { members: prevMembers = [] } = {} },
      } = state

      const members = prevMembers.filter(({ user_id }) => !userIds.includes(user_id))

      return replaceIn(state, ['organizationMemberships', organizationId, 'members'], members)
    }
  }

  return state
}

function _getOrganizations(state: State): OrganizationsById {
  return state.organizations
}

function _getOrganization(state: State, organizationId: string): Organization | undefined {
  return state.organizations[organizationId]
}

function _getOrganizationInvitation(
  state: State,
  invitationToken: string,
): OrganizationInvitation | undefined {
  const organizationsInvitations = flatMap(
    state.organizationInvitations,
    ({ invitations }) => invitations,
  )

  return organizationsInvitations.find(({ token }) => token === invitationToken)
}

export function _getOrganizationInvitations(
  state: State,
  organizationId: string,
): OrganizationInvitation[] {
  return state.organizationInvitations[organizationId]?.invitations || []
}

export function _getOrganizationMembers(
  state: State,
  organizationId: string | undefined,
): OrganizationMembership[] {
  if (!organizationId) {
    return []
  }

  return state.organizationMemberships[organizationId]?.members || []
}

export function getOrganizations(state: ReduxState) {
  return _getOrganizations(state.organizations)
}

export function getOrganization(state: ReduxState, organizationId: string) {
  return _getOrganization(state.organizations, organizationId)
}

export function getOrganizationInvitation(state: ReduxState, invitationToken: string) {
  return _getOrganizationInvitation(state.organizations, invitationToken)
}

export function getOrganizationInvitations(state: ReduxState, organizationId: string) {
  return _getOrganizationInvitations(state.organizations, organizationId)
}

export function getOrganizationMembers(state: ReduxState, organizationId: string | undefined) {
  return _getOrganizationMembers(state.organizations, organizationId)
}
