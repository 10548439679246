/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle, useEuiTheme } from '@elastic/eui'

import type { PropsWithChildren, ReactNode } from 'react'

type FormLayoutProps = PropsWithChildren<{
  title: ReactNode
  description?: ReactNode
  footer?: ReactNode
}>

export const DiscoveryQuestionStepLayout = ({
  title,
  description,
  children,
  footer,
}: FormLayoutProps) => {
  const {
    euiTheme: {
      colors: { subduedText, text },
    },
    colorMode,
  } = useEuiTheme()

  const descriptionColor = colorMode === 'LIGHT' ? subduedText : text

  return (
    <EuiFlexGroup direction='column' gutterSize='xl'>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction='column' gutterSize='m'>
          <EuiTitle>
            <EuiText>{title}</EuiText>
          </EuiTitle>
          {description && <EuiText color={descriptionColor}>{description}</EuiText>}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction='column' gutterSize='m'>
          {children}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexGroup responsive={false}>
        <EuiFlexItem grow={false}>{footer}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  )
}
