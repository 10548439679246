/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { groupBy } from 'lodash'

import type { EuiSuperSelectProps } from '@elastic/eui'
import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSkeletonRectangle,
  EuiSuperSelect,
  EuiText,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { CSP, Region } from '@modules/cluster-user-api/v1/types'
import ProviderSelector from '@modules/project-creation-components/ProviderSelector'
import { useListRegionsQuery } from '@modules/cluster-user-lib/hooks'

import { DiscoveryQuestionStepLayout } from './DiscoveryQuestionStepLayout'
import messages from './messages'

import type { FC } from 'react'

export interface ChooseCSPRegionProps {
  isDisabled: boolean
  onRegionConfirmed: (region: Region) => void
}

type State = {
  selectedProvider: CSP | undefined
  selectedRegion: Region | undefined
}

const defaultState: State = {
  selectedProvider: undefined,
  selectedRegion: undefined,
}

const ChooseCSPRegion: FC<ChooseCSPRegionProps> = ({
  isDisabled,
  onRegionConfirmed,
}: ChooseCSPRegionProps) => {
  const { data: regions, isError, isLoading, refetch } = useListRegionsQuery()

  const [state, setState] = useState<State>(defaultState)

  const { selectedProvider, selectedRegion } = state

  const cspRegions = groupBy(regions, `csp`) as Record<CSP, Region[]>

  const providers = Object.keys(cspRegions) as CSP[]

  const defaultProvider = providers[0] as CSP

  return (
    <DiscoveryQuestionStepLayout
      title={<FormattedMessage {...messages.title} />}
      description={<FormattedMessage {...messages.subtitle} />}
      footer={
        !isError && (
          <EuiButton
            data-test-id='signup.discovery-questions.launch'
            isLoading={isDisabled}
            fill={true}
            onClick={handleLaunchButtonClicked}
          >
            {isDisabled ? (
              <FormattedMessage {...messages.loading} />
            ) : (
              <FormattedMessage {...messages.launch} />
            )}
          </EuiButton>
        )
      }
    >
      <ChooseCSPRegionInnerContent
        isError={isError}
        isLoading={isLoading}
        refetch={refetch}
        regions={regions}
        selectedProvider={selectedProvider}
        selectedRegion={selectedRegion}
        onChange={setState}
      />
    </DiscoveryQuestionStepLayout>
  )

  function handleLaunchButtonClicked() {
    const provider = selectedProvider ?? defaultProvider

    const region = selectedRegion ?? cspRegions[provider][0]

    if (region === undefined) {
      return
    }

    onRegionConfirmed(region)
  }
}

type ChooseCSPRegionInnerContentProps = {
  isError: boolean
  isLoading: boolean
  refetch: () => void
  regions: Region[] | undefined
  selectedProvider: CSP | undefined
  selectedRegion: Region | undefined
  onChange: (state: State) => void
}

export const ChooseCSPRegionInnerContent: FC<ChooseCSPRegionInnerContentProps> = ({
  isError,
  isLoading,
  refetch,
  regions,
  selectedProvider,
  selectedRegion,
  onChange,
}) => {
  if (isError) {
    return <FetchErrorCallOut refetch={refetch} />
  }

  if (isLoading || regions === undefined) {
    return (
      <EuiFlexGroup>
        <EuiSkeletonRectangle width='196px' height='38px' />
        <EuiSkeletonRectangle width='88' height='38px' />
      </EuiFlexGroup>
    )
  }

  return (
    <CSPRegionFormRow
      regions={regions}
      selectedProvider={selectedProvider}
      selectedRegion={selectedRegion}
      onChange={onChange}
    />
  )
}

type RegionSelectorProps = {
  selectedRegion: Region
  allowedRegions: Region[]
  onSelectionChange: (value: Region) => void
} & Omit<EuiSuperSelectProps<Region>, 'options' | 'valueOfSelected' | 'onChange'>

const ServerlessRegionSelector = ({
  selectedRegion,
  allowedRegions,
  onSelectionChange,
  ...props
}: RegionSelectorProps) => (
  <EuiSuperSelect
    fullWidth={true}
    valueOfSelected={selectedRegion}
    options={allowedRegions.map((region) => ({
      value: region,
      inputDisplay: <EuiText>{`${region.name} ${region.csp_region}`}</EuiText>,
    }))}
    onChange={onSelectionChange}
    {...props}
  />
)

export default ChooseCSPRegion

const FetchErrorCallOut: FC<{ refetch: () => void }> = ({ refetch }) => {
  const { formatMessage } = useIntl()

  return (
    <EuiCallOut
      title={formatMessage(messages.error)}
      color='danger'
      iconType='alert'
      data-test-id='signup.discovery-questions.error'
    >
      <EuiButton
        onClick={() => refetch()}
        color='danger'
        aria-label={formatMessage({
          ...messages.retry,
        })}
      >
        {formatMessage(messages.retry)}
      </EuiButton>
    </EuiCallOut>
  )
}

const CSPRegionFormRow: FC<{ regions: Region[]; onChange: (state: State) => void } & State> = ({
  regions,
  selectedProvider,
  selectedRegion,
  onChange,
}) => {
  const cloudProviderHtmlId = useGeneratedHtmlId()

  const regionHtmlId = useGeneratedHtmlId()

  const cspRegions = groupBy(regions, `csp`) as Record<CSP, Region[]>

  const providers = Object.keys(cspRegions) as CSP[]

  const defaultProvider = providers[0] as CSP

  const defaultRegion = cspRegions[defaultProvider][0] as Region

  const allowedRegions = cspRegions[selectedProvider ?? defaultProvider]

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiFormRow
          label={
            <EuiTitle size='xxxs'>
              <EuiText id={cloudProviderHtmlId}>
                <FormattedMessage {...messages.cloudProvider} />
              </EuiText>
            </EuiTitle>
          }
        >
          <ProviderSelector
            value={selectedProvider ?? defaultProvider}
            availableProviders={providers}
            onChange={(value: CSP) => {
              onChange({
                selectedProvider: value,
                selectedRegion: cspRegions[value][0], // reset region when provider changes
              })
            }}
            showPrepend={false}
            aria-labelledby={cloudProviderHtmlId}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFormRow
          label={
            <EuiTitle size='xxxs'>
              <EuiText id={regionHtmlId}>
                <FormattedMessage {...messages.region} />
              </EuiText>
            </EuiTitle>
          }
        >
          <ServerlessRegionSelector
            selectedRegion={selectedRegion ?? defaultRegion}
            allowedRegions={allowedRegions}
            onSelectionChange={(region) => {
              onChange({
                selectedProvider: region.csp,
                selectedRegion: region,
              })
            }}
            aria-labelledby={regionHtmlId}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
