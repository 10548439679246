/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiText,
  EuiSpacer,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHideFor,
} from '@elastic/eui'

import { ProductType } from '../../../types'
import { Action } from '../filtersReducer'
import ProductTypeSelect from '../ProductTypeSelect'
import SolutionSelect from '../SolutionSelect'

import type { Actions, ReducerState } from '../filtersReducer'
import type { FunctionComponent, Dispatch } from 'react'

interface Props {
  isLoading: boolean
  dispatch: Dispatch<Actions>
  state: ReducerState
  shouldShowServerlessFilters: boolean
}

const SideFilters: FunctionComponent<Props> = ({
  isLoading,
  dispatch,
  state,
  shouldShowServerlessFilters,
}) => {
  const onResetFilters = () => dispatch({ type: Action.RESET_FILTERS })

  if (!shouldShowServerlessFilters) {
    return null
  }

  return (
    <EuiHideFor sizes={['xs', 's', 'm']}>
      <EuiFlexItem grow={2}>
        <EuiSpacer size='m' />
        <div>
          <EuiFlexGroup alignItems='center'>
            <EuiFlexItem grow={false}>
              <EuiText size='xs'>
                <h3>
                  <FormattedMessage id='billing-usage.filters.title' defaultMessage='Filters' />
                </h3>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty onClick={onResetFilters} isDisabled={isLoading}>
                <FormattedMessage
                  id='billing-usage.filters.resetFilters'
                  defaultMessage='Reset filters'
                />
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size='xl' />

          <ProductTypeSelect
            values={state.productTypes}
            onChange={(productTypes) =>
              dispatch({
                type: Action.SET_PRODUCT_TYPES,
                payload: productTypes,
              })
            }
            isLoading={isLoading}
          />

          <EuiSpacer size='m' />

          <SolutionSelect
            values={state.solutions}
            onChange={(solutions) =>
              dispatch({
                type: Action.SET_SOLUTIONS,
                payload: solutions,
              })
            }
            isLoading={isLoading}
            isDisabled={!state.productTypes.includes(ProductType.Projects)}
          />
        </div>
      </EuiFlexItem>
    </EuiHideFor>
  )
}

export default SideFilters
