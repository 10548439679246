/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiProgress,
  EuiSpacer,
  useEuiTheme,
  useIsWithinBreakpoints,
} from '@elastic/eui'

import { CuiThemedIcon } from '@modules/cui/ThemedIcon'

import elasticLogo from './files/elastic-logo-H-color.svg'
import elasticLogoDark from './files/elastic-logo-H-color-dark.svg'
import backgroundLight from './files/background.svg'
import backgroundDark from './files/background-dark.svg'

import type { FunctionComponent } from 'react'

interface TrialFlowContainerProps {
  step?: number
  totalSteps?: number
  children: JSX.Element
  onGoBack?: () => void
  'data-test-id'?: string
}

const TrialFlowContainer: FunctionComponent<TrialFlowContainerProps> = ({
  step,
  totalSteps,
  children,
  onGoBack,
  'data-test-id': dataTestId,
}) => {
  const {
    euiTheme: {
      breakpoint: { m: euiBreakpointM },
      size: { m: euiSizeM, xl: euiSizeXL },
      colors: { lightestShade, emptyShade },
    },
    colorMode,
  } = useEuiTheme()

  const isLargeScreen = useIsWithinBreakpoints(['l', 'xl'])

  const backgroundImage = colorMode === 'LIGHT' ? backgroundLight : backgroundDark
  const backgroundColor = colorMode === 'LIGHT' ? lightestShade : emptyShade

  return (
    <EuiFlexGroup
      data-test-id={dataTestId}
      justifyContent='flexStart'
      css={css({
        [`@media(max-width: ${euiBreakpointM})`]: {
          padding: euiSizeM,
        },
        backgroundColor,
      })}
    >
      <EuiFlexItem grow={10}>
        <EuiFlexGroup direction='column'>
          <div>
            <CuiThemedIcon
              size='xxl'
              css={css({ width: 'auto', height: '39px', margin: euiSizeXL })}
              lightType={elasticLogo}
              darkType={elasticLogoDark}
            />
          </div>
          <EuiFlexGroup direction='column' justifyContent='center'>
            <EuiPanel
              paddingSize='s'
              hasShadow={false}
              color='transparent'
              css={css({
                width: '100%',
                maxWidth: 625,
                alignSelf: 'center',
                ...(isLargeScreen && { minHeight: 600 }),
              })}
              grow={false}
            >
              <EuiFlexGroup
                direction='column'
                css={css({
                  [`@media(min-width: ${euiBreakpointM})`]: {
                    height: '100%',
                  },
                })}
                gutterSize='xl'
              >
                {step !== undefined && totalSteps !== undefined && (
                  <EuiProgress
                    css={{ width: 300 }}
                    value={((step + 1) / totalSteps) * 100}
                    max={100}
                    size='m'
                  />
                )}
                <EuiFlexItem grow={false}>{children}</EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexGroup>
          <EuiFlexItem grow={false} css={css({ height: '100px' })}>
            {step !== 0 && onGoBack && (
              <EuiFlexGroup alignItems='center'>
                <EuiButtonEmpty
                  css={css({ margin: `0 ${euiSizeXL}` })}
                  onClick={() => {
                    onGoBack()
                  }}
                >
                  <FormattedMessage id='discovery-questions.go-back' defaultMessage='Go back' />
                </EuiButtonEmpty>
              </EuiFlexGroup>
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem
        grow={6}
        css={css({
          background: `url("${backgroundImage}") center right no-repeat`,
          backgroundSize: 'cover',
          [`@media(max-width: ${euiBreakpointM})`]: {
            background: 'none',
            display: 'none',
          },
        })}
      >
        <EuiSpacer />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default TrialFlowContainer
