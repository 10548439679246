/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import Breadcrumbs from '@modules/cui/Breadcrumbs/Breadcrumbs'
import { solutionCreationCrumbs } from '@modules/project-creation-lib/breadcrumbs'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import ProjectCreationContainer from '../ProjectCreationContainer'

import { useSolutionOptions } from './messages'
import SelectSolutionCard from './SelectSolutionCard'

import type { FunctionComponent } from 'react'

const SelectSolution: FunctionComponent = () => {
  const [isFlagsUsable, { includesElasticsearchBadge }] = useFlagsWhenLoaded()

  const isBadgeEnabled = isFlagsUsable && includesElasticsearchBadge

  const solutionOptions = useSolutionOptions()

  return (
    <Fragment>
      <Breadcrumbs breadcrumbs={solutionCreationCrumbs()} />
      <ProjectCreationContainer
        title={
          <Fragment>
            <EuiTitle size='m'>
              <h1>
                {isBadgeEnabled ? (
                  <FormattedMessage
                    id='select-solution.main-title-b'
                    defaultMessage='Which type of project would you like to create?'
                  />
                ) : (
                  <FormattedMessage
                    id='select-solution.main-title-a'
                    defaultMessage='Which type of serverless project would you like to create?'
                  />
                )}
              </h1>
            </EuiTitle>
          </Fragment>
        }
        step={1}
      >
        <EuiFlexGroup direction='column' alignItems='center'>
          <EuiFlexItem>
            {!isBadgeEnabled && (
              <EuiText textAlign='center' color='subdued'>
                <FormattedMessage
                  id='select-solution.main-description'
                  defaultMessage='All serverless project types include Elasticsearch, Kibana, and the rest of the Elastic Stack.'
                />
              </EuiText>
            )}
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiSpacer size='s' />
            <EuiFlexGroup alignItems='stretch' justifyContent='center'>
              {solutionOptions.map(({ label, description, features, logo, title }, index) => (
                <SelectSolutionCard
                  label={label}
                  description={description}
                  features={features}
                  logo={logo}
                  title={title}
                  index={index}
                  key={`${index}-key`}
                />
              ))}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </ProjectCreationContainer>
    </Fragment>
  )
}

export default SelectSolution
