/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { cloneDeep, isEqual, size } from 'lodash'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFormHelpText, EuiTextColor } from '@elastic/eui'

import type {
  ChangeSourceInfo,
  ElasticsearchClusterPlan,
  InstanceConfiguration,
} from '@modules/cloud-api/v1/types'
import type { AnyPlan, RegionId, SliderInstanceType } from '@modules/ui-types'

import { getPlanVersion } from '@/lib/stackDeployments/selectors/fundamentals'

import StrategyExplained from '../../StrategyExplained'
import { isExplicitStrategy, isAutodetect } from '../../../lib/clusterStrategies'
import { diffPlan } from '../../../lib/stackDeployments/planDiffs'
import { diffTopology } from '../../../lib/stackDeployments/planDiffs/topology'

import { formatDifferences } from './formatters'
import { getProductName } from './polyglotTopology'
import explainVacateInstancesChanges from './explainVacateInstancesChanges'
import explainVacateAllocatorChanges from './explainVacateAllocatorChanges'
import RestoreSnapshotChange from './ExplainChanges/RestoreSnapshotChange'

import type { ExplainedChange, ExplainedChangeType } from './types'
import type { AutoscalingEnabled } from '@/lib/stackDeployments/autoscaling'

export function explainTopologyChanges({
  type,
  newSource,
  oldSource,
  instanceConfigurations,
  autoscalingStatus,
}: {
  type: SliderInstanceType
  newSource?: AnyPlan
  oldSource?: AnyPlan
  instanceConfigurations: InstanceConfiguration[]
  autoscalingStatus: AutoscalingEnabled
}): ExplainedChange[] {
  const differences = diffPlan(
    {
      instanceConfigurations,
      sliderInstanceType: type,
      pruneOrphans: false,
      current: oldSource,
      next: newSource,
      autoscalingStatus,
    },
    [diffTopology],
  )
  return formatDifferences({ differences, isPastHistory: false })
}

export function considerNoOpProductChanges({
  changes,
  type,
  oldPlan,
  newPlan,
}: {
  changes: ExplainedChange[]
  type: ExplainedChangeType
  oldPlan: AnyPlan | null
  newPlan: AnyPlan | null
}) {
  if (typeof newPlan === `undefined`) {
    return
  }

  const productChanges = changes.filter((change) => change.type === type)

  if (productChanges.length > 0) {
    return
  }

  const version = getPlanVersion({ plan: oldPlan || newPlan })

  const productName = getProductName({ type, version })

  // remove plan-runtime considerations to ensure an meaningful equality comparison
  const newPlanForComparison = cloneDeep(newPlan)
  const oldPlanForComparison = cloneDeep(oldPlan)

  ;[newPlanForComparison, oldPlanForComparison].forEach((plan) => {
    if (plan?.transient?.strategy) {
      delete plan.transient.strategy
    }

    if (plan?.transient && size(plan.transient) === 0) {
      delete plan.transient
    }
  })

  if (isEqual(newPlanForComparison, oldPlanForComparison)) {
    changes.push({
      'data-test-id': `${type}-no-op`,
      id: `${type}-no-op`,
      type,
      message: (
        <FormattedMessage
          id='explain-changes.configuration-no-op'
          defaultMessage='No {productName} configuration changes'
          values={{ productName }}
        />
      ),
    })
  } else {
    changes.push({
      'data-test-id': `${type}-no-significant-op`,
      id: `${type}-no-significant-op`,
      type,
      message: (
        <FormattedMessage
          id='explain-changes.configuration-no-significant-op'
          defaultMessage='No significant {productName} configuration changes'
          values={{ productName }}
        />
      ),
    })
  }
}

export function getClusterOperations({
  type,
  plan,
  source,
  regionId,
  esResourceId,
  isPastHistory,
}: {
  type: ExplainedChangeType
  plan: AnyPlan | null
  source: ChangeSourceInfo | undefined
  regionId: RegionId
  esResourceId: string | null
  isPastHistory: boolean
}): ExplainedChange[] {
  const clusterOperations: ExplainedChange[] = []

  if (source?.action === `apm.reset-secret-token`) {
    clusterOperations.push({
      id: `apm-server-token-reset`,
      type,
      message: (
        <FormattedMessage
          id='explain-changes.reset-secret-apm-token'
          defaultMessage='Reset the APM Server secret token'
        />
      ),
    })
  } else if (plan?.transient?.plan_configuration?.cluster_reboot === `forced`) {
    clusterOperations.push({
      id: `${type}-force-restart`,
      type,
      message: (
        <FormattedMessage
          id='explain-changes.cluster-reboot-forced'
          defaultMessage='Forcefully restart the deployment'
        />
      ),
    })
  }

  if (type === `elasticsearch`) {
    const esPlan = plan as ElasticsearchClusterPlan | null

    const snapshotName = esPlan?.transient?.restore_snapshot?.snapshot_name

    if (snapshotName) {
      clusterOperations.push({
        id: `${type}-restore-snapshot`,
        testParams: [snapshotName],
        type,
        message: (
          <RestoreSnapshotChange
            regionId={regionId}
            esClusterId={esResourceId || undefined}
            snapshotName={snapshotName}
            restoreFromEsClusterId={esPlan.transient?.restore_snapshot?.source_cluster_id}
          />
        ),
      })
    }

    if (esPlan?.transient?.plan_configuration?.skip_snapshot === true) {
      clusterOperations.push({
        id: `${type}-skip-snapshot`,
        type,
        message: (
          <FormattedMessage
            id='explain-changes.skip-snapshot'
            defaultMessage='Skip snapshot before applying deployment configuration change'
          />
        ),
      })
    }

    if (esPlan?.transient?.plan_configuration?.skip_data_migration === true) {
      clusterOperations.push({
        id: `${type}-skip-data-migration`,
        type,
        message: (
          <Fragment>
            <FormattedMessage
              id='explain-changes.dont-migrate'
              defaultMessage="Don't attempt to gracefully move shards"
            />
            <EuiFormHelpText>
              <FormattedMessage
                id='explain-changes.dont-migrate-help-text'
                defaultMessage='The underlying host experienced an outage and the system will recover data from available replicas or snapshots'
              />
            </EuiFormHelpText>
          </Fragment>
        ),
      })
    }

    if (esPlan?.transient?.plan_configuration?.override_failsafe === true) {
      clusterOperations.push({
        id: `${type}-override-failsafe`,
        type,
        message: (
          <FormattedMessage
            id='explain-changes.override-failsafe'
            defaultMessage='Override all safety checks. This is a highly dangerous operation.'
          />
        ),
      })
    }

    if (esPlan?.transient?.plan_configuration?.force_bucket_management_migration === true) {
      clusterOperations.push({
        id: `${type}-azure-bucket-migration`,
        type,
        message: (
          <FormattedMessage
            id='explain-changes.azure-bucket-migration'
            defaultMessage='Enable dedicated snapshot repository for this deployment'
          />
        ),
      })
    }
  }

  if (plan?.transient?.plan_configuration?.extended_maintenance === true) {
    clusterOperations.push({
      id: `${type}-extended-maintenance`,
      type,
      message: (
        <Fragment>
          <FormattedMessage
            id='explain-changes.extended-maintenance'
            defaultMessage='Keep nodes in maintenance mode until configuration changes have been completed'
          />

          {isPastHistory ? null : (
            <EuiFormHelpText>
              <EuiTextColor color='danger'>
                <FormattedMessage
                  id='explain-changes.extended-maintenance-danger'
                  defaultMessage='Access to all deployment instances will be blocked during configuration changes'
                />
              </EuiTextColor>
            </EuiFormHelpText>
          )}
        </Fragment>
      ),
    })
  }

  if (plan?.transient?.plan_configuration?.reallocate_instances === true) {
    clusterOperations.push({
      id: `${type}-reallocate-nodes`,
      type,
      message: (
        <FormattedMessage
          id='explain-changes.reallocate'
          defaultMessage='Create new containers for all nodes'
        />
      ),
    })
  }

  const moveAllocators = plan?.transient?.plan_configuration?.move_allocators

  if (moveAllocators && moveAllocators.length > 0) {
    clusterOperations.push(
      ...explainVacateAllocatorChanges({
        regionId,
        type,
        moveAllocators,
      }),
    )
  }

  const moveInstances = plan?.transient?.plan_configuration?.move_instances

  if (moveInstances && moveInstances.length > 0) {
    clusterOperations.push(
      ...explainVacateInstancesChanges({
        regionId,
        type,
        moveInstances,
      }),
    )
  }

  const strategy = plan?.transient?.strategy || {}

  if (isExplicitStrategy(strategy) && !isAutodetect(strategy)) {
    clusterOperations.push({
      id: `${type}-strategy`,
      type,
      message: <StrategyExplained strategy={strategy} />,
    })
  }

  return clusterOperations
}
