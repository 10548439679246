/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { parse } from 'querystring'

import { z } from 'zod'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router'

import { putDiscoveryQuestions } from '@modules/discovery-questions-api/callers'
import { invalidateGetUserProfileQuery } from '@modules/profile-lib/hooks'
import type { QueryFunctionReturnType } from '@modules/query/types'
import { useTypedLocalStorage } from '@modules/utils/useTypedLocalStorage'

import {
  getKibanaDeploymentDeepLink,
  getKibanaProjectDeepLink,
  isValidOnboardingToken,
  OnboardingToken,
  ResourceType,
} from './steps'

import type {
  ExperienceLevelOptionsType,
  ExperienceLevelOptionsValueType,
  TrialIntentOptionsType,
  TrialIntentOptionsValueType,
  UseCaseOptionsType,
  UseCaseOptionsValueType,
} from './utils'

export type DiscoveryQuestionsStateType = {
  full_name: string
  company: string
  experience_level: ExperienceLevelOptionsType
  use_case: UseCaseOptionsType
  trial_intent: TrialIntentOptionsType
}

export interface DiscoveryQuestionsPayload {
  full_name: string
  company: string
  experience_level: ExperienceLevelOptionsValueType
  trial_intent: TrialIntentOptionsValueType
  use_case: UseCaseOptionsValueType
  use_case_detail?: string
}

export const usePutDiscoveryQuestionsMutation = () =>
  useMutation<
    QueryFunctionReturnType<typeof putDiscoveryQuestions>,
    { message },
    { body: DiscoveryQuestionsPayload }
  >({
    mutationFn: ({ body }: { body: DiscoveryQuestionsPayload }) => putDiscoveryQuestions(body),
    onSuccess: () => {
      invalidateGetUserProfileQuery()
    },
  })

export const useOnboardingToken = (): OnboardingToken | undefined => {
  const { search } = useLocation()
  const query = parse(search.slice(1))
  const onboardingToken = query.onboarding_token as string | undefined

  if (isValidOnboardingToken(onboardingToken)) {
    return onboardingToken
  }

  return undefined
}

function getValues<T extends Record<string, any>>(obj: T) {
  return Object.values(obj) as [(typeof obj)[keyof T]]
}

const onboardingMetadataSchema = z.object({
  type: z.enum(getValues(ResourceType)).optional(),
  token: z.enum(getValues(OnboardingToken)).optional(),
  resource_id: z.string().optional(),
})

type OnboardingMetadata = z.infer<typeof onboardingMetadataSchema>

export const useOnboardingMetadataFromLocalStorage = (initialValue?: OnboardingMetadata) => {
  const result = useTypedLocalStorage(
    'ONBOARDING_METADATA',
    onboardingMetadataSchema,
    initialValue || {},
  )
  return result
}

export const useKibanaDeeplinkForCurrentResource = (id: string) => {
  const [onboardingMetadata] = useOnboardingMetadataFromLocalStorage()

  const resourceType = onboardingMetadata.type

  let kibanaDeepLink

  if (resourceType && id === onboardingMetadata.resource_id) {
    if (resourceType === ResourceType.Serverless) {
      kibanaDeepLink = getKibanaProjectDeepLink(onboardingMetadata.token)
    } else {
      kibanaDeepLink = getKibanaDeploymentDeepLink(onboardingMetadata.token)
    }
  }

  return kibanaDeepLink
}
