/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Sticky from 'react-sticky-el'
import cx from 'classnames'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import {
  EuiSplitPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiHorizontalRule,
  EuiSkeletonText,
  EuiButtonEmpty,
  EuiAccordion,
  EuiPanel,
  EuiIconTip,
} from '@elastic/eui'

import type {
  StackVersionConfig,
  DeploymentSearchResponse,
  DeploymentClaimRequest,
  DeploymentClaimResponse,
} from '@modules/cloud-api/v1/types'
import { AjaxRequestError } from '@modules/ui-types'
import type {
  ClusterSnapshot,
  AsyncRequestState,
  ProfileState,
  RegionId,
  AnyTopologyElement,
  AsyncRequestError,
  StackDeployment,
} from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import { getResponseStatus } from '@modules/utils/ajax'

import PriceButton from '@/components/StackDeploymentEditor/CreateStackDeploymentEditor/PriceButton'
import DeploymentArchitecture from '@/components/DeploymentArchitecture/DeploymentArchitecture'
import { getDeploymentNodeConfigurations } from '@/lib/stackDeployments/selectors/topologyElements'
import { getLatestSnapshotSuccess } from '@/lib/stackDeployments/selectors/snapshots'
import {
  getRegionId,
  getFirstEsClusterFromGet,
  getRegionIdForCreate,
} from '@/lib/stackDeployments/selectors/fundamentals'
import { getAutoscalingStatus } from '@/lib/stackDeployments/selectors/autoscaling'
import { getUpsertVersion } from '@/lib/stackDeployments/selectors/creates'
import {
  setEsSettings,
  getDeploymentVersionSetter,
  getDeploymentNameSetter,
  getCreatePayload,
  changeRestoreFromSnapshot,
} from '@/lib/stackDeployments/crud'

import { setAutoscalingDisabled } from '../../../lib/stackDeployments/autoscaling'
import CreateStackDeploymentTitle from '../CreateStackDeploymentTitle'
import { inTrial, inActiveTrial } from '../../../lib/trial'

import CreateDeploymentButton from './CreateDeploymentButton'
import ConfigureDeploymentSteps from './ConfigureDeploymentSteps'
import SelectTemplate from './SelectTemplate'

import type { LDProps } from 'launchdarkly-react-client-sdk/src/withLDConsumer'
import type { CreateEditorComponentConsumerProps as ConsumerProps } from '../types'
import type { WrappedComponentProps } from 'react-intl'

import './createStackDeploymentEditor.scss'

type StateProps = {
  showPrice: boolean
  stackVersions: StackVersionConfig[] | undefined
  createStackDeploymentRequest: AsyncRequestState
  claimInstantStackDeploymentRequest: AsyncRequestState
  trialMaxedOut: boolean
  profile: ProfileState
  isLaunchdarklyEnabled: boolean
  isUserConsole: boolean
}

interface DispatchProps {
  claimInstantStackDeployment: (params: { payload: DeploymentClaimRequest }) => Promise<any>
  fetchDeployment: (stackDeploymentId: string) => Promise<any>
}

export type Props = StateProps & DispatchProps & ConsumerProps

type State = {
  claimedInstantStackDeploymentId: string | null
  restoreSnapshotSource: DeploymentSearchResponse | null
  showArchitectureSummary: boolean
  priceViewSelected: `hourly` | `monthly`
}

class CreateStackDeploymentEditor extends Component<
  Props & WrappedComponentProps & LDProps,
  State
> {
  state: State = {
    restoreSnapshotSource: null,
    showArchitectureSummary: false,
    priceViewSelected: `hourly`,
    claimedInstantStackDeploymentId: null,
  }

  render() {
    const { profile, showTrialExperience } = this.props

    /* only show settings and advanced settings link if
     *   a) the user isn't in a trial
     *   b) the user is in a trial but already created at least one deployment
     */
    const showSettings = !inTrial({ profile }) || inActiveTrial({ profile })

    return (
      <div className='create-deployment-from-template'>
        <EuiSpacer size='l' />
        <EuiSplitPanel.Outer className='create-deployment-panel'>
          <EuiSplitPanel.Inner className='create-deployment-panel-title'>
            <CreateStackDeploymentTitle showTrialExperience={showTrialExperience} />
            <EuiHorizontalRule margin='s' />
          </EuiSplitPanel.Inner>
          {this.renderPage(showSettings)}
          {this.renderFooter(showSettings)}
        </EuiSplitPanel.Outer>
      </div>
    )
  }

  renderPage(showSettings: boolean) {
    const {
      availableVersions,
      createStackDeploymentRequest,
      editorState,
      onChange,
      region,
      setDeploymentTemplate,
      setRegion,
      showPrice,
      showRegion,
      trialMaxedOut,
      whitelistedVersions,
      globalDeploymentTemplates,
      setGlobalTemplate,
      deploymentTemplates,
      isUserConsole,
      setSnapshotSettingsDisabled,
    } = this.props

    const { restoreSnapshotSource } = this.state

    const { deployment } = editorState
    const isAutoscalingMLEnabled = getAutoscalingStatus({ deployment }) === `ml`
    const showMLAutoscalingBell = isAutoscalingMLEnabled && isUserConsole

    return (
      <EuiSplitPanel.Inner className='create-deployment-panel-body'>
        <SelectTemplate
          disabled={createStackDeploymentRequest.inProgress}
          editorState={editorState}
          globalDeploymentTemplates={globalDeploymentTemplates}
          setDeploymentName={getDeploymentNameSetter({ onChange })}
          setDeploymentTemplate={setDeploymentTemplate}
          setGlobalTemplate={setGlobalTemplate}
          availableVersions={availableVersions}
          whitelistedVersions={whitelistedVersions}
          setVersion={getDeploymentVersionSetter({ editorState, onChange })}
          setRegion={setRegion}
          showRegion={showRegion!}
          region={region}
          showPrice={showPrice}
          onChange={onChange}
          onChangeSnapshotSource={this.onChangeSnapshotSource}
          onChangeSnapshot={this.onChangeSnapshot}
          setEsSettings={this.updateEsSettings}
          restoreFromSnapshot={restoreSnapshotSource !== null}
          trialMaxedOut={trialMaxedOut}
          deploymentTemplates={deploymentTemplates}
          showSettings={showSettings}
          setSnapshotSettingsDisabled={setSnapshotSettingsDisabled}
        />

        <EuiSpacer size='l' />

        {showSettings && (
          <Fragment>
            <EuiAccordion
              className='configure-deployment-steps-accordion'
              id='configure-deployment-steps-accordion'
              data-test-id='configure-deployment-steps-accordion'
              buttonContent={
                <Fragment>
                  <FormattedMessage
                    id='create-deployment-from-template.advanced-settings'
                    defaultMessage='Advanced settings'
                  />
                  {showMLAutoscalingBell && (
                    <Fragment>
                      {` `}
                      <EuiIconTip
                        type='bell'
                        color='subdued'
                        content={
                          <FormattedMessage
                            id='create-deployment-from-template.ml-autoscaling-enabled'
                            defaultMessage='Autoscaling enabled for ML nodes by default (configurable in Advanced settings)'
                          />
                        }
                      />
                    </Fragment>
                  )}
                </Fragment>
              }
              buttonProps={{
                /* @ts-expect-error data-test-id is not declared on the EuiButton */
                'data-test-id': 'configure-deployment-steps',
              }}
              paddingSize='xs'
            >
              <EuiSpacer size='l' />
              <ConfigureDeploymentSteps
                editorState={editorState}
                onChange={onChange}
                firstStepNumber={this.getSelectTemplateStepCount() + 1}
                region={region!}
                snapshotDetails={this.getSnapshotDetails()}
                showPrice={showPrice}
              />
            </EuiAccordion>
            <EuiSpacer size='l' />
          </Fragment>
        )}
      </EuiSplitPanel.Inner>
    )
  }

  renderFooter(showSettings: boolean) {
    const {
      createStackDeploymentRequest,
      claimInstantStackDeploymentRequest,
      editorState,
      region,
    } = this.props
    const { claimedInstantStackDeploymentId } = this.state

    const disableBottomNav = this.shouldDisableBottomNav()
    const { deploymentTemplate } = editorState
    return (
      <Sticky positionRecheckInterval={0.1} mode='bottom'>
        <EuiPanel
          hasShadow={false}
          className='create-deployment-footer'
          data-test-id='create-deployment-footer'
        >
          {this.renderSummaryArchitecture()}
          <EuiFlexGroup justifyContent='spaceBetween'>
            {showSettings && region && deploymentTemplate && (
              <EuiFlexItem grow={false}>{this.renderArchitectureButton()}</EuiFlexItem>
            )}
            <EuiFlexItem grow={false}>
              <CreateDeploymentButton
                disabled={
                  disableBottomNav ||
                  createStackDeploymentRequest.inProgress ||
                  claimInstantStackDeploymentRequest.inProgress
                }
                claimInstantStackDeployment={this.claimInstantStackDeployment}
                claimedInstantStackDeploymentId={claimedInstantStackDeploymentId}
                renderClaimStackDeploymentError={this.renderClaimStackDeploymentError}
                editorState={editorState}
                showApiRequest={true}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          {this.renderRequestErrors()}
        </EuiPanel>
      </Sticky>
    )
  }

  renderRequestErrors() {
    const { createStackDeploymentRequest, claimInstantStackDeploymentRequest } = this.props
    const createStackDeploymentRequestError = createStackDeploymentRequest.error
    const claimInstantStackDeploymentRequestError = claimInstantStackDeploymentRequest.error

    if (createStackDeploymentRequestError || claimInstantStackDeploymentRequestError) {
      return (
        <Fragment>
          <EuiSpacer />

          {createStackDeploymentRequestError && (
            <CuiAlert type='error'>{createStackDeploymentRequestError}</CuiAlert>
          )}
          {this.renderClaimStackDeploymentError(claimInstantStackDeploymentRequestError)}
        </Fragment>
      )
    }

    return null
  }

  renderClaimStackDeploymentError(error?: AsyncRequestError) {
    if (!error) {
      return null
    }

    if (!(error instanceof AjaxRequestError) || getResponseStatus(error) === 404) {
      return null
    }

    return <CuiAlert type='error'>{error}</CuiAlert>
  }

  renderArchitectureButton() {
    const { showPrice, region, editorState, profile } = this.props
    const { deploymentTemplate } = editorState
    const { showArchitectureSummary } = this.state

    if (showPrice && !inTrial({ profile })) {
      return (
        <PriceButton
          level={profile?.level}
          showArchitectureSummary={showArchitectureSummary}
          nodeConfigurations={this.getNodeConfigurations()}
          priceViewSelected={this.state.priceViewSelected}
          deploymentTemplate={deploymentTemplate!}
          regionId={region!.id}
          showPrice={showPrice}
          onChangePriceView={(id) => this.setState({ priceViewSelected: id })}
          onClickPriceButton={this.onClickPriceButton}
        />
      )
    }

    return (
      <div>
        <EuiButtonEmpty
          data-test-id='show-architecture-button'
          onClick={() =>
            this.setState({ showArchitectureSummary: !this.state.showArchitectureSummary })
          }
          iconSide='right'
          iconType={showArchitectureSummary ? 'arrowDown' : 'arrowUp'}
          size='s'
        >
          {showArchitectureSummary ? (
            <FormattedMessage
              id='architecture-button.hide-configuration'
              defaultMessage='Hide configuration'
            />
          ) : (
            <FormattedMessage
              id='architecture-button.show-configuration'
              defaultMessage='Show configuration'
            />
          )}
        </EuiButtonEmpty>
      </div>
    )
  }

  renderSummaryArchitecture() {
    const { editorState } = this.props
    const { showArchitectureSummary } = this.state
    const { regionId, deployment, deploymentTemplate } = editorState

    if (!deploymentTemplate || !regionId || !deployment) {
      return (
        <Fragment>
          <EuiSkeletonText />
          <EuiSpacer size='s' />
        </Fragment>
      )
    }

    const instanceConfigurations = deploymentTemplate!.instance_configurations
    const version = getUpsertVersion(editorState)

    return (
      <div
        className={cx({
          'deployment-architecture-summary-hidden': !showArchitectureSummary,
          'deployment-architecture-summary-visible': showArchitectureSummary,
        })}
      >
        <DeploymentArchitecture
          priceViewSelected={this.state.priceViewSelected}
          version={version!}
          regionId={regionId}
          autoscalingEnabled={getAutoscalingStatus({ deployment })}
          instanceConfigurations={instanceConfigurations}
          nodeConfigurations={this.getNodeConfigurations()}
        />

        <EuiHorizontalRule />
      </div>
    )
  }

  claimInstantStackDeployment = async (claimDeploymentRequestPayload) => {
    const { claimInstantStackDeployment, fetchDeployment } = this.props

    const claimedInstantStackDeploymentResponse = await claimInstantStackDeployment({
      payload: claimDeploymentRequestPayload,
    })
    const claimedDeploymentResponse: DeploymentClaimResponse =
      claimedInstantStackDeploymentResponse.payload
    const { deployment_id } = claimedDeploymentResponse

    this.setState({ claimedInstantStackDeploymentId: deployment_id })
    const fetchClaimedInstantStackDeploymentResponse = await fetchDeployment(deployment_id)
    const deployment: StackDeployment = fetchClaimedInstantStackDeploymentResponse.payload

    return deployment
  }

  onClickPriceButton = (): void => {
    this.setState({ showArchitectureSummary: !this.state.showArchitectureSummary })
  }

  onChangeSnapshotSource = (
    nextRestoreSnapshotSource?: DeploymentSearchResponse | null,
    regionId?: RegionId,
  ) => {
    const { onChange, editorState } = this.props

    if (nextRestoreSnapshotSource == null) {
      this.setState({ restoreSnapshotSource: null })
      changeRestoreFromSnapshot({ onChange, source: null })
      return
    }

    const disabledAutoscalingDeployment = setAutoscalingDisabled({
      deployment: editorState.deployment,
    })
    this.setState({ restoreSnapshotSource: nextRestoreSnapshotSource })

    // if we're restoring from a snapshot then we can't have autoscaling turned on, so we send the whole autoscaling turned off deployment
    changeRestoreFromSnapshot({
      onChange,
      regionId,
      source: nextRestoreSnapshotSource,
      baseDeployment: disabledAutoscalingDeployment,
    })
  }

  onChangeSnapshot = (nextSnapshot?: ClusterSnapshot | null) => {
    const { onChange } = this.props
    const { restoreSnapshotSource } = this.state
    changeRestoreFromSnapshot({
      onChange,
      source: restoreSnapshotSource,
      snapshotName: nextSnapshot ? nextSnapshot.snapshot : undefined,
    })
  }

  getNodeConfigurations(): AnyTopologyElement[] {
    const { editorState, stackVersions } = this.props

    const region = this.props.region!

    const payload = getCreatePayload({
      region,
      editorState,
      stackVersions,
    })

    return getDeploymentNodeConfigurations({ deployment: payload! })
  }

  updateEsSettings = (settings) => {
    const { onChange } = this.props
    setEsSettings({ onChange, settings })
  }

  shouldDisableBottomNav = (): boolean => {
    const { region, editorState, trialMaxedOut } = this.props
    const { deployment, regionId, _joltVersion } = editorState

    if (trialMaxedOut) {
      return true
    }

    if (
      deployment.settings?.observability &&
      !deployment.settings.observability.metrics &&
      !deployment.settings.observability.logging
    ) {
      return true
    }

    /*
     * A missing region — or a region mismatch — would indicate we haven't finished
     * fetching the currently selected region.
     */
    const actualRegionId = deployment && getRegionIdForCreate({ deployment })
    const missingRegion = !region || !regionId
    const regionMismatch = actualRegionId !== regionId

    if (missingRegion || regionMismatch) {
      return true
    }

    /*
     * `_joltVersion` is the currently selected version.
     * `actualVersion` comes from the deployment, based on the deployment template.
     * A mismatch means the template hasn't yet been fetched, and passed along
     * to the `editorState`.
     */
    const actualVersion = getUpsertVersion({ deployment, _fromJolt: false })
    const missingVersion = !_joltVersion || !actualVersion
    const versionMismatch = _joltVersion !== actualVersion

    if (missingVersion || versionMismatch) {
      return true
    }

    return false
  }

  getSelectTemplateStepCount() {
    const { showRegion } = this.props

    let count = 3 // name step, setup step, optimize step

    if (showRegion) {
      count += 2 // platform step, region step
    }

    return count // NOTE: pricing step doesn't count!
  }

  getSnapshotDetails() {
    const { restoreSnapshotSource } = this.state
    const { showRegion } = this.props
    const firstEs = restoreSnapshotSource
      ? getFirstEsClusterFromGet({ deployment: restoreSnapshotSource })
      : null
    const snapshotSourceRegionId = restoreSnapshotSource
      ? getRegionId({ deployment: restoreSnapshotSource })
      : null
    const latestSnapshotDate = firstEs ? getLatestSnapshotSuccess({ resource: firstEs }) : null
    return restoreSnapshotSource === null
      ? {}
      : {
          deploymentId: firstEs!.id,
          deploymentName: restoreSnapshotSource.name,
          regionId: showRegion ? snapshotSourceRegionId : null,
          snapshotDate: latestSnapshotDate || ``,
        }
  }
}

export default injectIntl(withLDConsumer()(CreateStackDeploymentEditor))
