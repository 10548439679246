/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage, defineMessage, defineMessages } from 'react-intl'
import React from 'react'

import {
  CustomRoleBadgeLabel,
  type ResourceType,
  type RoleLabel,
} from '@modules/role-assignments-lib/types'
import {
  customRoleBadgeLabels,
  deploymentRoleLabels,
  elasticsearchRoleLabels,
  observabilityRoleLabels,
  organizationRoleLabels,
  securityRoleLabels,
} from '@modules/role-assignments-lib/messages'

import type { RoleBadgeInfo } from '.'
import type { ReactNode } from 'react'
import type { MessageDescriptor } from 'react-intl'

export const roleBadgeLabels = defineMessages<RoleLabel>({
  ...organizationRoleLabels,
  ...deploymentRoleLabels,
  ...elasticsearchRoleLabels,
  ...observabilityRoleLabels,
  ...securityRoleLabels,
  ...customRoleBadgeLabels,
})

export const allInstancesRoleBadgeTooltipMessages = defineMessages<ResourceType>({
  elasticsearch: {
    id: 'role-badges.tooltip.all-elasticsearch-instances',
    defaultMessage: 'This role applies to all current and future Elasticsearch projects.',
  },
  observability: {
    id: 'role-badges.tooltip.all-observability-instances',
    defaultMessage: 'This role applies to all current and future Observability projects.',
  },
  security: {
    id: 'role-badges.tooltip.all-security-instances',
    defaultMessage: 'This role applies to all current and future Security projects.',
  },
  deployment: {
    id: 'role-badges.tooltip.all-hosted-deployments',
    defaultMessage: 'This role applies to all current and future hosted deployments.',
  },
})

export const specificInstancesRoleBadgeTooltipMessages = defineMessages<ResourceType>({
  elasticsearch: {
    id: 'role-badges.tooltip.elasticsearch',
    defaultMessage:
      'This role applies to {count} Elasticsearch {count, plural, one {project} other {projects}}.',
  },
  observability: {
    id: 'role-badges.tooltip.observability',
    defaultMessage:
      'This role applies to {count} Observability {count, plural, one {project} other {projects}}.',
  },
  security: {
    id: 'role-badges.tooltip.security',
    defaultMessage:
      'This role applies to {count} Security {count, plural, one {project} other {projects}}.',
  },
  deployment: {
    id: 'role-badges.tooltip.deployment',
    defaultMessage:
      'This role applies to {count} hosted {count, plural, one {deployment} other {deployments}}.',
  },
})

export const customRoleBadgeTooltipMessages = defineMessages<ResourceType>({
  elasticsearch: {
    id: 'custom-role-badges.tooltip.elasticsearch',
    defaultMessage:
      'Custom roles are applied to {count} Elasticsearch {count, plural, one {project} other {projects}}.',
  },
  observability: {
    id: 'custom-role-badges.tooltip.observability',
    defaultMessage:
      'Custom roles are applied to {count} Observability {count, plural, one {project} other {projects}}.',
  },
  security: {
    id: 'custom-role-badges.tooltip.security',
    defaultMessage:
      'Custom roles are applied to {count} Security {count, plural, one {project} other {projects}}.',
  },
  deployment: {
    id: 'role-badges.tooltip.deployment',
    defaultMessage:
      'This role applies to {count} hosted {count, plural, one {deployment} other {deployments}}.',
  },
})

export const getTooltipMessage = (
  roleBadgeInfo: RoleBadgeInfo,
): MessageDescriptor & {
  values?: Record<string, string | number>
} => {
  const { roleLabel, resourceType, count } = roleBadgeInfo

  const isRoleAppliedToAllInstances = count === 'all'

  // tooltip is not necessary if there is no instance type and all is not true
  if (!resourceType && !isRoleAppliedToAllInstances) {
    return { ...roleBadgeLabels[roleLabel] }
  }

  if (isRoleAppliedToAllInstances) {
    if (!resourceType) {
      return defineMessage({
        id: 'role-badges.tooltip.all-resource-types',
        defaultMessage: 'This role applies to all current and future instances.',
      })
    }

    return {
      ...allInstancesRoleBadgeTooltipMessages[resourceType],
    }
  }

  if (roleBadgeInfo.roleLabel === CustomRoleBadgeLabel) {
    return {
      ...customRoleBadgeTooltipMessages[resourceType || ''],
      values: {
        count: count || 0,
      },
    }
  }

  return {
    ...specificInstancesRoleBadgeTooltipMessages[resourceType || ''],
    values: {
      count: count || 0,
    },
  }
}

export const getCountLabel = (count?: number | 'all'): ReactNode => {
  if (count === 'all') {
    return <FormattedMessage id='role-badges.notification.all-instances' defaultMessage='All' />
  }

  return count
}
