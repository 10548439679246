/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { fetchAsJson } from '@modules/query/helpers'
import type { GetProjectMetricsParams, GetProjectMetricsResponse } from '@modules/autoops-api/types'

export const getProjectMetrics = ({ projectId, organizationId, stats }: GetProjectMetricsParams) =>
  fetchAsJson<GetProjectMetricsResponse>(
    `/autoops/api/monitoring/serverless/metrics/projects/${projectId}`,
    {
      method: 'POST',
      headers: {
        organizationId,
        deploymentId: projectId,
      },
      body: JSON.stringify({
        timeRange: {
          from: 1721736735830,
          to: 1721737635830,
        },
        stats,
        buckets: 20,
      }),
    },
  )
