/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'

import RegionControl from '@modules/pricing-list-components/Serverless/Filters/RegionControl'
import ProviderControl from '@modules/pricing-list-components/Serverless/Filters/ProviderControl'

import type { Props } from './types'
import type { FunctionComponent } from 'react'

const Filters: FunctionComponent<Props> = ({
  isLoading,
  providers,
  regions,
  provider,
  region,
  onSelectedProviderChange,
  onSelectedRegionChange,
}) => (
  <Fragment>
    <EuiFlexGroup>
      <EuiFlexItem>
        <ProviderControl
          provider={provider}
          providers={providers}
          isLoading={isLoading}
          onSelectProvider={(providerId) => onSelectedProviderChange(providerId)}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <RegionControl
          region={region}
          regions={regions}
          isLoading={isLoading}
          onSelectRegion={(regionId) => onSelectedRegionChange(regionId)}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer size='m' />
  </Fragment>
)

export default Filters
