/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton } from '@elastic/eui'

import { useIsProjectInitializing } from '@modules/project-user-lib/hooks/status'
import type { AnyUserProject } from '@modules/project-user-api/types'
import { makeSsoUrl } from '@modules/project-lib/kibanaLinks'
import { isProjectSuspended } from '@modules/project-lib/suspended'
import {
  useKibanaDeeplinkForCurrentResource,
  useOnboardingMetadataFromLocalStorage,
} from '@modules/discovery-questions-lib/hooks'

export type Props = {
  project: AnyUserProject
}

const OpenProjectButton: React.FunctionComponent<Props> = ({ project }) => {
  const isInitializing = useIsProjectInitializing(project)
  const isSuspended = isProjectSuspended(project)

  const [_, setOnboardingMetadata] = useOnboardingMetadataFromLocalStorage()
  const kibanaDeeplinkForCurrentProject = useKibanaDeeplinkForCurrentResource(project.id)

  const ssoUrl = makeSsoUrl(project, { kibanaDeepLink: kibanaDeeplinkForCurrentProject })

  return (
    <EuiButton
      fill={true}
      isDisabled={isInitializing || isSuspended}
      onClick={() => {
        if (ssoUrl) {
          if (kibanaDeeplinkForCurrentProject) {
            setOnboardingMetadata({})
          }

          window.location.href = ssoUrl
        }
      }}
    >
      <FormattedMessage id='project.openButton' defaultMessage='Open project' />
    </EuiButton>
  )
}

export default OpenProjectButton
