/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import genericMessages from '@modules/project-lib/genericMessages'
import type { AnyProject } from '@modules/project-api/types'
import { indexManagementUrl } from '@modules/project-lib/kibanaLinks'
import usePermissions from '@modules/permissions-lib/hooks'

export type Props = {
  project: AnyProject
}

const DataRetention: React.FunctionComponent<Props> = ({ project }) => {
  const { hasPermissions: hasUpdatePermission } = usePermissions([
    { type: `project-${project.type}`, action: 'update', id: project.id },
  ])

  const [isEditing, setIsEditing] = useState(false)
  const { formatMessage } = useIntl()
  const dataRetentionLabel = formatMessage(genericMessages.dataRetention)

  return (
    <EuiPanel data-test-subj='editDataRetention' paddingSize='l' hasBorder={true}>
      <EuiFlexGroup gutterSize='xl' alignItems='stretch'>
        <EuiFlexItem grow={1}>
          <div style={{ marginRight: '2rem' }}>{renderDescription()}</div>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiFlexGroup direction='column' justifyContent='spaceBetween'>
            {renderDisplay()}
            {renderControl()}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )

  function renderDescription() {
    return (
      <EuiFlexGroup alignItems='baseline'>
        <EuiFlexItem grow={false} style={{ alignSelf: 'flex-start' }}>
          <EuiPanel paddingSize='m' hasBorder={true}>
            <EuiIcon type='storage' color='primary' size='xl' />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiTitle size='xs'>
            <h3>{dataRetentionLabel}</h3>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiText size='s' color='subdued'>
            <p>
              <FormattedMessage
                id='project.dataRetention.description.1'
                defaultMessage='
                <strong>Data Retention</strong> policies, determine how long your data is retained in the Search AI Lake.'
                values={{ strong: (content) => <strong>{content}</strong> }}
              />
            </p>
            {isEditing && (
              <Fragment>
                <p>
                  <FormattedMessage
                    id='project.dataRetention.description.2'
                    defaultMessage='
                    The Search AI Lake holds <strong>all the data</strong> that you store and access through your project.'
                    values={{ strong: (content) => <strong>{content}</strong> }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id='project.dataRetention.description.3'
                    defaultMessage='You can specify different retention periods for each data stream configured in
                  your project.'
                  />
                </p>
                <p>
                  <FormattedMessage
                    id='project.dataRetention.description.4'
                    defaultMessage='Go to <link>Data Streams</link> to configure data retention.'
                    values={{
                      link: (content) => (
                        <EuiLink href={indexManagementUrl(project)}>{content}</EuiLink>
                      ),
                    }}
                  />
                </p>
              </Fragment>
            )}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  function renderDisplay() {
    const linkLabel = formatMessage(isEditing ? genericMessages.close : genericMessages.edit)
    return (
      <EuiFlexItem grow={false}>
        <EuiSpacer size='xs' />
        <EuiFlexGroup
          responsive={false}
          gutterSize='m'
          justifyContent='flexEnd'
          alignItems='baseline'
          style={{ whiteSpace: 'nowrap' }}
        >
          {hasUpdatePermission && (
            <EuiFlexItem grow={false}>
              <EuiLink
                onClick={toggle}
                aria-label={formatMessage(genericMessages.linkActionAriaLabel, {
                  action: linkLabel,
                  name: dataRetentionLabel,
                })}
              >
                {linkLabel}
              </EuiLink>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  function renderControl() {
    if (isEditing) {
      return (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize='m' justifyContent='flexEnd' alignItems='baseline'>
            <EuiFlexItem grow={false}>
              <EuiButton size='s' color='primary' fill={true} href={indexManagementUrl(project)}>
                <FormattedMessage {...genericMessages.configure} />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      )
    }

    return null
  }

  function toggle() {
    setIsEditing(!isEditing)
  }
}

export default DataRetention
