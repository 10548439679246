/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import { EuiCallOut, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'

import { AjaxRequestError } from '@modules/ui-types'
import type { AsyncRequestError } from '@modules/ui-types'
import { extractEmailFromError, getErrorCode, getMessages } from '@modules/utils/error'
import { CuiLink } from '@modules/cui/Link'

import { supportUrl } from '@/lib/urlBuilder'

import { passwordErrorMessages } from '../../PasswordField/errorMessages'

import type { AllProps as Props } from './types'

const messages = defineMessages({
  emailDisposableMailgun: {
    id: `registration-page.email-error-mailgun`,
    defaultMessage: `The provided email address appears to be invalid.`,
  },
  emailMisspellingMailgun: {
    id: `registration-page.email-misspelling-mailgun`,
    defaultMessage: `The provided email address appears to be invalid. Check for typos or misspellings and try again.`,
  },
  userAlreadyExist: {
    id: 'registration-page.user-already-exist',
    defaultMessage:
      '{email} is already associated with an account. Please use another email address.',
  },
  internalUser: {
    id: 'registration-page.internal-user',
    defaultMessage: 'User activation is not allowed. Please use Google Sign-In.',
  },
  userAlreadyExistMarketplace: {
    id: 'registration-page.user-already-exist-marketplace',
    defaultMessage:
      '{email} is already associated with an account. To continue, enter another email address to create an account.',
  },
  internalUserMarketplace: {
    id: 'registration-page.internal-user-marketplace',
    defaultMessage: 'Marketplace activation is not allowed for internal user.',
  },
  activateAccount: {
    id: 'registration-page.staged-user-activate',
    defaultMessage: 'Activate your account.',
  },
  internalServerErrorMessage: {
    id: 'create-account-form.internal-server-error-message',
    defaultMessage: `Unable to create account due to a technical error. Please try again. If the issue persists, please contact us at `,
  },
})

class CreateAccountFormErrorCallout extends PureComponent<Props> {
  render() {
    const {
      intl: { formatMessage },
      createUserRequest,
      createMarketplaceUserRequest,
      createPendingSaasUserRequest,
      captchaError,
      stageActivation,
    } = this.props

    if (captchaError) {
      return (
        <Fragment>
          <EuiSpacer size='m' />
          {captchaError}
        </Fragment>
      )
    }

    if (!createUserRequest.error && !createMarketplaceUserRequest.error) {
      return null
    }

    // Create User Errors
    const { responseStatus, errorDetails, errorCode, didYouMean, message } = this.getErrorDetails(
      createUserRequest.error,
    )
    const isInvalidCaptchaError = errorCode?.includes(`invalid_captcha`)
    const isRejectedCaptchaError = errorCode === `user.registration.captcha_rejected`

    if (responseStatus >= 400 && responseStatus < 500) {
      const errorMsg = passwordErrorMessages[errorDetails.hint]

      if (errorMsg) {
        return this.renderErrorCalloutComponent({ content: formatMessage(errorMsg) })
      }

      if (errorDetails.detail) {
        return this.renderErrorCalloutComponent({ content: errorDetails.detail })
      }

      if (errorCode === `user.invalid_email`) {
        if (createPendingSaasUserRequest.isDone) {
          return (
            <Fragment>
              <EuiSpacer size='m' />
              <EuiCallOut title={formatMessage(messages.activateAccount)}>
                <EuiText size='s'>
                  <FormattedMessage
                    id='set-password.check-email'
                    defaultMessage='Check your email to activate the account'
                  />
                </EuiText>
              </EuiCallOut>
              <EuiSpacer size='m' />
            </Fragment>
          )
        }

        return this.renderErrorCalloutComponent({
          content: formatMessage(messages.emailMisspellingMailgun),
          details: didYouMean,
          activate: !!stageActivation,
          color: 'warning',
        })
      }

      if (errorCode === `user.is_disposable`) {
        return this.renderErrorCalloutComponent({
          content: formatMessage(messages.emailDisposableMailgun),
          details: didYouMean,
        })
      }

      if (errorCode === `user.activation_not_allowed`) {
        return this.renderErrorCalloutComponent({
          content: formatMessage(messages.internalUser),
        })
      }

      if (createUserRequest?.error instanceof Error && errorCode === `user.already_exists`) {
        const message = getMessages(createUserRequest.error)
        const email = extractEmailFromError(message)

        return this.renderErrorCalloutComponent({
          content: formatMessage(messages.userAlreadyExist, { email }),
        })
      }
    }

    // what are you?
    if (errorDetails && errorDetails.msg) {
      return this.renderErrorCalloutComponent({ content: errorDetails.msg })
    }

    if (responseStatus >= 500 || isInvalidCaptchaError || isRejectedCaptchaError) {
      return this.renderErrorCalloutComponent({
        content: formatMessage(messages.internalServerErrorMessage),
        support: true,
      })
    }

    if (message) {
      return this.renderErrorCalloutComponent({ content: message })
    }

    // Marketplace error
    const {
      errorCode: marketplaceErrorCode,
      message: marketplaceMessage,
      errorDetails: marketplaceErrorDetail,
    } = this.getErrorDetails(createMarketplaceUserRequest.error)

    if (marketplaceErrorCode === `user.activation_not_allowed`) {
      return this.renderErrorCalloutComponent({
        content: formatMessage(messages.internalUserMarketplace),
        color: 'danger',
      })
    }

    if (marketplaceErrorCode === `user.already_exists`) {
      const email = extractEmailFromError(marketplaceMessage)

      return this.renderErrorCalloutComponent({
        content: formatMessage(messages.userAlreadyExistMarketplace, { email }),
        color: 'danger',
      })
    }

    if (marketplaceErrorDetail?.detail) {
      return this.renderErrorCalloutComponent({
        content: marketplaceErrorDetail.detail,
        color: 'danger',
      })
    }

    return null
  }

  renderErrorCalloutComponent({
    content,
    details,
    color = 'danger',
    activate,
    support,
  }: {
    content: string
    details?: string
    color?: 'primary' | 'success' | 'warning' | 'danger'
    activate?: boolean
    support?: boolean
  }) {
    const { stageActivation } = this.props
    return (
      <Fragment>
        <EuiSpacer size='m' />
        <EuiCallOut
          data-test-id='set-password-failed'
          title={<FormattedMessage id='registration.failed' defaultMessage='Registration error' />}
          color={color}
          iconType='alert'
        >
          {content}
          {details && (
            <EuiText size='s'>
              <FormattedMessage
                id='set-password.failed-meaning'
                defaultMessage='Did you mean {mean}?'
                values={{
                  mean: details,
                }}
              />
            </EuiText>
          )}
          {activate && (
            <EuiText size='s'>
              <FormattedMessage
                id='cloud-signup.error-button-label'
                defaultMessage='You can also {verify} address to activate your account.'
                values={{
                  verify: (
                    <EuiLink
                      data-test-subj='cloud-signup.force-email-verification'
                      onClick={stageActivation}
                    >
                      <FormattedMessage
                        id='cloud-signup.error-button-verify'
                        defaultMessage='verify your email'
                      />
                    </EuiLink>
                  ),
                }}
              />
            </EuiText>
          )}
          {support && (
            <EuiText size='s'>
              <FormattedMessage
                id='create-account-form.internal-server-error-contact-support'
                defaultMessage='{support}.'
                values={{
                  support: (
                    <CuiLink to={supportUrl()}>
                      <FormattedMessage
                        id='create-account-form.internal-server-error-message-support'
                        defaultMessage='support@elastic.co'
                      />
                    </CuiLink>
                  ),
                }}
              />
            </EuiText>
          )}
        </EuiCallOut>
        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  getErrorDetails(error: AsyncRequestError | undefined) {
    if (!(error instanceof AjaxRequestError)) {
      return {
        responseStatus: 0,
        errorDetails: undefined,
        errorCode: undefined,
        didYouMean: undefined,
        message: error,
      }
    }

    const responseStatus = error.response?.status ?? null
    const errorDetails = error.body ?? {}
    const errorCode = getErrorCode(error)

    const subError =
      Array.isArray(errorDetails.errors) && errorDetails.errors.length > 0
        ? errorDetails.errors[0]
        : undefined
    const didYouMean = subError ? subError.context?.did_you_mean : undefined
    const message = subError ? subError.message : undefined

    return {
      responseStatus,
      errorDetails,
      errorCode,
      didYouMean,
      message,
    }
  }
}

export default injectIntl(CreateAccountFormErrorCallout)
