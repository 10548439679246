/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { map } from 'lodash'

import {
  EuiTitle,
  EuiBadge,
  EuiSpacer,
  EuiButtonIcon,
  EuiFlexItem,
  EuiFlexGroup,
  EuiBetaBadge,
} from '@elastic/eui'

import type {
  DeploymentCreateRequest,
  DeploymentResources,
  DeploymentUpdateRequest,
  ElasticsearchClusterTopologyElement,
  InstanceConfiguration,
} from '@modules/cloud-api/v1/types'
import type { AnyTopologyElement, NodeRole } from '@modules/ui-types'

import {
  sanitizeNodeRolesForDisplay,
  getNodeRoles,
} from '@/lib/stackDeployments/selectors/nodeRoles'
import {
  isAutoscaleableTier,
  displayAutoscalingLimitReached,
} from '@/lib/stackDeployments/selectors/autoscaling'
import { getUpsertVersion } from '@/lib/stackDeployments/selectors/creates'
import { getNodeAttributes } from '@/lib/stackDeployments/selectors/topologyElements'
import { getFirstEsCluster } from '@/lib/stackDeployments/selectors/stackDeployment'

import { canDisableConfiguration } from '../../../../../../lib/deployments/architecture'
import { getVersion } from '../../../../../../reducers/clusters'

import InstanceInfoPopover from './InstanceInfoPopover'
import NormalizeSizing from './NormalizeSizing'
import { TopologyElementDescription, getTopologyTitle } from './helpers'

import type { ReactElement } from 'react'
import type { NormalizeSizingProps } from './NormalizeSizing'
import type { WrappedComponentProps } from 'react-intl'

export type AllProps = {
  deployment: DeploymentCreateRequest | DeploymentUpdateRequest
  topologyElement: AnyTopologyElement
  templateTopologyElement?: AnyTopologyElement
  instanceConfiguration: InstanceConfiguration
  onChange: undefined | ((path: string[], value: any) => void) // path is scoped to the topology element
  isAutoscalingEnabled?: boolean
  dedicatedMasterThreshold?: number
  maxNodeCountForEnvironment?: number
  capMaxNodeCount?: boolean
  betaBadges?: JSX.Element[] // to override the default beta badges if needed
}

type Props = AllProps & WrappedComponentProps

type State = {
  isTitlePopoverOpen: boolean
}

const messages = defineMessages({
  hardware: {
    id: 'instance-info-popover.hardware',
    defaultMessage: 'Hardware',
  },
  description: {
    id: 'instance-info-popover.description',
    defaultMessage: 'Description',
  },
  roles: {
    id: 'instance-info-popover.roles',
    defaultMessage: 'Roles',
  },
  autoscaling: {
    id: 'instance-info-popover.autoscaling',
    defaultMessage: 'Autoscaling',
  },
  nodeAttributes: {
    id: 'instance-info-popover.nodeAttributes',
    defaultMessage: 'Node attributes',
  },
  removeAriaLabel: {
    id: 'instance-info-popover.remove.aria-label',
    defaultMessage: 'Remove "{item}"',
  },
})

class Heading extends Component<Props, State> {
  state = {
    isTitlePopoverOpen: false,
  }

  render(): JSX.Element {
    const {
      intl: { formatMessage },
      topologyElement,
      templateTopologyElement,
      instanceConfiguration,
      deployment,
      onChange,
      dedicatedMasterThreshold,
      isAutoscalingEnabled,
      betaBadges,
    } = this.props

    const { isTitlePopoverOpen } = this.state

    const configVersion = instanceConfiguration.config_version
    const icName = instanceConfiguration.name || ``
    const icTitle = `${icName}${configVersion !== undefined ? ` (version ${configVersion})` : ``}`

    const listItems = [
      {
        title: formatMessage(messages.hardware),
        description: icTitle,
        dataTestId: `instance-info-popover-hardware`,
      },
      {
        title: formatMessage(messages.description),
        description: instanceConfiguration.description || ``,
      },
      {
        title: formatMessage(messages.roles),
        description: this.renderBadges(),
      },
    ]

    const nodeAttributes = getNodeAttributes({ topologyElement })

    if (nodeAttributes) {
      listItems.push({
        title: formatMessage(messages.nodeAttributes),
        description: this.renderNodeAttributes(nodeAttributes),
      })
    }

    const version = getUpsertVersion({ deployment })

    const showDisableButton =
      onChange &&
      canDisableConfiguration({
        topologyElement,
        sliderInstanceType: instanceConfiguration.instance_type,
        dedicatedMasterThreshold,
      }) &&
      (!isAutoscalingEnabled || !isAutoscaleableTier({ topologyElement, version }))

    const title = getTopologyTitle(
      templateTopologyElement || topologyElement,
      instanceConfiguration,
      version,
      formatMessage,
    )

    return (
      <Fragment>
        <EuiFlexGroup responsive={false} alignItems='center' gutterSize='s'>
          <EuiFlexItem>
            <EuiFlexGroup gutterSize='s' responsive={false} alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiTitle size='xxs'>
                  <h4>{title}</h4>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem
                data-test-id='info-instance-popover'
                data-id={instanceConfiguration.name}
                grow={false}
              >
                <InstanceInfoPopover
                  listItems={listItems}
                  onClick={() => this.onClickInfoPopover()}
                  isOpen={isTitlePopoverOpen}
                  onClose={() => this.onClickInfoPopover()}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {betaBadges ||
              (this.displayAutoscalingSettings() && (
                <EuiFlexGroup gutterSize='s'>
                  <EuiFlexItem grow={false}>
                    <EuiBetaBadge
                      data-test-id='autoscaling-badge'
                      label={formatMessage(messages.autoscaling)}
                    />
                  </EuiFlexItem>
                  <NormalizeSizing {...this.props} autoscalingSizeFilter='constrainToLimits'>
                    {this.renderAutoscalingLimitWarning}
                  </NormalizeSizing>
                </EuiFlexGroup>
              ))}
          </EuiFlexItem>
          {showDisableButton && (
            <NormalizeSizing {...this.props} autoscalingSizeFilter='constrainToLimits'>
              {({ onChangeSize }) => (
                <EuiFlexItem grow={false}>
                  <EuiButtonIcon
                    data-test-id='topologyElement-remove'
                    iconType='cross'
                    color='danger'
                    onClick={onChangeSize && (() => onChangeSize(0))}
                    aria-label={formatMessage(messages.removeAriaLabel, { item: title })}
                  />
                </EuiFlexItem>
              )}
            </NormalizeSizing>
          )}
        </EuiFlexGroup>

        <TopologyElementDescription
          topologyElement={templateTopologyElement || topologyElement}
          instanceConfiguration={instanceConfiguration}
          version={version}
        />
      </Fragment>
    )
  }

  renderAutoscalingLimitWarning({
    size,
    autoscalingMaxSize,
    autoscalingMinSize,
  }: NormalizeSizingProps): ReactElement | null {
    if (!autoscalingMaxSize) {
      return null
    }

    const limitReached = displayAutoscalingLimitReached({
      size,
      autoscalingMax: autoscalingMaxSize,
      autoscalingMin: autoscalingMinSize,
    })

    if (!limitReached) {
      return null
    }

    return (
      <EuiFlexItem grow={false}>
        <EuiBetaBadge
          className='autoscaling-limit-reached'
          data-test-id='autoscaling-limit-reached'
          color='accent'
          // style={{ display: 'inherit' }} // to avoid built-in bottom margin that pushes it up too high
          label={
            <FormattedMessage
              id='autoscaling-edit-settings.limit-reached'
              defaultMessage='Limit Reached'
            />
          }
          tooltipContent={
            <FormattedMessage
              id='autoscaling-edit-settings.limit-reached.tooltip'
              defaultMessage='To continue experiencing optimal performance, we recommend increasing your maximum size per zone.'
            />
          }
        />
      </EuiFlexItem>
    )
  }

  renderBadges(): JSX.Element {
    const { instanceConfiguration, deployment } = this.props

    // not all topology elements have node types but we're duck-typing that property below
    const topologyElement = this.props.topologyElement as ElasticsearchClusterTopologyElement

    const esResource = getFirstEsCluster({ deployment })!
    const version = getVersion(esResource)

    const currentInstanceType = instanceConfiguration.instance_type
      ? [instanceConfiguration.instance_type]
      : []

    // the fallbacks here are to still render a badge named for the instance, if the instance has no node types
    const badgeStrings: Array<NodeRole | keyof DeploymentResources | 'coordinating'> =
      instanceConfiguration.instance_type === `elasticsearch`
        ? getNodeRoles({ topologyElement, version })
        : currentInstanceType

    const addCoordinatingBadge =
      instanceConfiguration.instance_type === `elasticsearch` && badgeStrings.includes(`ingest`)

    if (addCoordinatingBadge) {
      badgeStrings.splice(badgeStrings.indexOf(`ingest`), 0, `coordinating`)
    }

    const badges = sanitizeNodeRolesForDisplay(badgeStrings).map((nodeType, i) => (
      <EuiFlexItem key={i} grow={false}>
        <EuiBadge
          data-test-subj='topologyElement-badge'
          data-id={nodeType}
          key={nodeType}
          color='hollow'
        >
          {nodeType}
        </EuiBadge>
      </EuiFlexItem>
    ))

    return (
      <Fragment>
        <EuiSpacer size='s' />
        <EuiFlexGroup responsive={false} wrap={true} gutterSize='xs'>
          {badges}
        </EuiFlexGroup>
      </Fragment>
    )
  }

  renderNodeAttributes(nodeAttributes: Record<string, string>): JSX.Element {
    const badges = map(nodeAttributes, (v, k) => (
      <EuiFlexItem key={k} grow={false}>
        <EuiBadge data-test-subj='nodeAttribute-badge' data-id={k} key={k} color='hollow'>
          {k}: {v}
        </EuiBadge>
      </EuiFlexItem>
    ))

    return (
      <Fragment>
        <EuiSpacer size='s' />
        <EuiFlexGroup responsive={false} wrap={true} gutterSize='xs'>
          {badges}
        </EuiFlexGroup>
      </Fragment>
    )
  }

  onClickInfoPopover() {
    this.setState({ isTitlePopoverOpen: !this.state.isTitlePopoverOpen })
  }

  closeInfoPopOver() {
    this.setState({ isTitlePopoverOpen: false })
  }

  displayAutoscalingSettings(): boolean {
    const { isAutoscalingEnabled, topologyElement, deployment } = this.props
    const version = getUpsertVersion({ deployment }) || undefined

    return Boolean(isAutoscalingEnabled) && isAutoscaleableTier({ topologyElement, version })
  }
}

export default injectIntl(Heading)
