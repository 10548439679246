/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { useParams } from 'react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import { capitalize } from 'lodash'
import { css } from '@emotion/react'

import {
  EuiButton,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiSplitPanel,
  EuiTitle,
} from '@elastic/eui'

import { makeSsoUrl } from '@modules/project-lib/kibanaLinks'
import { getGetProjectQueryHook } from '@modules/project-user-lib/hooks/get'
import type { UrlParams } from '@modules/project-creation-components/types'
import { useIsProjectInitializing } from '@modules/project-user-lib/hooks/status'
import { isProjectType } from '@modules/project-creation-lib/types'
import VideoPlayer from '@modules/VideoPlayer'
import ProjectCreationContainer from '@modules/project-creation-components/ProjectCreationContainer'
import { getThemeVariables } from '@modules/cui/theme'
import { projectCreationCrumbs } from '@modules/project-creation-lib/breadcrumbs'
import Breadcrumbs from '@modules/cui/Breadcrumbs/Breadcrumbs'
import { getVideoBySolution } from '@modules/project-user-lib/util'

import { getSolutionContent } from '../messages'

import type { FunctionComponent } from 'react'

const GettingStarted: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const { id, solution } = useParams<UrlParams>()

  const getProjectQuery = getGetProjectQueryHook(
    isProjectType(solution) ? solution : 'elasticsearch', // unconditional hook sanity
  )(id)
  const isInitializing = useIsProjectInitializing(getProjectQuery.data, false)

  if (!id || !solution || !isProjectType(solution)) {
    return null
  }

  const { title, label } = getSolutionContent(solution)
  const {
    euiBreakpoints: { m: euiBreakpointM },
  } = getThemeVariables()

  const continueButtonAriaLabel = isInitializing
    ? formatMessage({
        id: 'starting-project-component.fullymanaged-creating.aria-label',
        defaultMessage: 'Creating project',
      })
    : formatMessage({
        id: 'starting-project-component.fullymanaged-done.aria-label',
        defaultMessage: 'Continue to project',
      })

  return (
    <Fragment>
      <Breadcrumbs breadcrumbs={projectCreationCrumbs(solution)} />

      <ProjectCreationContainer step={3}>
        <Fragment>
          <EuiSplitPanel.Outer
            grow={true}
            css={css`
              width: 781px;

              @media only screen and (max-width: ${euiBreakpointM}) {
                width: 100%;
              }
            `}
          >
            <EuiSplitPanel.Inner paddingSize='m'>
              <EuiFlexGroup alignItems='stretch' direction='row' gutterSize='s' aria-live='polite'>
                <EuiFlexItem grow={true}>
                  <EuiFlexGroup alignItems='center' direction='row' gutterSize='s'>
                    <EuiIcon type={`logo${capitalize(label)}`} size='xl' />
                    <EuiTitle data-test-subj={`project-creating-title-${solution}`} size='s'>
                      <h2>
                        <FormattedMessage {...title} />
                      </h2>
                    </EuiTitle>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    isLoading={isInitializing}
                    href={makeSsoUrl(getProjectQuery.data)}
                    aria-label={continueButtonAriaLabel}
                  >
                    {isInitializing ? (
                      <FormattedMessage
                        id='starting-project-component.fullymanaged-creating'
                        defaultMessage='Creating project...'
                      />
                    ) : (
                      <FormattedMessage
                        id='starting-project-component.fullymanaged-done'
                        defaultMessage='Continue'
                      />
                    )}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup direction='column' gutterSize='none'>
                <EuiHorizontalRule margin='m' />
                <EuiFlexItem>
                  {isInitializing ? (
                    <EuiFlexItem>
                      <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem>
                          <FormattedMessage
                            id='starting-project-component.fullymanaged-hangon'
                            defaultMessage='Hang tight. This will take just a few minutes. Learn more about Elastic in the meantime.'
                          />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  ) : (
                    <FormattedMessage
                      id='starting-project-component.fullymanaged-ready'
                      defaultMessage="You're all set! Let's get started."
                    />
                  )}
                </EuiFlexItem>
                <EuiFlexItem grow={true}>
                  <EuiSpacer size='m' />
                  <VideoPlayer uuid={getVideoBySolution(solution)} />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiSplitPanel.Inner>
          </EuiSplitPanel.Outer>
        </Fragment>
      </ProjectCreationContainer>
    </Fragment>
  )
}

export default GettingStarted
