/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { css, jsx } from '@emotion/react'

import {
  EuiPopover,
  EuiStat,
  EuiPanel,
  EuiText,
  EuiSpacer,
  EuiProgress,
  EuiButtonIcon,
} from '@elastic/eui'

import type { CloudAppName } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import { CuiLink } from '@modules/cui/Link'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import { organizationBillingSubscriptionUrl } from '@modules/urls/organizations'
import withBillingDetails from '@modules/billing-lib/billingDetails/withBillingDetails'
import {
  getMarketplaceDisplayName,
  isMarketplaceCustomer,
} from '@modules/billing-lib/billingDetails/utils'

import { getConfigForKey } from '@/store'
import lightTheme from '@/lib/theme/light'
import Percentage from '@/lib/Percentage'
import { billingSubscriptionUrl } from '@/apps/userconsole/urls'

import { getCurrentMonthDates } from '../UsageCardV2/utils'
import UsageStat from '../UsageStat'
import messagesBillingOverview from '../../messages'

import messages from './messages'
import { getActiveBalance } from './utils'

import type { WrappedComponentProps } from 'react-intl'
import type { AllProps } from './types'

interface State {
  showBalancePopover: boolean
  showInitialBalancePopover: boolean
}

class PrepaidConsumptionOverviewCard extends Component<AllProps & WrappedComponentProps, State> {
  state = {
    showBalancePopover: false,
    showInitialBalancePopover: false,
  }

  componentDidMount() {
    const { fetchAccountCostsOverviewIfNeeded } = this.props

    fetchAccountCostsOverviewIfNeeded(getCurrentMonthDates())
  }

  render() {
    return (
      <EuiPanel hasShadow={false} paddingSize='l' hasBorder={true} borderRadius='m' grow={false}>
        <EuiSpacer size='xl' />

        <EuiText size='m'>
          <h6>
            <FormattedMessage {...messages.plan} />
          </h6>
        </EuiText>

        <EuiText size='s' css={css({ display: 'flex', alignItems: 'center' })}>
          <h2 data-test-id='balance-overview-card.title'>
            <FormattedMessage {...messages.prepaidBalance} />
          </h2>
          {this.renderBalancePopover()}
        </EuiText>

        {this.renderMarketplaceInfo()}

        <EuiSpacer size='l' />

        {this.renderBalanceOverviewContent()}

        <EuiSpacer size='xl' />
      </EuiPanel>
    )
  }

  renderMarketplaceInfo = (): JSX.Element | null => {
    const { billingDetails } = this.props

    if (billingDetails.data && isMarketplaceCustomer(billingDetails.data)) {
      const channel = billingDetails.data.channel

      return (
        <EuiText size='s'>
          <FormattedMessage
            id='billing-overview.overview-card.marketplace-info'
            defaultMessage={`Billed through {channel} account`}
            values={{
              channel: getMarketplaceDisplayName(channel),
            }}
          />
        </EuiText>
      )
    }

    return null
  }

  renderBalanceOverviewContent = (): JSX.Element => {
    const {
      costOverviewCurrentMonth,
      costOverviewCurrentMonthRequest: { inProgress, error },
    } = this.props

    const { available, remaining } = getActiveBalance(costOverviewCurrentMonth?.balance?.line_items)

    if (error) {
      return (
        <CuiAlert type='error' data-test-id='error-message'>
          <FormattedMessage {...messagesBillingOverview.genericErrorMessage} />
        </CuiAlert>
      )
    }

    return (
      <div data-test-id='balance-overview-card'>
        <EuiStat
          isLoading={inProgress}
          title={
            <UsageStat
              title={
                <CuiElasticConsumptionUnits value={remaining} unit='none' withSymbol={false} />
              }
              subText={<FormattedMessage {...messages.ecuUnit} />}
              data-test-id='balance-overview-card.remaining-balance'
            />
          }
          titleSize='l'
          description={
            <EuiText size='s'>
              <FormattedMessage {...messages.remainingBalance} />
            </EuiText>
          }
          descriptionElement='div'
        />

        <EuiSpacer size='s' />

        {!inProgress && (
          <EuiProgress
            color='primary'
            value={remaining}
            max={available}
            size='l'
            css={css({ maxWidth: 234 })}
            label={
              <EuiText size='xs' color={lightTheme.euiColorPrimary}>
                <FormattedMessage
                  {...messages.remaining}
                  values={{
                    remainingValue: (
                      <Percentage value={available === 0 ? 0 : remaining / available} />
                    ),
                  }}
                />
              </EuiText>
            }
          />
        )}

        <EuiSpacer size='xl' />

        {inProgress && <EuiSpacer size='xl' />}

        <EuiStat
          isLoading={inProgress}
          title={
            <UsageStat
              title={
                <CuiElasticConsumptionUnits value={available} unit='none' withSymbol={false} />
              }
              subText={<FormattedMessage {...messages.ecuUnit} />}
              data-test-id='balance-overview-card.initial-balance'
            />
          }
          titleSize='xs'
          description={
            <EuiText size='s'>
              <FormattedMessage {...messages.initialBalance} /> {this.renderInitialBalancePopover()}
            </EuiText>
          }
          descriptionElement='div'
        />
      </div>
    )
  }

  renderBalancePopover = (): JSX.Element => {
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <EuiPopover
        button={
          <EuiButtonIcon
            onClick={this.toggleBalancePopover}
            css={css({ marginLeft: 8 })}
            data-test-id='balance-overview-card.balance-popover-button'
            iconType='questionInCircle'
            display='empty'
            aria-label={formatMessage(messages.popoverActiveBalanceAriaLabel)}
          />
        }
        isOpen={this.state.showBalancePopover}
        closePopover={this.toggleBalancePopover}
      >
        <EuiText
          css={css({ width: 300 })}
          data-test-id='balance-overview-card.balance-popover-content'
        >
          <FormattedMessage
            {...messages.balancePopover}
            values={{
              subscriptionLink: (content) => (
                <CuiLink to={this.getSubscriptionLink()}>{content}</CuiLink>
              ),
            }}
          />
        </EuiText>
      </EuiPopover>
    )
  }

  renderInitialBalancePopover = (): JSX.Element => {
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <EuiPopover
        button={
          <EuiButtonIcon
            onClick={this.toggleInitialBalancePopover}
            data-test-id='balance-overview-card.initial-balance-popover-button'
            iconType='questionInCircle'
            display='empty'
            aria-label={formatMessage(messages.popoverStartingBalanceAriaLabel)}
          />
        }
        isOpen={this.state.showInitialBalancePopover}
        closePopover={this.toggleInitialBalancePopover}
      >
        <EuiText
          className='popoverContent'
          data-test-id='balance-overview-card.initial-balance-popover-content'
        >
          <FormattedMessage
            {...messages.initialBalancePopover}
            values={{
              subscriptionLink: (content) => (
                <CuiLink to={this.getSubscriptionLink()}>{content}</CuiLink>
              ),
            }}
          />
        </EuiText>
      </EuiPopover>
    )
  }

  getSubscriptionLink = (): string => {
    const { organizationId } = this.props
    const appName: CloudAppName = getConfigForKey(`APP_NAME`) || 'userconsole' // fallback to 'userconsole' just in case

    const subscriptionLink = {
      userconsole: billingSubscriptionUrl(),
      adminconsole: organizationBillingSubscriptionUrl(organizationId),
    }[appName]

    return subscriptionLink
  }

  toggleBalancePopover = (): void => {
    const { showBalancePopover } = this.state

    this.setState({ showBalancePopover: !showBalancePopover })
  }

  toggleInitialBalancePopover = (): void => {
    const { showInitialBalancePopover } = this.state

    this.setState({ showInitialBalancePopover: !showInitialBalancePopover })
  }
}

export default injectIntl(withBillingDetails(PrepaidConsumptionOverviewCard))
