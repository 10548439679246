/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import type { EuiSuperSelectProps } from '@elastic/eui'
import { EuiFlexGroup, EuiFlexItem, EuiFormLabel, EuiIcon, EuiSuperSelect } from '@elastic/eui'

import { getProviderInfoById } from '@modules/project-lib/providers'
import type { ProviderInfo } from '@modules/project-lib/providers'
import type { CSP } from '@modules/cluster-user-api/v1/types'

import type { FunctionComponent } from 'react'

type Props = {
  value: string | undefined
  availableProviders: string[]
  onChange: (value: string) => void
  showPrepend?: boolean
} & Omit<EuiSuperSelectProps<string>, 'fullWidth' | 'valueOfSelected' | 'options'>

const ProviderSelector: FunctionComponent<Props> = ({
  value,
  availableProviders,
  onChange,
  showPrepend = true,
  ...props
}) => {
  const providerOptions = availableProviders
    .map((key) => getProviderInfoById(key as CSP))
    .map((provider) => ({
      inputDisplay: renderProviderOption(provider),
      value: provider.id,
    }))

  function renderProviderOption(provider: ProviderInfo) {
    return (
      <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
        <EuiFlexItem grow={false}>
          <EuiIcon size='m' type={provider.iconType} />
        </EuiFlexItem>
        <EuiFlexItem>{provider.title}</EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <EuiSuperSelect
      fullWidth={true}
      prepend={
        showPrepend ? (
          <EuiFormLabel style={{ width: '150px' }}>
            <FormattedMessage id='provider-selector.label' defaultMessage='Cloud provider' />
          </EuiFormLabel>
        ) : undefined
      }
      options={providerOptions}
      valueOfSelected={value}
      onChange={(provider) => onChange(provider)}
      {...props}
    />
  )
}

export default ProviderSelector
