/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiFormLabel, EuiFormControlLayout, EuiComboBox, EuiToolTip } from '@elastic/eui'

import type { Region } from '@modules/cloud-api/v1/types'
import { withErrorBoundary } from '@modules/cui/Boundary'
import { getRegionDisplayName } from '@modules/utils/region'

import type { WrappedComponentProps } from 'react-intl'

import './selectRegion.scss'

interface Props extends WrappedComponentProps {
  regionId: string | null
  availableRegions: Region[] | null
  onChange: (regionId: string) => void
  restoreFromSnapshot: boolean
  loading?: boolean
  disabled?: boolean
  prepend?: boolean
  'aria-labelledby'?: string
}

class SelectRegion extends Component<Props> {
  render() {
    const {
      restoreFromSnapshot,
      prepend = true,
      intl: { formatMessage },
    } = this.props
    const label = formatMessage({
      id: 'select-region-label',
      defaultMessage: 'Region',
    })

    return (
      <Fragment>
        <EuiFormControlLayout
          fullWidth={true}
          prepend={
            prepend ? <EuiFormLabel style={{ width: `180px` }}>{label}</EuiFormLabel> : undefined
          }
        >
          {restoreFromSnapshot ? (
            <EuiToolTip
              anchorClassName='region-disabled-tooltip'
              position='top'
              content={
                <FormattedMessage
                  defaultMessage='You cannot change the region as you are restoring data from a snapshot.'
                  id='select-region-disabled-tooltip'
                />
              }
            >
              {this.renderComboBox({ 'aria-label': label })}
            </EuiToolTip>
          ) : (
            this.renderComboBox({ 'aria-label': label })
          )}
        </EuiFormControlLayout>
      </Fragment>
    )
  }

  renderComboBox(props: { ['aria-label']?: string }) {
    const { regionId, availableRegions, onChange, restoreFromSnapshot, loading, disabled } =
      this.props
    const regionOptions =
      availableRegions?.map((region) => ({
        label: getRegionDisplayName({ region }),
        value: region.identifier,
      })) ?? []

    const selectedValue = availableRegions?.find((region) => region.identifier === regionId)

    const selectedName = getRegionDisplayName({ region: selectedValue })
    const isDisabled = disabled || restoreFromSnapshot

    return (
      <EuiComboBox
        fullWidth={true}
        options={regionOptions}
        selectedOptions={[{ label: selectedName }]}
        onChange={(newRegion) => onChange(newRegion[0]!.value!)}
        singleSelection={{ asPlainText: true }}
        isDisabled={isDisabled}
        isClearable={false}
        isLoading={loading}
        data-test-id='region-combobox'
        aria-label={this.props['aria-labelledby'] ?? props['aria-label']}
      />
    )
  }
}

export default withErrorBoundary(injectIntl(SelectRegion))
