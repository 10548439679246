/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import {
  getElasticsearchProject,
  getElasticsearchProjectRoles,
  getObservabilityProject,
  getObservabilityProjectRoles,
  getSecurityProject,
  getSecurityProjectRoles,
} from '@modules/project-user-api/v1/callers'
import type { ProjectID } from '@modules/project-user-api/v1/types'
import type { QueryHook } from '@modules/query/types'
import type { ProjectApiError, ProjectType } from '@modules/ui-types/projects'
import type { ResourceType } from '@modules/role-assignments-lib/types'

import { makeQueryKey } from './helpers'

/**
 * Returns the appropriate hook for the provided project type.
 */
export function getGetProjectQueryHook(
  projectType: ProjectType,
):
  | typeof useGetElasticsearchProjectQuery
  | typeof useGetObservabilityProjectQuery
  | typeof useGetSecurityProjectQuery {
  switch (projectType) {
    case 'observability':
      return useGetObservabilityProjectQuery
    case 'security':
      return useGetSecurityProjectQuery
    default:
      return useGetElasticsearchProjectQuery
  }
}

const useGetElasticsearchProjectQuery: QueryHook<
  typeof getElasticsearchProject,
  ProjectID,
  ProjectApiError
> = (id: ProjectID) =>
  useQuery(makeQueryKey('getElasticsearchProject', id), () =>
    getElasticsearchProject({
      pathParameters: { id },
    }),
  )

const useGetObservabilityProjectQuery: QueryHook<
  typeof getObservabilityProject,
  ProjectID,
  ProjectApiError
> = (id: ProjectID) =>
  useQuery(makeQueryKey('getObservabilityProject', id), () =>
    getObservabilityProject({
      pathParameters: { id },
    }),
  )

const useGetSecurityProjectQuery: QueryHook<
  typeof getSecurityProject,
  ProjectID,
  ProjectApiError
> = (id: ProjectID) =>
  useQuery(makeQueryKey('getSecurityProject', id), () =>
    getSecurityProject({
      pathParameters: { id },
    }),
  )

export function useGetProjectRolesQueryHook({
  projectId,
  resourceType,
  enabled,
}: {
  projectId: string
  resourceType: ResourceType
  enabled: boolean
}): { data?: unknown; isLoading: boolean } {
  const elasticsearchQuery = useGetElasticsearchProjectRolesQuery(projectId, {
    enabled: enabled && resourceType === 'elasticsearch',
  })
  const observabilityQuery = useGetObservabilityProjectRolesQuery(projectId, {
    enabled: enabled && resourceType === 'observability',
  })
  const securityQuery = useGetSecurityProjectRolesQuery(projectId, {
    enabled: enabled && resourceType === 'security',
  })

  switch (resourceType) {
    case 'observability':
      return { data: observabilityQuery.data, isLoading: observabilityQuery.isLoading }
    case 'security':
      return { data: securityQuery.data, isLoading: securityQuery.isLoading }
    case 'elasticsearch':
      return { data: elasticsearchQuery.data, isLoading: elasticsearchQuery.isLoading }
    default:
      return { data: {}, isLoading: false }
  }
}

const useGetElasticsearchProjectRolesQuery: QueryHook<typeof getElasticsearchProjectRoles> = (
  id: ProjectID,
  options?: { enabled?: boolean },
) =>
  useQuery(
    makeQueryKey('getElasticsearchProjectRoles', id),
    () =>
      getElasticsearchProjectRoles({
        pathParameters: { id },
      }),
    options,
  )

const useGetObservabilityProjectRolesQuery: QueryHook<typeof getObservabilityProjectRoles> = (
  id: ProjectID,
  options?: { enabled?: boolean },
) =>
  useQuery(
    makeQueryKey('getObservabilityProjectRoles', id),
    () =>
      getObservabilityProjectRoles({
        pathParameters: { id },
      }),
    { enabled: options?.enabled ?? true },
  )

const useGetSecurityProjectRolesQuery: QueryHook<typeof getSecurityProjectRoles> = (
  id: ProjectID,
  options?: { enabled?: boolean },
) =>
  useQuery(
    makeQueryKey('getSecurityProjectRoles', id),
    () =>
      getSecurityProjectRoles({
        pathParameters: { id },
      }),
    { enabled: options?.enabled ?? true },
  )
