/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { withErrorBoundary } from '@modules/cui/Boundary'

import {
  isPendingAttempt,
  getPlanBeforeAttempt,
} from '@/lib/stackDeployments/selectors/configurationChangeAttempts'
import { autoscalingStatusOnGet } from '@/lib/stackDeployments/selectors/autoscaling'

import explainChanges from './explainChanges'

import type { AllProps } from './types'

class PlanAttemptChangeExplain extends Component<AllProps> {
  componentDidMount() {
    this.props.fetchInstanceConfigurations()
  }

  render() {
    const {
      sliderInstanceType,
      resource,
      planAttempt,
      planToCompareWith,
      instanceConfigurations,
      pruneOrphans,
      deployment,
    } = this.props

    if (!instanceConfigurations) {
      return null
    }

    const newPlan = planAttempt.plan

    if (!newPlan) {
      return null
    }

    const source = planAttempt.source
    const oldPlan = planToCompareWith || getPlanBeforeAttempt({ resource, planAttempt }) || null
    const isPastHistory = !isPendingAttempt({ planAttempt })

    const explanations = explainChanges({
      sliderInstanceType,
      resource,
      source,
      newPlan,
      oldPlan,
      instanceConfigurations,
      pruneOrphans,
      isPastHistory,
      autoscalingStatus: deployment ? autoscalingStatusOnGet({ deployment }) : `none`,
    })

    if (explanations.length === 0) {
      return null
    }

    return (
      <div>
        {explanations.map((explanation) => (
          <EuiFlexGroup key={explanation.id} gutterSize='s'>
            <EuiFlexItem grow={false}>
              <div
                data-test-id={explanation['data-test-id']}
                data-test-explanation-id={explanation.id}
                data-test-params={explanation.testParams}
              >
                {explanation.message}
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        ))}
      </div>
    )
  }
}

export default withErrorBoundary(PlanAttemptChangeExplain)
