/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { groupBy } from 'lodash'

import { OnboardingToken } from '@modules/discovery-questions-lib/steps'

import type { SelectTemplateArgShape } from './types'

type TemplateCategoryId = 'cpu-optimized' | 'general-purpose' | 'vector-search-optimized'

type HardwareProfilePreference = {
  preferredProfileId: TemplateCategoryId
  fallbackProfileId?: TemplateCategoryId
}

const MapeableOnboardingTokens = [
  OnboardingToken.VectorSearch,
  OnboardingToken.GeneralPurpose,
  OnboardingToken.Connectors,
  OnboardingToken.Crawlers,
] as const

type MapeableOnboardingToken = (typeof MapeableOnboardingTokens)[number]

const HARDWARE_PROFILE_MAPPING: Record<MapeableOnboardingToken, HardwareProfilePreference> = {
  [OnboardingToken.VectorSearch]: {
    preferredProfileId: 'vector-search-optimized',
    fallbackProfileId: 'cpu-optimized',
  },

  [OnboardingToken.GeneralPurpose]: {
    preferredProfileId: 'general-purpose',
  },

  [OnboardingToken.Connectors]: {
    preferredProfileId: 'cpu-optimized',
  },

  [OnboardingToken.Crawlers]: {
    preferredProfileId: 'cpu-optimized',
  },
}

export function getHardwareProfilePreference(
  onboardingToken: MapeableOnboardingToken,
): HardwareProfilePreference {
  return HARDWARE_PROFILE_MAPPING[onboardingToken]
}

export const isMapeableOnboardingToken = (
  onboardingToken: OnboardingToken | undefined,
): onboardingToken is MapeableOnboardingToken =>
  MapeableOnboardingTokens.includes(onboardingToken as MapeableOnboardingToken)

export function selectTemplateByOnboardingToken(
  onboardingToken: OnboardingToken | undefined,
  fallbackFn: (shape: SelectTemplateArgShape) => void,
): (shape: SelectTemplateArgShape) => void {
  if (!isMapeableOnboardingToken(onboardingToken)) {
    return fallbackFn
  }

  return (shape: SelectTemplateArgShape) => {
    const {
      deploymentTemplate,
      deploymentTemplates,
      setDeploymentTemplate,
      regionChanged,
      versionChanged,
    } = shape

    function shouldSkipUpdateDeploymentTemplate(foundDeploymentTemplate: { id: string }): boolean {
      return (
        (deploymentTemplate &&
          deploymentTemplate.id === foundDeploymentTemplate.id &&
          !regionChanged &&
          !versionChanged) ??
        false
      )
    }

    if (!deploymentTemplates?.length) {
      return
    }

    const { preferredProfileId, fallbackProfileId } = getHardwareProfilePreference(onboardingToken)

    const deploymentTemplatesByTemplateCategoryId = groupBy(
      deploymentTemplates,
      'template_category_id',
    )

    const [preferredHardwareProfile] =
      deploymentTemplatesByTemplateCategoryId[preferredProfileId] ?? []

    if (preferredHardwareProfile) {
      if (shouldSkipUpdateDeploymentTemplate(preferredHardwareProfile)) {
        return
      }

      setDeploymentTemplate(preferredHardwareProfile)
    }

    const [fallbackHardwareProfile] =
      (fallbackProfileId && deploymentTemplatesByTemplateCategoryId[fallbackProfileId]) ?? []

    if (fallbackHardwareProfile) {
      if (shouldSkipUpdateDeploymentTemplate(fallbackHardwareProfile)) {
        return
      }

      setDeploymentTemplate(fallbackHardwareProfile)
    }
  }
}
