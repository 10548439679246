/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import genericMessages from '@modules/mfa-lib/genericMessages'
import type { useVerifySaasCurrentUserMfaChallengeMutation } from '@modules/cloud-lib/users/hooks/mfa'

const AuthenticatorForm: React.FunctionComponent<{
  verifyMutation: ReturnType<typeof useVerifySaasCurrentUserMfaChallengeMutation>
  onSubmit: (passCode: string) => void
}> = ({ verifyMutation, onSubmit }) => {
  const { formatMessage } = useIntl()

  const [value, setValue] = useState('')

  const title = (
    <EuiTitle size='m'>
      <h1 style={{ textAlign: 'center' }}>
        <FormattedMessage
          id='mfaLogin.authenticatorForm.title'
          defaultMessage='Check your authenticator app for a code'
        />
      </h1>
    </EuiTitle>
  )

  const instructions = (
    <EuiText size='s' textAlign='center' style={{ maxWidth: '20rem', margin: '0 auto' }}>
      <FormattedMessage
        id='mfaLogin.authenticatorForm.instructions'
        defaultMessage='Enter the 6-digit code from your registered authenticator app.'
      />
    </EuiText>
  )

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()

        onSubmit(value)
      }}
    >
      <EuiFlexGroup direction='column' alignItems='center'>
        <EuiFlexItem>{title}</EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction='column' alignItems='stretch' style={{ maxWidth: '24rem' }}>
            <EuiFlexItem>{instructions}</EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow
                hasChildLabel={false}
                isInvalid={verifyMutation.isError}
                error={
                  verifyMutation.isError && [
                    <FormattedMessage
                      id='mfaLogin.authenticatorForm.codeError'
                      defaultMessage='This code may be incorrect or expired. Try again.'
                    />,
                  ]
                }
              >
                <EuiFieldText
                  aria-label={formatMessage(genericMessages.passCode)}
                  autoFocus={true}
                  autoComplete='off'
                  icon='lock'
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value)
                  }}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton type='submit' fill={true}>
                <FormattedMessage {...genericMessages.verifyCode} />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </form>
  )
}

export default AuthenticatorForm
