/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { EuiFlexItem, EuiSpacer } from '@elastic/eui'

import { createDeploymentUrl } from '@modules/cloud-api/v1/urls'
import type { DeploymentCreateRequest, DeploymentCreateResponse } from '@modules/cloud-api/v1/types'
import { getResponseStatus } from '@modules/utils/ajax'
import { AjaxRequestError } from '@modules/ui-types'
import { getUserUsecase } from '@modules/profile-lib'
import { isTrialUser } from '@modules/billing-lib/utils'
import { CuiAlert } from '@modules/cui/Alert'

import { getCreatePayload, getClaimDeploymentRequestPayload } from '@/lib/stackDeployments/crud'
import { getFirstSliderClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import { kibanaGettingStartedUrl } from '@/lib/serviceProviderDeepLinks'

import ApiRequestExample from '../../../ApiRequestExample'
import SpinButton from '../../../SpinButton'

import type { ReactElement } from 'react'
import type { AllProps as Props } from './types'

type State = {
  byokError: boolean
}

class CreateDeploymentButton extends Component<Props, State> {
  static defaultProps = {
    filterIngestPlugins: false,
  }

  state = {
    byokError: false,
  }

  componentWillUnmount(): void {
    const { resetCreateDeployment } = this.props
    resetCreateDeployment()
  }

  render(): ReactElement {
    const {
      disabled,
      createStackDeploymentRequest,
      fetchStackDeploymentRequest,
      showApiRequest,
      profile,
    } = this.props
    const displayApiRequestLink = !disabled && showApiRequest
    const isTrial = profile && isTrialUser(profile)
    const deploymentRequestProgress =
      createStackDeploymentRequest.inProgress || // creating deployment
      (this.claimInstantDeploymentProgress() && !this.createStackDeploymentErrors()) ||
      fetchStackDeploymentRequest.inProgress // fetching claimed deployment

    return (
      <EuiFlexItem style={{ textAlign: 'center' }} grow={false}>
        <div>
          <SpinButton
            data-test-id='submit-create-deployment'
            className='create-deployment-action-button'
            fill={true}
            disabled={disabled}
            onClick={this.onClickCreate}
            spin={deploymentRequestProgress}
          >
            <FormattedMessage
              id='create-deployment-from-template.create-deployment'
              defaultMessage='Create deployment'
            />
          </SpinButton>

          {this.state.byokError && (
            <Fragment>
              <EuiSpacer size='xs' />
              <CuiAlert type='danger' data-test-id='create-deployment-validation-error'>
                <FormattedMessage
                  id='create-deployment-button.error'
                  defaultMessage='A key must be provided for encryption at rest'
                />
              </CuiAlert>
            </Fragment>
          )}

          {displayApiRequestLink && !isTrial && (
            <ApiRequestExample
              method='POST'
              endpoint={createDeploymentUrl()}
              body={this.getCreatePayload()}
            />
          )}
        </div>
      </EuiFlexItem>
    )
  }

  createStackDeploymentErrors() {
    const { createStackDeploymentRequest, fetchStackDeploymentRequest } = this.props

    return (
      createStackDeploymentRequest.error || // creating deployment
      fetchStackDeploymentRequest.error // fetching claimed deployment
    )
  }

  claimInstantDeploymentProgress() {
    const { claimInstantStackDeploymentRequest } = this.props

    return (
      claimInstantStackDeploymentRequest.inProgress || // claiming instant deployment
      claimInstantStackDeploymentRequest.isDone || // instant deployment claimed
      getResponseStatus(claimInstantStackDeploymentRequest.error) === 404 // instant deployment not found, switch to create deployment
    )
  }

  onClickCreate = (): void => {
    const { profile, flags } = this.props
    const isTrial = profile && isTrialUser(profile)

    if (isTrial && flags?.claimInstantDeployment) {
      this.claimInstantDeployment()
      return
    }

    this.createDeployment()
  }

  getCreatePayload(): DeploymentCreateRequest {
    const { editorState, filterIngestPlugins, stackVersions } = this.props
    const region = this.props.region!

    const payload = getCreatePayload({
      region,
      editorState,
      filterIngestPlugins,
      stackVersions,
    })

    return payload!
  }

  claimInstantDeployment = async () => {
    const { claimInstantStackDeployment, flags, profile, renderClaimStackDeploymentError } =
      this.props
    const createDeploymentPayload = this.getCreatePayload()
    const claimDeploymentRequestPayload = getClaimDeploymentRequestPayload({
      deployment: createDeploymentPayload,
    })

    if (claimDeploymentRequestPayload) {
      try {
        const deployment = await claimInstantStackDeployment(claimDeploymentRequestPayload)
        const kibanaResource = getFirstSliderClusterFromGet({
          deployment,
          sliderInstanceType: `kibana`,
        })
        const discoveryUseCase = getUserUsecase(profile!)
        const kibanaLinkUrl = kibanaGettingStartedUrl({
          resource: kibanaResource,
          showGuidedOnboardingPage: flags?.guidedOnboarding === true,
          discoveryUseCase: discoveryUseCase ? discoveryUseCase : undefined,
        })

        if (kibanaLinkUrl) {
          window.location.replace(kibanaLinkUrl)
        }
      } catch (err) {
        if (err instanceof AjaxRequestError) {
          // NO standby deployment is available, so create a deployment - we rely on other errors being rendered elsewhere
          if (getResponseStatus(err) === 404) {
            this.createDeployment()
            return
          }

          renderClaimStackDeploymentError(err)
        }
      }
    }
  }

  validateCreateDeployment = (createDeploymentPayload): boolean => {
    const { isByokToggleEnabled } = this.props

    if (isByokToggleEnabled && createDeploymentPayload.settings?.byok === undefined) {
      this.setState({ byokError: true })
      return false
    }

    this.setState({ byokError: false })

    return true
  }

  createDeployment = () => {
    const {
      createDeployment,
      redirectToStackGettingStarted,
      profile,
      fetchProfile,
      isUserconsole,
    } = this.props

    const createDeploymentPayload = this.getCreatePayload()

    if (!this.validateCreateDeployment(createDeploymentPayload)) {
      return false
    }

    return createDeployment({
      deployment: createDeploymentPayload,
      profile,
    }).then(createdDeployment)

    function createdDeployment(actionResult) {
      if (actionResult.error || !actionResult.payload) {
        return // we rely on the error being rendered elsewhere
      }

      if (isUserconsole) {
        fetchProfile()
      }

      const response: DeploymentCreateResponse = actionResult.payload
      const { id } = response

      redirectToStackGettingStarted(id)
    }
  }
}

export default withLDConsumer()(CreateDeploymentButton)
