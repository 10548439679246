/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'
import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import { asAjaxRequestError } from '@modules/utils/ajax'
import history from '@modules/utils/history'

import LandingPageContainer from '../../../../../components/LandingPageContainer'

import AccountVerified from './AccountVerified'
import FetchingDetails from './FetchingDetails'
import InvalidToken from './InvalidToken'
import VerificationFailed from './VerificationFailed'
import images from './images'

import type { Props } from './types'
import type { ReactElement, ReactNode } from 'react'
import type { InvitationSource } from '../../AcceptInvitation/types'

import './passwordUpdateViaEmailLink.scss'

enum ViewState {
  INIT = 'INIT',
  VERIFYING_ACCOUNT = 'VERIFYING_ACCOUNT',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  SET_PASSWORD = 'SET_PASSWORD',
  VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS',
}

interface State {
  viewState: ViewState
}

const {
  verifyEmailDark,
  verifyEmailLight,
  supportDark,
  supportLight,
  communityDark,
  communityLight,
} = images

class PasswordUpdateViaEmailLink extends Component<Props, State> {
  state: State = {
    viewState: ViewState.INIT,
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    const {
      activate,
      email,
      expires,
      requiresAccountVerification,
      verificationHash,
      verifyAccount,
      verifyAccountRequest,
    } = nextProps

    const { viewState } = prevState

    if (!requiresAccountVerification) {
      return null
    }

    if (viewState === ViewState.INIT) {
      if (
        verifyAccountRequest.inProgress ||
        verifyAccountRequest.isDone ||
        verifyAccountRequest.error
      ) {
        return null
      }

      // If our parameters are absent then someone has accessed a bad URL
      // Redirect since there's nothing else to be done.
      if (!email || !expires) {
        history.replace(`/`)
        return null
      }

      if (verificationHash) {
        verifyAccount(email, expires, verificationHash)
        return { viewState: ViewState.VERIFYING_ACCOUNT }
      }

      // If our parameters are absent then someone has accessed a bad URL
      // Redirect since there's nothing else to be done.
      history.replace(`/`)
      return null
    }

    if (viewState === ViewState.VERIFYING_ACCOUNT) {
      if (verifyAccountRequest.error) {
        return { viewState: ViewState.VERIFICATION_FAILED }
      }

      if (!verifyAccountRequest.isDone) {
        return null
      }

      if (activate) {
        // set password for old registration
        return { viewState: ViewState.SET_PASSWORD }
      }

      return { viewState: ViewState.VERIFICATION_SUCCESS }
    }

    return null
  }

  render(): ReactNode {
    const { requiresAccountVerification } = this.props

    if (!requiresAccountVerification) {
      return this.renderForm()
    }

    return this.renderAccountVerificationContent()
  }

  renderAccountVerificationContent(): ReactNode | null {
    const { verifyAccountRequest, email } = this.props
    const { viewState } = this.state
    const image = this.getStaticImage()
    const title = this.renderTitle()

    switch (viewState) {
      case ViewState.INIT:
      case ViewState.VERIFYING_ACCOUNT:
        return <FetchingDetails title={title} {...image} />

      case ViewState.VERIFICATION_FAILED: // this state implies email is not null (from getDerivedStateFromProps)
        return (
          email && (
            <VerificationFailed
              title={title}
              verifyAccountRequest={verifyAccountRequest}
              email={email}
              {...image}
            />
          )
        )

      case ViewState.SET_PASSWORD:
        return this.renderForm()

      case ViewState.VERIFICATION_SUCCESS:
        return <AccountVerified {...image} />

      default:
        return null
    }
  }

  renderTitle(): ReactElement {
    const { title } = this.props

    if (!title) {
      return (
        <FormattedMessage
          id='password-update-via-email.title'
          defaultMessage='Welcome to Elastic Cloud'
        />
      )
    }

    return title
  }

  renderForm(): ReactElement {
    const { children, pageContext, onPageContextChange, isFlowV2 } = this.props
    const tokenInvalid = this.isInvalidToken()

    const content = !tokenInvalid && children

    if (isFlowV2) {
      return (
        <LandingPageOuterContainer
          pageContext={pageContext}
          onPageContextChange={onPageContextChange}
          isFlowV2={isFlowV2}
        >
          <LandingPageInnerContainer
            title={this.renderTitle()}
            subtitle={tokenInvalid && <InvalidToken />}
            isFlowV2={isFlowV2}
          >
            {content}
          </LandingPageInnerContainer>
        </LandingPageOuterContainer>
      )
    }

    return (
      <LandingPageContainer
        pageContext={pageContext}
        onPageContextChange={onPageContextChange}
        title={this.renderTitle()}
        subtitle={tokenInvalid && <InvalidToken />}
        {...this.getStaticImage()}
        panelProps={{
          hasShadow: false,
          className:
            'cloud-landing-page-panel cloud-landing-page-form-panel email-verification-page',
          color: 'transparent',
        }}
      >
        {content}
      </LandingPageContainer>
    )
  }

  isInvalidToken(): boolean {
    const { setInitialPasswordRequest } = this.props
    const errorCode = asAjaxRequestError(setInitialPasswordRequest.error)?.body.errors?.[0]?.code

    return errorCode === 'user.token_expired' || errorCode === 'user.token_already_used'
  }

  getStaticImage(): { image: string; darkImage: string } | null {
    const { source } = this.props
    const { viewState } = this.state

    if ((source as InvitationSource) === 'organization-invite') {
      return null
    }

    const sourceContextImage = this.getSourceContextImage()

    return {
      image:
        viewState === ViewState.VERIFICATION_SUCCESS ? verifyEmailLight : sourceContextImage.image,
      darkImage:
        viewState === ViewState.VERIFICATION_SUCCESS
          ? verifyEmailDark
          : sourceContextImage.darkImage,
    }
  }

  getSourceContextImage(): { image: string; darkImage: string } {
    const { source, withWelcomeImage } = this.props

    if (withWelcomeImage) {
      if (source === `training`) {
        return {
          image: verifyEmailLight,
          darkImage: verifyEmailDark,
        }
      }

      if (source === `support`) {
        return {
          image: supportLight,
          darkImage: supportDark,
        }
      }

      if (source && source.startsWith('community')) {
        return {
          image: communityLight,
          darkImage: communityDark,
        }
      }
    }

    return {
      image: verifyEmailLight,
      darkImage: verifyEmailDark,
    }
  }
}

export default PasswordUpdateViaEmailLink
