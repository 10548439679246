/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { CuiThemedIcon } from '@modules/cui/ThemedIcon'
import { CuiRouterLinkButton } from '@modules/cui/RouterButton'
import { loginUrl } from '@modules/auth/urls'

import elasticLogo from '../images/elastic-logo-H-color.svg'
import elasticLogoDark from '../images/elastic-logo-H-color-dark.svg'

import type { FunctionComponent } from 'react'

const Header: FunctionComponent = () => (
  <Fragment>
    <EuiFlexGroup alignItems='center' justifyContent='spaceBetween' responsive={false}>
      <EuiFlexItem grow={true}>
        <Link to='/' style={{ width: '110px' }}>
          <CuiThemedIcon size='original' lightType={elasticLogo} darkType={elasticLogoDark} />
        </Link>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup
          gutterSize='m'
          alignItems='flexEnd'
          justifyContent='flexEnd'
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <CuiRouterLinkButton data-test-id='pricing-list.login-link' to={loginUrl}>
              <FormattedMessage id='pricing-list.login' defaultMessage='Login' />
            </CuiRouterLinkButton>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <CuiRouterLinkButton
              fill={true}
              data-test-id='pricing-list.try-free-link'
              to='/register'
            >
              <FormattedMessage id='pricing-list.try-free' defaultMessage='Try free' />
            </CuiRouterLinkButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  </Fragment>
)

export default Header
