/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { getElasticsearchMetricsUrl } from '@modules/cloud-api/v1/urls'

import asyncRequest from '../../../../actions/asyncRequests'
import { FETCH_METRICS } from '../../constants/actions'

export function fetchMetrics({
  regionId,
  stackDeploymentId,
  refId,
}: {
  regionId: string
  stackDeploymentId: string
  refId: string
}) {
  const url = getElasticsearchMetricsUrl({
    deploymentId: stackDeploymentId,
    refId,
  })

  return asyncRequest({
    type: FETCH_METRICS,
    url,
    meta: { regionId, stackDeploymentId },
    crumbs: [regionId, stackDeploymentId],
    includeHeaders: true,
  })
}
