/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { parse } from 'query-string'

import Feature from '@modules/utils/feature'

import { resendEmailVerificationLinkRequest } from '@/apps/userconsole/reducers/registry'
import { resendEmailVerificationLink } from '@/apps/userconsole/actions/account'
import { createSaasActivatedUser, createSaasUser } from '@/actions/saasUsers'
import { loginAndRedirect } from '@/actions/auth'
import { getConfigForKey, isFeatureActivated } from '@/selectors'
import { getRegistrationSource } from '@/lib/urlUtils'
import { resetLoginRequest } from '@/actions/auth/auth'
import { createSaasUserRequest, getLoginRequest } from '@/reducers/asyncRequests/registry'

import UserRegistration from './UserRegistration'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { DispatchProps, ConsumerProps, StateProps } from './types'

const mapStateToProps = (state: ReduxState, { location }: ConsumerProps): StateProps => {
  const { search } = location
  const query = parse(location.search.slice(1))

  return {
    downloads: String(query.downloads) === `true`,
    source: getRegistrationSource(search),
    locationQueryString: search,
    isGovCloud: isFeatureActivated(state, Feature.hideIrrelevantSectionsFromGovCloud),
    googleTrackingIdV4Enabled: getConfigForKey(state, `GOOGLE_ANALYTICS_TRACKING_ID_V4`) !== null,
    resendEmailVerificationLinkRequest: resendEmailVerificationLinkRequest(state),
    loginRequest: getLoginRequest(state),
    registrationRequest: createSaasUserRequest(state),
    isLaunchDarklyActivated: Boolean(isFeatureActivated(state, Feature.launchdarklyId)),
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  resetLoginRequest: () => dispatch(resetLoginRequest()),
  createUser: (user) => dispatch(createSaasUser(user)),
  createSaasActivatedUser: (user) => dispatch(createSaasActivatedUser(user)),
  loginAndRedirect: ({ email, password, redirectTo, oktaRedirectUrl }) =>
    dispatch(loginAndRedirect({ oktaRedirectUrl, redirectTo, credentials: { email, password } })),
  resendEmailVerificationLink: (email) => dispatch(resendEmailVerificationLink(email)),
})

export default withRouter(
  withLDConsumer()(
    connect<StateProps, DispatchProps, ConsumerProps>(
      mapStateToProps,
      mapDispatchToProps,
    )(UserRegistration),
  ),
)
