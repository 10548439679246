/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedDate, FormattedMessage, injectIntl } from 'react-intl'
import { partition } from 'lodash'

import {
  EuiDescriptionList,
  EuiFormControlLayout,
  EuiFormLabel,
  EuiSpacer,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiText,
  EuiButtonIcon,
} from '@elastic/eui'

import type {
  DeploymentsSearchResponse,
  DeploymentSearchResponse,
} from '@modules/cloud-api/v1/types'
import type {
  AsyncRequestState,
  RestoreSnapshot,
  VersionNumber,
  ClusterSnapshot,
  RegionId,
  StackDeployment,
} from '@modules/ui-types'
import { getPlatform } from '@modules/utils/platform'

import { DeploymentPicker, getDeploymentPickerLabel } from '@/components/DeploymentPicker'
import {
  getRegionId,
  getFirstEsClusterFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'
import {
  getLatestSnapshotSuccess,
  filterSearchableSnapshots,
} from '@/lib/stackDeployments/selectors/snapshots'
import { getDeploymentTemplateId } from '@/lib/stackDeployments/selectors/deploymentTemplates'

import sortOn from '../../../../../../../lib/sortOn'
import DocLink from '../../../../../../DocLink'

import SelectSnapshot from './SelectSnapshot'
import EnterSnapshot from './EnterSnapshot'
import RestoreFromSnapshot from './RestoreFromSnapshot'

import type { IntlShape } from 'react-intl'

import './selectSnapshotSource.scss'

const messages = defineMessages({
  deploymentsWithSnapshots: {
    id: 'select-snapshot-source.deployments-with-snapshots',
    defaultMessage: 'Deployments with snapshots',
  },
  deploymentsWithoutSnapshots: {
    id: 'select-snapshot-source.deployments-without-snapshots',
    defaultMessage: 'Deployments without snapshots',
  },
})

type Props = {
  intl: IntlShape
  deploymentSearchResults: DeploymentsSearchResponse | null
  fetchSnapshots: (params: { deployment: StackDeployment }) => void
  asRestoreForm: boolean
  fetchSnapshotsRequest: (deploymentId: string) => AsyncRequestState
  forceLastSnapshot: boolean
  getClusterSnapshots: (deploymentId) => ClusterSnapshot[] | null | undefined
  onRestoreSnapshot?: () => any
  onSelectSnapshot: (snapshot: ClusterSnapshot | null, id: string) => void
  onUpdateIndexRestore?: (payload: unknown) => void
  regionId: string | null
  restoreSnapshotRequest?: AsyncRequestState
  searchDeploymentsRequest: AsyncRequestState
  showRegion: boolean
  snapshotRestoreSettings: RestoreSnapshot | undefined
  version: VersionNumber
  onUpdateSnapshotSource: (source?: DeploymentSearchResponse | null) => void
  getRegionName: (regionId: RegionId) => string
  searchDeployments: (userInput: string) => void
  onChangeMaintMode?: (value: boolean) => void
  maintModeValue?: boolean
}

type State = {
  selectedDeployment: DeploymentSearchResponse | null
  showSnapshotRestoreHelp: boolean
}

type Option = {
  label: string
  value: DeploymentSearchResponse
  disabled: boolean
}

class SelectSnapshotSource extends Component<Props, State> {
  state: State = {
    selectedDeployment: null,
    showSnapshotRestoreHelp: false,
  }

  render() {
    const { searchDeploymentsRequest, searchDeployments } = this.props
    const { selectedDeployment } = this.state
    const options = this.getOptions()

    return (
      <Fragment>
        {this.renderShardRoutingWarning()}
        <EuiFormControlLayout
          fullWidth={true}
          prepend={
            <EuiFormLabel style={{ width: `180px` }}>
              <EuiFlexGroup justifyContent='flexStart' alignItems='center' gutterSize='xs'>
                <EuiFlexItem grow={false}>
                  <FormattedMessage
                    defaultMessage='Restore from'
                    id='select-snapshot-source-label'
                  />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                  <EuiPopover
                    data-test-id='enter-snapshot-help-popover'
                    anchorPosition='upCenter'
                    ownFocus={true}
                    button={
                      <EuiButtonIcon
                        aria-label='enter-snapshot-help-trigger'
                        onClick={() =>
                          this.setState({
                            showSnapshotRestoreHelp: !this.state.showSnapshotRestoreHelp,
                          })
                        }
                        iconType='iInCircle'
                      />
                    }
                    isOpen={this.state.showSnapshotRestoreHelp}
                    closePopover={() => this.setState({ showSnapshotRestoreHelp: false })}
                  >
                    <EuiText style={{ width: 350 }}>
                      <p>
                        <FormattedMessage
                          id='select-snapshot-restore-help'
                          defaultMessage='Only deployments in the same region and matching certain version criteria can be used. See <link>our docs</link> and the <link2>version matrix</link2> for more information.'
                          values={{
                            link: (content) => (
                              <DocLink link='snapshotRestoreAcrossClusters'>{content}</DocLink>
                            ),
                            link2: (content) => (
                              <DocLink link='snapshotRestoreVersionMatrix'>{content}</DocLink>
                            ),
                          }}
                        />
                      </p>
                    </EuiText>
                  </EuiPopover>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFormLabel>
          }
        >
          <DeploymentPicker
            fullWidth={true}
            data-test-id='select-snapshot-source-combo'
            searchDeployments={searchDeployments}
            searchDeploymentsRequest={searchDeploymentsRequest}
            options={options}
            value={selectedDeployment}
            onChange={(value) => this.onSelectDeployment(value)}
          />
        </EuiFormControlLayout>
        {this.renderSelectSnapshot()}
      </Fragment>
    )
  }

  renderShardRoutingWarning() {
    const { selectedDeployment } = this.state

    if (selectedDeployment === null) {
      return null
    }

    const deploymentTemplateId = getDeploymentTemplateId({ deployment: selectedDeployment })

    if (
      !deploymentTemplateId ||
      !(deploymentTemplateId.endsWith('hot-warm') || deploymentTemplateId === 'hot.warm')
    ) {
      return null
    }

    return (
      <Fragment>
        <EuiCallOut data-test-id='hot-warm-snapshot-restore-warning' color='warning'>
          <FormattedMessage
            id='snapshot-source.hot-warm-warning'
            defaultMessage='Restoring from hot/warm deployments ignores any shard allocation routing applied by {routing}. Data will be distributed across all cluster nodes, and you might need to manually allocate existing indices to the appropriate data tier.'
            values={{
              routing: <code>index.routing.allocation.require</code>,
            }}
          />
        </EuiCallOut>

        <EuiSpacer size='m' />
      </Fragment>
    )
  }

  renderSelectSnapshot() {
    const { selectedDeployment } = this.state
    const {
      asRestoreForm,
      forceLastSnapshot,
      onRestoreSnapshot,
      restoreSnapshotRequest,
      onUpdateIndexRestore,
      snapshotRestoreSettings,
      onChangeMaintMode,
      maintModeValue,
    } = this.props

    if (snapshotRestoreSettings && selectedDeployment == null) {
      const deployment = this.findSnapshotDeploymentById(snapshotRestoreSettings.source_cluster_id)

      if (deployment == null) {
        return
      }

      this.setState({ selectedDeployment: deployment })

      return this.renderSelectedDeployment(deployment)
    }

    if (forceLastSnapshot && selectedDeployment !== null) {
      return this.renderSelectedDeployment(selectedDeployment)
    }

    const targetIsPreSlm = this.selectedDeploymentIsPreSlm()
    const selectedSnapshot = snapshotRestoreSettings?.snapshot_name

    return (
      <Fragment>
        <EuiSpacer size='m' />

        {targetIsPreSlm ? this.renderSnapshotDropdown() : this.renderSnapshotTextbox()}

        <EuiSpacer size='s' />

        {asRestoreForm && (
          <RestoreFromSnapshot
            snapshot={Boolean(selectedSnapshot)}
            restoreSnapshot={onRestoreSnapshot}
            restoreSnapshotRequest={restoreSnapshotRequest}
            onBeforeRestore={onUpdateIndexRestore}
            onChangeMaintMode={(value) => onChangeMaintMode && onChangeMaintMode(value)}
            maintModeValue={maintModeValue}
          />
        )}

        <EuiSpacer size='s' />
      </Fragment>
    )
  }

  renderSnapshotTextbox() {
    const { selectedDeployment } = this.state
    const { snapshotRestoreSettings, onSelectSnapshot, getClusterSnapshots } = this.props

    const selectableSnapshots = filterSearchableSnapshots(
      (selectedDeployment && getClusterSnapshots(selectedDeployment.id)) || [],
    )

    const selectedSnapshot = snapshotRestoreSettings?.snapshot_name

    return (
      <EnterSnapshot
        selectableSnapshots={selectableSnapshots}
        selectedDeployment={selectedDeployment}
        selectedSnapshot={selectedSnapshot}
        onSelectSnapshot={onSelectSnapshot}
      />
    )
  }

  renderSnapshotDropdown() {
    const { selectedDeployment } = this.state
    const {
      fetchSnapshotsRequest,
      getClusterSnapshots,
      onSelectSnapshot,
      onRestoreSnapshot,
      restoreSnapshotRequest,
      onUpdateIndexRestore,
      snapshotRestoreSettings,
    } = this.props

    const searchSnapshotResult = selectedDeployment
      ? getClusterSnapshots(selectedDeployment.id)
      : []

    const snapshotsRequest = selectedDeployment
      ? fetchSnapshotsRequest(selectedDeployment.id)
      : null

    const selectedSnapshot = snapshotRestoreSettings?.snapshot_name

    return (
      <SelectSnapshot
        selectedDeployment={selectedDeployment}
        searchResults={searchSnapshotResult}
        onSelectSnapshot={onSelectSnapshot}
        fetchSnapshotsRequest={snapshotsRequest}
        onRestoreSnapshot={onRestoreSnapshot}
        onUpdateIndexRestore={onUpdateIndexRestore}
        restoreSnapshotRequest={restoreSnapshotRequest}
        selectedSnapshot={selectedSnapshot}
      />
    )
  }

  renderSelectedDeployment(deployment: DeploymentSearchResponse) {
    const firstEs = getFirstEsClusterFromGet({ deployment })
    const lastSuccessfulSnapshotDate = getLatestSnapshotSuccess({
      resource: firstEs!,
    })
    const listItems: Array<{
      title: JSX.Element
      description: JSX.Element | string
    }> = [
      {
        title: (
          <FormattedMessage
            id='select-snapshot-source.deployment-title'
            defaultMessage='Deployment:'
          />
        ),
        description: deployment.name,
      },
    ]

    if (lastSuccessfulSnapshotDate) {
      listItems.push({
        title: (
          <FormattedMessage
            id='select-snapshot-source.latest-snapshot-title'
            defaultMessage='Latest snapshot:'
          />
        ),
        description: (
          <FormattedDate
            value={lastSuccessfulSnapshotDate}
            year='numeric'
            month='short'
            day='numeric'
            hour='2-digit'
            minute='2-digit'
          />
        ),
      })
    }

    if (this.props.showRegion) {
      const regionId = getRegionId({ deployment })

      if (regionId) {
        listItems.push(
          {
            title: (
              <FormattedMessage
                id='select-snapshot-source.platform-title'
                defaultMessage='Platform:'
              />
            ),
            description: getPlatform(regionId).toUpperCase(),
          },
          {
            title: (
              <FormattedMessage id='select-snapshot-source.region-title' defaultMessage='Region:' />
            ),
            description: regionId,
          },
        )
      }
    }

    return (
      <Fragment>
        <EuiSpacer size='s' />
        <EuiDescriptionList
          className='selectSnapshotSource--snapshotDetails'
          type='column'
          compressed={true}
          listItems={listItems}
        />
      </Fragment>
    )
  }

  getOptions() {
    const {
      intl: { formatMessage },
      deploymentSearchResults,
    } = this.props

    const options: Option[] = mapResultsToOptions()
    const [noSnapshots, withSnapshots] = partition(options, `disabled`)

    const optionGroups = [
      {
        label: formatMessage(messages.deploymentsWithSnapshots),
        options: withSnapshots.sort(sortOn('label')),
      },
      {
        label: formatMessage(messages.deploymentsWithoutSnapshots),
        options: noSnapshots.sort(sortOn('label')),
      },
    ]

    return optionGroups

    function mapResultsToOptions() {
      if (deploymentSearchResults == null) {
        return []
      }

      return deploymentSearchResults.deployments.map((deployment) => {
        const firstEs = getFirstEsClusterFromGet({ deployment })
        const snapshots = firstEs!.info.snapshots

        const hasSnapshotToRestoreFrom = snapshots.count > 0

        return {
          label: getDeploymentPickerLabel(deployment),
          value: deployment,
          disabled: !hasSnapshotToRestoreFrom,
        }
      })
    }
  }

  onSelectDeployment(selectedDeployment: DeploymentSearchResponse | null) {
    const { forceLastSnapshot, fetchSnapshots, onUpdateSnapshotSource } = this.props

    this.setState({ selectedDeployment })

    onUpdateSnapshotSource(selectedDeployment)

    if (selectedDeployment === null) {
      return
    }

    if (!forceLastSnapshot) {
      fetchSnapshots({
        deployment: selectedDeployment,
      })
    }
  }

  findSnapshotDeploymentById(sourceClusterId): DeploymentSearchResponse | undefined {
    const { deploymentSearchResults } = this.props

    if (!deploymentSearchResults) {
      return
    }

    const { deployments } = deploymentSearchResults
    const selectedDeployment = deployments.find((deployment) => {
      const firstEs = getFirstEsClusterFromGet({ deployment })

      if (!firstEs) {
        return false
      }

      return firstEs.id === sourceClusterId
    })
    return selectedDeployment
  }

  selectedDeploymentIsPreSlm = () => {
    const { selectedDeployment } = this.state

    if (!selectedDeployment) {
      return false
    }

    const esReource = getFirstEsClusterFromGet({ deployment: selectedDeployment })

    return esReource?.info.settings?.snapshot?.slm === false
  }
}

export default injectIntl(SelectSnapshotSource)
