/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-user-lib/hooks/list'
import type {
  NormalisedResourceRoleAssignment,
  ResourceType,
} from '@modules/role-assignments-lib/types'
import type { MemberOrApiKey } from '@modules/role-assignments-components/ProjectRoleAssignmentsSummary'
import ProjectRoleAssignmentsSummary from '@modules/role-assignments-components/ProjectRoleAssignmentsSummary'
import type { ProjectType } from '@modules/ui-types/projects'
import { useListDeploymentsQuery } from '@modules/cloud-lib/deployments/hooks'
import usePermissions from '@modules/permissions-lib/hooks'
import { useServerlessFeatureFlag } from '@modules/launchdarkly'

import type { FunctionComponent } from 'react'

const UserProjectRoleAssignmentsSummary: FunctionComponent<{
  memberOrApiKey: MemberOrApiKey
  roleAssignments: Array<NormalisedResourceRoleAssignment<ResourceType>>
}> = ({ memberOrApiKey, roleAssignments }) => {
  const isServerlessEnabled = useServerlessFeatureFlag()

  const showDeployments = usePermissions([{ type: 'deployment', action: 'list' }]).hasPermissions

  const hasListElasticsearchPermission = usePermissions([
    { type: 'project-elasticsearch', action: 'list' },
  ]).hasPermissions
  const hasListObservabilityPermission = usePermissions([
    { type: 'project-observability', action: 'list' },
  ]).hasPermissions
  const hasListSecurityPermission = usePermissions([
    { type: 'project-security', action: 'list' },
  ]).hasPermissions

  const showElasticsearchProjects = isServerlessEnabled && hasListElasticsearchPermission
  const showObservabilityProjects = isServerlessEnabled && hasListObservabilityPermission
  const showSecurityProjects = isServerlessEnabled && hasListSecurityPermission

  const deploymentQuery = useListDeploymentsQuery({
    enabled: showDeployments,
  })
  const elasticsearchQuery = useListElasticsearchProjectsQuery({
    enabled: showElasticsearchProjects,
  })
  const observabilityQuery = useListObservabilityProjectsQuery({
    enabled: showObservabilityProjects,
  })
  const securityQuery = useListSecurityProjectsQuery({
    enabled: showSecurityProjects,
  })

  const queryResults = [
    {
      resourceType: 'deployment' as ResourceType,
      data: deploymentQuery.data?.deployments,
      isLoading: deploymentQuery.isLoading,
      error: deploymentQuery.error,
      isEnabled: showDeployments,
    },
    {
      resourceType: 'elasticsearch' as ProjectType,
      data: elasticsearchQuery.data?.items,
      isLoading: elasticsearchQuery.isLoading,
      error: elasticsearchQuery.error,
      isEnabled: showElasticsearchProjects,
    },
    {
      resourceType: 'observability' as ProjectType,
      data: observabilityQuery.data?.items,
      isLoading: observabilityQuery.isLoading,
      error: observabilityQuery.error,
      isEnabled: showObservabilityProjects,
    },
    {
      resourceType: 'security' as ProjectType,
      data: securityQuery.data?.items,
      isLoading: securityQuery.isLoading,
      error: securityQuery.error,
      isEnabled: showSecurityProjects,
    },
  ].filter(({ isEnabled }) => isEnabled)

  return (
    <ProjectRoleAssignmentsSummary
      memberOrApiKey={memberOrApiKey}
      queryResults={queryResults}
      roleAssignments={roleAssignments}
    />
  )
}

export default UserProjectRoleAssignmentsSummary
