/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { useConfig } from '@modules/cui/ConfigContext'

import { bless } from '@/lib/router'
import AppRootSharedSideEffects from '@/components/AppRootSharedSideEffects'

import HerokuAppRoot from './HerokuAppRoot'

const AppRoot: React.FunctionComponent = ({ children }) => {
  const isHeroku = useConfig('APP_FAMILY') === 'heroku'

  const Component = isHeroku ? HerokuAppRoot : Fragment

  return (
    <Component>
      {children}
      <AppRootSharedSideEffects />
    </Component>
  )
}

export default bless(AppRoot)
