/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { Fragment } from 'react'

import type { EuiBasicTableColumn } from '@elastic/eui'
import { EuiBadge, EuiBasicTable, EuiSkeletonText } from '@elastic/eui'

import { processQueryParams } from '@modules/billing-api/utils'
import { useGetInstanceCostItemsV2 } from '@modules/billing-lib/hooks'
import { CuiAlert } from '@modules/cui/Alert'
import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import {
  buildServerlessDimensionItems,
  getServerlessFreeComponents,
  mapToDeploymentCostsItemsV2,
} from '@/components/User/BillingUsage/BillingUsageByInstance/utils'
import messages from '@/components/User/BillingOverviewV2/messages'

import QuantityTableInfo from '../components/QuantityTableInfo'
import {
  buildCapacityItemsV2,
  buildDataTransferItems,
  buildStorageItems,
  buildSyntheticsItems,
} from '../components/InstanceUsageTable/utils'

import type { ServerlessFreeComponent } from '@/components/User/BillingUsage/BillingUsageByInstance/utils'
import type { DimensionData } from '../components/InstanceUsageTable/types'
import type { ProductData } from '@/components/User/BillingUsage/BillingUsageByInstance/types'
import type { Moment } from 'moment'

const projectColumns: Array<EuiBasicTableColumn<ProductData | ServerlessFreeComponent>> = [
  {
    name: <FormattedMessage id='billing-usage-product-list.name-name' defaultMessage='Component' />,
    width: '58%',
    render: (data) => (
      <Fragment>
        <p>{data.name}</p>
        {data?.free_message && (
          <EuiBadge css={css({ marginLeft: 12 })}>{data.free_message}</EuiBadge>
        )}
      </Fragment>
    ),
  },
  {
    name: (
      <FormattedMessage id='billing-usage-product-list.quantity-name' defaultMessage='Quantity' />
    ),
    render: (data) => {
      if (data?.free_message) {
        return null
      }

      return (
        <QuantityTableInfo
          unit={data.unit}
          display_quantity={data.display_quantity}
          quantity={data.quantity}
        />
      )
    },
  },
  {
    name: (
      <FormattedMessage
        id='billing-usage-product-list.item-price-name'
        defaultMessage='Current usage rates (in ECU)'
      />
    ),
    render: (data) => data?.item_price,
  },
  {
    name: (
      <FormattedMessage id='billing-usage-product-list.cost-name' defaultMessage='Cost (in ECU)' />
    ),
    render: (data) => {
      if (data?.free_message) {
        return null
      }

      return (
        <CuiElasticConsumptionUnits value={data?.total} unit='none' dp={4} withSymbol={false} />
      )
    },
    align: 'right',
  },
]

const deploymentColumns: Array<EuiBasicTableColumn<DimensionData>> = [
  {
    name: <FormattedMessage id='billing-deployment-usage.usage-type' defaultMessage='Usage type' />,
    field: 'dimension',
    render: (dimension) => dimension,
    width: '150px',
  },
  {
    name: (
      <FormattedMessage id='billing-deployment-usage.usage-component' defaultMessage='Components' />
    ),
    field: 'component',
    render: (component) => component,
  },
  {
    name: (
      <FormattedMessage id='billing-deployment-usage.usage-quantity' defaultMessage='Quantity' />
    ),
    field: 'quantity',
    width: '150px',
  },
  {
    name: (
      <FormattedMessage id='billing-deployment-usage.usage-rate' defaultMessage='Price (in ECU)' />
    ),
    field: 'rate',
    width: '180px',
  },
  {
    name: (
      <FormattedMessage id='billing-deployment-usage.usage-cost' defaultMessage='Cost (in ECU)' />
    ),
    field: 'total',
    render: (total) => (
      <CuiElasticConsumptionUnits value={total as number} unit='none' dp={4} withSymbol={false} />
    ),
    width: '170px',
    align: 'right',
  },
]

const tableCss = css({
  paddingLeft: 15,
  paddingRight: 30,
  '& tr:last-of-type td': { borderBottom: 'none ' },
})

interface Props {
  organizationId: string
  instanceId: string
  startDate: Moment
  endDate: Moment
}

const DetailsPanel = ({ instanceId, organizationId, startDate, endDate }: Props) => {
  const [_isFlagUsable, flags] = useFlagsWhenLoaded()

  const instanceCostItems = useGetInstanceCostItemsV2({
    pathParameters: {
      organization_id: organizationId,
      instance_id: instanceId,
    },
    queryParameters: processQueryParams({
      from: startDate.startOf('day').utc().format(),
      to: endDate.endOf('day').utc().format(),
    }),
  })

  if (instanceCostItems.isError) {
    return (
      <CuiAlert type='error' data-test-id='error-message'>
        <FormattedMessage {...messages.genericErrorMessage} />
      </CuiAlert>
    )
  }

  if (instanceCostItems.isLoading) {
    return <EuiSkeletonText lines={2} />
  }

  const instance_type = instanceCostItems.data?.products[0]?.type

  if (instance_type === 'deployment') {
    const itemsCosts = mapToDeploymentCostsItemsV2(instanceCostItems.data, true)

    return (
      <EuiBasicTable
        items={[
          ...buildCapacityItemsV2(itemsCosts),
          ...buildDataTransferItems(itemsCosts),
          ...buildStorageItems(itemsCosts),
          ...buildSyntheticsItems(itemsCosts),
        ]}
        columns={deploymentColumns}
        css={tableCss}
      />
    )
  }

  const getServerlessItems = () => {
    const serverlessItems = buildServerlessDimensionItems(instanceCostItems.data)
    const items: Array<ProductData | ServerlessFreeComponent> = [...serverlessItems]

    items.push(...getServerlessFreeComponents(serverlessItems, flags.serverlessFreeComponents))

    return items
  }

  return (
    <Fragment>
      <EuiBasicTable items={getServerlessItems()} columns={projectColumns} css={tableCss} />
    </Fragment>
  )
}

export default DetailsPanel
