/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { EuiPalette } from '@elastic/eui/src/services/color/eui_palettes'
import { euiPaletteColorBlind } from '@elastic/eui'

class ColorGenerator {
  private map = new Map<string, string>()

  private index = 0

  private readonly palette: EuiPalette

  constructor(palette?: EuiPalette) {
    this.palette = palette || euiPaletteColorBlind()
  }

  get(id: string) {
    this.map.set(id, this.map.get(id) || this.palette[this.index++] || this.palette[0]!)
    return this.map.get(id) as string
  }

  reset() {
    this.map = new Map()
    this.index = 0
  }
}

export const colorForInstances = new ColorGenerator(
  euiPaletteColorBlind({ sortBy: 'natural', rotations: 2 }),
)
